import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { APIKEY, AUTHDOMAIN, DATABASEURL, PROJECTID, STORAGEBUCKET, MESSAGINGSENDERID, APPID, MEASUREMENTID } from '@env';

// Import your screens
import HomeScreen from './screens/Home.js';
import LoginScreen from './screens/Login.js';
import SigninScreen from './screens/Signin.js';
import FormuleScreen from './screens/Formule.js';
import FormuleCreditScreen from './screens/FormuleCredit.js';
import InscriptionActiviteScreen from './screens/InscriptionActivite.js';
import ActivityProfilScreen from './screens/ActivityProfil.js';
import SettingsProfilScreen from './screens/SettingsProfil.js';
import DashboardScreen from './screens/Dashboard.js';

const firebaseConfig = {
  apiKey: APIKEY,
  authDomain: AUTHDOMAIN,
  databaseURL: DATABASEURL,
  projectId: PROJECTID,
  storageBucket: STORAGEBUCKET,
  messagingSenderId: MESSAGINGSENDERID,
  appId: APPID,
  measurementId: MEASUREMENTID
};

const Stack = createStackNavigator();
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);

  const loadFonts = () => {
    return Font.loadAsync({
      'Avenir Next Regular': require('./assets/fonts/AvenirNextLTPro-Regular.otf'),
      'Avenir Next Italic': require('./assets/fonts/AvenirNextLTPro-It.otf'),
      'Avenir Next Bold': require('./assets/fonts/AvenirNextLTPro-Bold.otf'),
    });
  };

  if (!fontLoaded) {
    return (
      <AppLoading
        startAsync={loadFonts}
        onFinish={() => setFontLoaded(true)}
        onError={console.warn}
      />
    );
  }

  // Route spéciale pour la validation Let's Encrypt
  const renderAcmeChallenge = () => {
    const url = window.location.pathname; // Récupère le chemin de l'URL
    // Vérifiez si l'URL correspond à la route de validation Let's Encrypt
    if (url.startsWith('/.well-known/acme-challenge/pQIOsvShFbVbWf5cko7bsORwzqgzA8pWWR5AC37SDqc')) {
      // Retourner la réponse de validation attendue
      // Remplacez 'your-validation-string' par le contenu attendu par Let's Encrypt
      return <Text>pQIOsvShFbVbWf5cko7bsORwzqgzA8pWWR5AC37SDqc.VEsRb0eB-6PW8YZkU_9tvdD5jgzZRq4Jxfkyr6yFRtI</Text>;
    }
    return null;
  };

  return (
    <NavigationContainer>
      {renderAcmeChallenge() || (
        <Stack.Navigator initialRouteName="Home">
          <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
          <Stack.Screen name="ActivityProfil" component={ActivityProfilScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Signin" component={SigninScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Formule" component={FormuleScreen} options={{ headerShown: false }} />
          <Stack.Screen name="FormuleCredit" component={FormuleCreditScreen} options={{ headerShown: false }} />
          <Stack.Screen name="InscriptionActivite" component={InscriptionActiviteScreen} options={{ headerShown: false }} />
          <Stack.Screen name="SettingsProfil" component={SettingsProfilScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Dashboard" component={DashboardScreen} options={{ headerShown: false }} />
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
}
