import React, { useState, useEffect, useMemo,useCallback, useRef } from 'react';
import { View, Text, TextInput, Button, StyleSheet, SafeAreaView, StatusBar,TouchableOpacity, Image,Dimensions,Animated } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; // Si vous utilisez Expo. Sinon, utilisez `react-native-vector-icons` directement.
import { useRoute } from '@react-navigation/native';

import { LinearGradient } from 'expo-linear-gradient';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence
import { ScrollView } from 'react-native-web';
import CustomImage from './customImage.js';
import AlgoliaSearchWrapper from './AlgoliaInput.js';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { InfiniteHits } from './InfiniteHits';
import AgePicker from './AgePicker';
import Svg, {Path, G} from 'react-native-svg';
import { Profile, Heart, SearchNormal1,HambergerMenu, } from 'iconsax-react-native';

import ActivityFilter from '../components/ActivityFilter';

let userD = undefined
function debounce(func, wait) {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  
export default function Navbar({ onActivitySelect,setOnActivitySelect,scrollY,navigation,page,selectedButton, setSelectedButton,searchActivity,setSearchActivity,address,setAddress }) {
    
  const route = useRoute();
    const [selectedDay, setSelectedDay] = useState({}); // Pour stocker les jours sélectionnés
    const [isScrolled, setIsScrolled] = useState(true); // Pour stocker les jours sélectionnés
    const [agePicker, setAgePicker] = useState(false); // Pour stocker les jours sélectionnés
    
  const [selectedAge, setSelectedAge] = useState(12);
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width); // Utilisation de useRef ici
    const [menuZIndex, setMenuZIndex] = useState(-1); // Initialement derrière les autres composants
    const [searchQuery, setSearchQuery] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const animationSearchBar = useRef(new Animated.Value(0)).current; 
    
  const handleAgeChange = (newAge) => {
    setSelectedAge(newAge);
  };
  const handleAgePress = () => {
    setAgePicker(!agePicker);
  };
    const handleScroll = Animated.event(
        [{ nativeEvent: { contentOffset: { y: scrollY } } }],
        { useNativeDriver: false }
      );
    const toggleAnimationSearchBar = () => {
        setIsScrolled(!isScrolled);
        Animated.timing(animationSearchBar, {
          toValue: isScrolled ? 0 : 1, // Animate to opacity: 1, or 0
          duration: 500, // 500ms
          useNativeDriver: true, // Utiliser le pilote natif
        }).start();
      }
    const menuPosition = useState(new Animated.Value(-300))[0]; 
    const styles = getStyles(screenWidth);
    const DaySelector = React.memo(({ selectedDay, setSelectedDay }) => {
        const toggleDay = (day) => {
            setSelectedDay(prevState => ({
                ...prevState,
                [day]: !prevState[day]
            }));
        };
    
        return (
            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ flexDirection: 'row',flexGrow:0}}>
                {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map(day => (
                    <TouchableOpacity key={day} onPress={() => toggleDay(day)} style={selectedDay[day] ? styles.daySelected : styles.day}>
                        <Text style={selectedDay[day] ? styles.textDaySelected : styles.textDay}>{day}</Text>
                    </TouchableOpacity>
                ))}
            </ScrollView>
        );
    });

    
    const GooglePlacesInput = () => {
        return (
            <View style={{height:'100%'}}>
          <GooglePlacesAutocomplete
            placeholder='Adressee'
            containerStyle={{height:'100%'}}
            onPress={(data, details = null) => {
              // 'details' is provided when fetchDetails = true
              console.log(data, details);
            }}
            query={{
              key: 'AIzaSyBXP9mFixsN76mGW6_qNzo8kaeHj8iJXls',
              language: 'fr',
            }}
          />
          </View>
        );
      };
    const [userD,setUserD] = useState(undefined)
    useEffect(() => {
        const onChange = () => {
          setScreenWidth(Dimensions.get('window').width); // Mise à jour de la référence sans provoquer de re-render
        };
        
        Dimensions.addEventListener('change', onChange);
        return () => Dimensions.removeEventListener('change', onChange);
    }, []);

  // Définir un seuil de largeur pour ajuster la mise en page
  const [isSmallDevice,setIsSmallDevice] = useState(screenWidth.current >= 1000)  // useMemo pour éviter les recalculs inutiles
  const ref = useRef();

  useEffect(() => {
    setIsSmallDevice(screenWidth >= 1083)
  }, [screenWidth]);
  
  useEffect(() => {
    ref.current?.setAddressText('Some Text');
  }, []);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUserD(user)
            console.log("Utilisateur connecté in NAVBAR:", userD);
            // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
          } else {
            setUserD(undefined)
            console.log("Utilisateur déconnecté in NAVBAR:", userD);
            // Mettez à jour votre état en conséquence
          }
        });
      
        return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
      }, [auth]);// -300 est la position initiale hors écran
      
      useEffect(() => {
          if (menuVisible) {
              setMenuZIndex(150); // Assurez-vous que ce zIndex soit supérieur à celui des autres composants
              // Anime le menu pour entrer
              Animated.timing(menuPosition, {
                  toValue: 300,
                  duration: 0,
                  useNativeDriver: false,
              }).start();
          } else {
              // Anime le menu pour sortir
              Animated.timing(menuPosition, {
                  toValue: 0,
                  duration: 0,
                  useNativeDriver: false,
              }).start(() => setMenuZIndex(-1)); // Remet le zIndex à -1 une fois l'animation terminée
          }
      }, [menuVisible]);
      useEffect(() => {
        const listenerId = scrollY.addListener(({ value }) => {
          const isScrolled1 = value <= 10;
          if (value < 10) {
            setIsScrolled(true);
          }else {
            setIsScrolled(false);

          }
        });
      
        return () => {
          scrollY.removeListener(listenerId);
        };
      }, []);
      
      
      
      
      
    return (
    <View style={{width:'100vw',zIndex:10,position:'relative',top:0,justifyContent:'center'}}>
        <View style={{width:'100%',height:'99%',backgroundColor:'white',paddingTop:10,alignItems:'center',justifyContent:'center'}}>
        <View style={{justifyContent:'center',width:'90%'}}>
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',width:'100%',marginBottom:10}}>
                
                <TouchableOpacity onPress={()=>navigation.navigate('Home')} style={{...styles.logols,padding:0,margin:0,position:'absolute',left:0}}>
                   
                    <Svg style={{
        marginTop:15,}} id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/Svg" viewBox="0 0 2651.86 493.44">
  
  <G id="OBJECTS">
    <G>
      <Path
          fill="#6f2cf7"
          strokeWidth={0} class="cls-2" d="m374.96,261.65c0,5.45-3.31,10.36-8.4,12.33l-78.71,30.2c-27.7,10.63-49.4,32.79-59.59,60.75l-29.93,82.46c-.21.62-.68,1.05-.99,1.61-.26.46-.5.91-.82,1.33-.48.64-.96,1.21-1.56,1.72-.92.82-1.93,1.54-3.07,2.04-.71.27-1.43.54-2.14.63-.71.18-1.43.27-2.14.27l-2.32-.27c-.71-.09-1.43-.36-2.14-.63-1.18-.51-2.21-1.25-3.15-2.09-.58-.5-1.04-1.06-1.51-1.67-.27-.36-.49-.74-.71-1.12-.37-.62-.89-1.12-1.15-1.82l-29.93-82.46c-10.1-27.96-31.81-50.12-59.5-60.75l-78.71-30.2c-5.09-1.97-8.49-6.88-8.49-12.33,0-5.72,3.75-10.81,9.2-12.51l72.01-22.69c31.18-9.83,55.75-33.95,66.29-64.86l29.3-86.57c.36-.98.8-1.88,1.34-2.68.36-.54.72-1.07,1.25-1.52,1.07-1.16,2.41-2.05,4.02-2.68.45-.18.98-.36,1.52-.45.8-.27,1.7-.36,2.59-.36s1.79.09,2.59.36c.54.09,1.07.27,1.52.45,1.43.54,2.68,1.43,3.75,2.41.45.45.8.89,1.16,1.34.72.98,1.34,1.96,1.7,3.13l29.3,86.57c10.45,30.91,35.11,55.03,66.2,64.86l72.01,22.69c5.54,1.7,9.2,6.79,9.2,12.51Z"/>
      <Path 
          fill="#6f2cf7"
          strokeWidth={0}class="cls-2" d="m502.89,106.4c0,2.98-1.81,5.67-4.6,6.75l-43.1,16.53c-15.17,5.82-27.05,17.95-32.63,33.26l-16.39,45.15c-.12.34-.37.58-.54.88-.14.25-.27.5-.45.73-.26.35-.53.66-.85.94-.5.45-1.06.84-1.68,1.12-.39.15-.78.29-1.17.34-.39.1-.78.15-1.17.15l-1.27-.15c-.39-.05-.78-.2-1.17-.34-.64-.28-1.21-.68-1.72-1.15-.32-.27-.57-.58-.83-.91-.15-.2-.27-.4-.39-.62-.2-.34-.49-.61-.63-1l-16.39-45.15c-5.53-15.31-17.41-27.44-32.58-33.26l-43.1-16.53c-2.79-1.08-4.65-3.77-4.65-6.75,0-3.13,2.05-5.92,5.04-6.85l39.43-12.43c17.07-5.38,30.53-18.59,36.3-35.52l16.05-47.4c.2-.54.44-1.03.73-1.47.2-.29.39-.59.69-.83.59-.64,1.32-1.13,2.2-1.47.25-.1.54-.2.83-.24.44-.15.93-.2,1.42-.2s.98.05,1.42.2c.29.05.59.15.83.24.78.29,1.47.78,2.05,1.32.25.24.44.49.64.73.39.54.73,1.08.93,1.71l16.05,47.4c5.72,16.93,19.23,30.13,36.25,35.52l39.43,12.43c3.03.93,5.04,3.72,5.04,6.85Z"/>
      <Path 
          fill="#6f2cf7"
          strokeWidth={0}class="cls-2" d="m467.83,423.73c0,1.96-1.19,3.74-3.03,4.45l-28.38,10.89c-9.99,3.83-17.81,11.82-21.49,21.91l-10.79,29.73c-.08.22-.25.38-.36.58-.09.16-.18.33-.29.48-.17.23-.35.43-.56.62-.33.3-.7.56-1.1.74-.26.1-.52.19-.77.22-.26.06-.52.1-.77.1l-.84-.1c-.26-.03-.52-.13-.77-.22-.42-.19-.8-.45-1.13-.75-.21-.18-.37-.38-.54-.6-.1-.13-.18-.27-.26-.41-.13-.22-.32-.4-.42-.65l-10.79-29.73c-3.64-10.08-11.47-18.07-21.45-21.91l-28.38-10.89c-1.84-.71-3.06-2.48-3.06-4.45,0-2.06,1.35-3.9,3.32-4.51l25.96-8.18c11.24-3.54,20.1-12.24,23.9-23.39l10.57-31.22c.13-.35.29-.68.48-.97.13-.19.26-.39.45-.55.39-.42.87-.74,1.45-.97.16-.06.35-.13.55-.16.29-.1.61-.13.93-.13s.64.03.93.13c.19.03.39.1.55.16.51.19.97.52,1.35.87.16.16.29.32.42.48.26.35.48.71.61,1.13l10.57,31.22c3.77,11.15,12.66,19.84,23.87,23.39l25.96,8.18c2,.61,3.32,2.45,3.32,4.51Z"/>
    </G>
    <G>
      <Path strokeWidth={0} class="cls-1" d="m681.67,84.76h67.33v318.66h-67.33V84.76Z"/>
      <Path strokeWidth={0} class="cls-1" d="m800.15,296.6v-114.9h67.33v114.9c0,35.01,17.05,49.82,42.19,49.82s42.19-14.81,42.19-49.82v-114.9h67.32v114.9c0,74.5-43.98,112.65-109.51,112.65s-109.51-37.7-109.51-112.65Z"/>
      <Path strokeWidth={0} class="cls-1" d="m1063.14,278.65c0-71.37,41.29-102.78,95.15-102.78,31.42,0,57.9,12.12,74.5,35.46,16.61-23.34,42.64-35.46,74.5-35.46,53.86,0,95.15,31.42,95.15,102.78v124.77h-67.32v-124.77c0-29.63-14.36-39.95-34.56-39.95s-34.11,10.32-34.11,39.95v124.77h-67.32v-124.77c0-29.63-14.36-39.95-34.11-39.95s-34.55,10.32-34.55,39.95v124.77h-67.33v-124.77Z"/>
      <Path strokeWidth={0} class="cls-1" d="m1444.17,118.42c0-24.69,18.4-43.09,43.09-43.09s43.09,18.4,43.09,43.09-18.4,43.09-43.09,43.09-43.09-18.4-43.09-43.09Zm9.42,63.28h67.33v221.72h-67.33v-221.72Z"/>
      <Path strokeWidth={0} class="cls-1" d="m1563.1,336.1h66.42c.45,11.67,8.98,17.5,27.38,17.5,20.65,0,27.38-7.18,27.38-16.61,0-14.81-16.15-16.15-36.8-19.3-30.52-4.49-80.78-12.12-80.78-65.53,0-44.88,35.45-76.3,90.66-76.3s89.31,31.87,90.66,71.81h-65.52c-1.8-10.32-10.77-16.16-24.24-16.16-16.6,0-23.34,8.53-23.34,17.5,0,13.46,16.15,15.26,36.8,18.4,31.86,4.49,80.78,13.02,80.78,65.53,0,45.78-37.7,76.3-94.7,76.3s-93.8-30.52-94.7-73.16Z"/>
      <Path strokeWidth={0} class="cls-1" d="m1778.51,292.56c0-67.32,49.37-116.69,119.39-116.69,61.49,0,106.37,38.15,115.79,92.9h-68.22c-7.63-18.4-25.13-30.07-47.57-30.07-30.52,0-51.17,21.99-51.17,53.86s20.64,53.86,51.17,53.86c22.44,0,39.94-11.67,47.57-30.07h68.22c-9.42,54.76-54.3,92.91-115.79,92.91-70.02,0-119.39-49.37-119.39-116.69Z"/>
      <Path strokeWidth={0} class="cls-1" d="m2038.8,292.56c0-67.32,49.37-116.69,119.39-116.69s119.38,49.37,119.38,116.69-49.37,116.69-119.38,116.69-119.39-49.37-119.39-116.69Zm170.55,0c0-31.87-20.64-53.86-51.16-53.86s-51.17,21.99-51.17,53.86,20.64,53.86,51.17,53.86,51.16-21.99,51.16-53.86Z"/>
      <Path strokeWidth={0} class="cls-1" d="m2303.59,292.56c0-67.32,49.37-116.69,119.39-116.69s119.38,49.37,119.38,116.69-49.37,116.69-119.38,116.69-119.39-49.37-119.39-116.69Zm170.55,0c0-31.87-20.64-53.86-51.16-53.86s-51.17,21.99-51.17,53.86,20.64,53.86,51.17,53.86,51.16-21.99,51.16-53.86Z"/>
      <Path strokeWidth={0} class="cls-1" d="m2584.54,84.76h67.33v318.66h-67.33V84.76Z"/>
    </G>
  </G>
</Svg>
                </TouchableOpacity>
                {
                page === 'Home' ?
                <View>
                    {
                        isScrolled ? 
                <View style={{alignItems:'center',justifyContent:'center',maxWidth:'80vw'}}>
                    <View style={{...styles.LA, margin:5}}>
                        <TouchableOpacity 
                        onPress={() => setSelectedButton('Lieux')} 
                        style={selectedButton === 'Lieux' ? styles.buttonLASelected : styles.buttonLA}
                        >
                            <Text style={selectedButton === 'Lieux' ? styles.TextLASelected : styles.TextLA}>Lieux</Text>
                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={() => setSelectedButton('Activités')} 
                        style={selectedButton === 'Activités' ? styles.buttonLASelected : styles.buttonLA}
                        >
                            <Text style={selectedButton === 'Activités' ? styles.TextLASelected : styles.TextLA}>Activités</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={{...styles.searchBar,width:'100%',marginTop:20,alignSelf:'center',justifyContent:'space-between'}}>
                        
                        <AlgoliaSearchWrapper/>
                        <View style={styles.autocompleteContainer}>
                            <View style={{height:'100%',minWidth:100,width:'25%',borderLeftWidth:1,borderColor:'#E7E7E7',paddingLeft:15}}>
                            <Text style={{paddingTop:15,fontSize:12,fontFamily:'Avenir Next Bold'}}>Adresse</Text>
                            <GooglePlacesAutocomplete
                                     placeholder='Où ?'
                                     minLength={4} // minimum length of text to search
                                     autoFocus={false}
                                     returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                                     listViewDisplayed={true}   // true/false/undefined
                                     fetchDetails={true}
                                     renderDescription={row => row.description} // custom description render
                                     onPress={(data, details = null) => { // 'details' is provided when fetchDetails = true
                                       console.log(data, details);
                                     }}

                                     getDefaultValue={() => ''}

                                     requestUrl={{
                                         useOnPlatform: 'web', // ou "all" pour toutes les plateformes
                                         url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // ou tout autre proxy de votre choix

                                     }}
                                     query={{
                                       key: 'AIzaSyBXP9mFixsN76mGW6_qNzo8kaeHj8iJXls',
                                       language: 'fr',
                                     }}
                                     styles={{
                                         container: {width:'100%'},
                                         textInputContainer: {
                                           backgroundColor: 'rgba(0,0,0,0)',
                                           borderTopWidth: 0,
                                           borderBottomWidth:0,
                                           width: '100%',
                                         },
                                         listView: { color: 'black', //To see where exactly the list is
                                               zIndex: 16, //To popover the component outwards
                                               position: 'absolute',  
                                               width:'100%',
                                               top:'100%' 
                                },
                                poweredContainer: {
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  borderBottomRightRadius: 5,
                                  borderBottomLeftRadius: 5,
                                  borderColor: '#c8c7cc',
                                  borderTopWidth: 0.5,
                                },
                                textInput: {
                                  marginLeft: 0,
                                  marginRight: 0,
                                  height: 'auto',
                                  width: '100%',
                                  color: '#808080',
                                  fontSize: 15,
                                  paddingBottom:10,
                                    paddingTop: 3,
                                    paddingHorizontal: 0,
                                    paddingVertical: 0,
                                    fontSize: 15,
                                    color:'#808080',
                                    fontFamily:'Avenir Next Regular'
                                },
                                predefinedPlacesDescription: {
                                  color: '#1faadb'
                                },
                              }}

                              nearbyPlacesAPI='GooglePlacesSearch' // Which API to use: GoogleReverseGeocoding or GooglePlacesSearch

                          
                              filterReverseGeocodingByTypes={['locality', 'administrative_area_level_3']} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities

                          
                              debounce={200} // debounce the requests in ms. Set to 0 to remove debounce. By default 0ms.

                            />
                            </View>
                        </View>
                        <TouchableOpacity style={{height:'100%',maxWidth:100,borderLeftWidth:1,borderColor:'#E7E7E7',paddingLeft:15}} onPress={handleAgePress} activeOpacity={1} >
                            <Text  style={{paddingTop:15,fontSize:12,fontFamily:'Avenir Next Bold'}}>Age</Text>
                            <TextInput editable={false} placeholder={String(selectedAge)+' ans'} 
                style={{
                    paddingBottom:10,
                      paddingTop: 3,
                      paddingHorizontal: 0,
                      paddingVertical: 0,
                      fontSize: 15,fontFamily:'Avenir Next Regular',color:'#808080'}}/>
                            {
                                agePicker ? 
                                <View style={{position:'absolute',width:'150%',backgroundColor:'white',top:60,borderRadius:10,
                                shadowColor: '#7E7E7E',
                                shadowOffset: { width: 2, height: 2 },
                                shadowOpacity: 0.25,
                                shadowRadius: 7,}}>
                                    <Text  style={{padding:15,paddingBottom:0,fontSize:12,fontFamily:'Avenir Next Regular'}}>Selectionez l'age de votre enfant</Text>
                                    <AgePicker age={selectedAge} onAgeChange={handleAgeChange}/>
                                </View> : <View></View> 
                            }
                        </TouchableOpacity>
                
                        <DaySelector selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        


                        <TouchableOpacity style={styles.searchButton}>
                    <SearchNormal1 color={'#FFFFFF'}  size={20}/>
                        </TouchableOpacity>
                    </View>
                </View>
                : 
                <TouchableOpacity style={{flexDirection:'row',borderWidth:1,justifyContent:'space-between',borderRadius:30,padding:5,marginTop:4,marginBottom:4,borderColor:'#E7E7E7',
                shadowColor: '#7E7E7E',
                shadowOffset: { width: 2, height: 2 },
                shadowOpacity: 0.25,
                shadowRadius: 7,alignItems:'center',width:250,}}>
                        <Text style={{paddingLeft:10,paddingRight:20,
        color:'#808080',
        fontFamily:'Avenir Next Regular'}}>Rechercher une activité...</Text>
                    
                    <View style={styles.searchButtonScrolled}>
                        <SearchNormal1 color={'#FFFFFF'}  size={17}/>
                    </View>
                </TouchableOpacity>
                    }

                </View>
                :
                <View> </View>
                }
                
            <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center',position:'absolute',right:0}}>
            {
                page === 'Home' && userD!==undefined && isSmallDevice==true ?
            <TouchableOpacity onPress={() => navigation.navigate('InscriptionActivite')}><Text style={{...styles.TextLA,alignContent:'center',marginRight:10}}>{'Inscrire mon établissement'}</Text></TouchableOpacity>
        
            :
            <View> </View>
            }
                { userD !== undefined ?
            
                <View style={styles.containerProfilMenu}>
                    
                    <TouchableOpacity style={styles.buttonHearth} onPress={() => setMenuVisible(!menuVisible)}>
                        <Heart color={'#000000'}  size={24}/>

                    </TouchableOpacity>
                    <TouchableOpacity style={styles.buttonMenu} onPress={() => setMenuVisible(!menuVisible)}>
                        <HambergerMenu color={'#000000'}  size={24}/>

                    </TouchableOpacity>
                    {
                        userD ?
                        userD.photoURL ?
                        <TouchableOpacity style={{...styles.buttonProfil,overflow:'hidden',width:45}} onPress={()=> navigation.navigate('SettingsProfil')}>
                                <CustomImage source={{uri: userD.photoURL}} style={{width: 60, aspectRatio:1/1,position:'absolute' }} />
                            </TouchableOpacity>
                            :
                            <TouchableOpacity style={styles.buttonProfil} onPress={()=> navigation.navigate('SettingsProfil')}>
                            <Profile color={'#FFFFFF'} variant='Bold'  size={24}/>
                            </TouchableOpacity>
                        :
                            null
                    }
                </View>
                :
                <View style={styles.containerProfilMenu}>
                    
                    <TouchableOpacity style={styles.buttonHearth} onPress={() => setMenuVisible(!menuVisible)}>
                        <Heart color={'#000000'}  size={24}/>

                    </TouchableOpacity>
                <TouchableOpacity style={styles.buttonMenu} onPress={() => setMenuVisible(!menuVisible)}>
                        <HambergerMenu color={'#000000'}  size={24}/>
                </TouchableOpacity>
                <TouchableOpacity style={styles.buttonProfil} onPress={()=> navigation.navigate('Login')}>
                        <Profile color={'#FFFFFF'} variant='Bold'  size={24}/>
                </TouchableOpacity>
            </View>

                }
                </View>
            </View>
    
        </View>
           {
            route.name === 'Home' ? 
            <ActivityFilter onActivitySelect={onActivitySelect} setOnActivitySelect={setOnActivitySelect}/>
            :
            null
           }     

        <Animated.View style={{...styles.menu,overflow:'hidden',
                opacity: menuPosition,
                width: menuPosition,}}>
                     {
                    page === 'Home' && userD==undefined ?
                    <View style={{justifyContent:'center',alignItems:'center'}}>
                     <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Login')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Parents</Text>
    </TouchableOpacity>

    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Organisations</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Municipalités</Text>
    </TouchableOpacity>
                <View style={{width:'100%',borderBottomColor:'#E7E7E7',borderBottomWidth:1}}/>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Centre d'aide</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Blog</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Newsletter</Text>
    </TouchableOpacity>
    
                </View>
                :
                <View style={{justifyContent:'center',alignItems:'center'}}>
                <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={()=> navigation.navigate('Home')}>
                    <Text style={{...styles.TextLASelected,color:'black'}}>Acceuil</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={()=> navigation.navigate('Dashboard')}>
                    <Text style={{...styles.TextLASelected,color:'black'}}>Dashboard</Text>
                </TouchableOpacity>
                <View style={{width:'100%',borderBottomColor:'#E7E7E7',borderBottomWidth:1}}/>
                     <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Login')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Parents</Text>
    </TouchableOpacity>

    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Organisations</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Municipalités</Text>
    </TouchableOpacity>
                <View style={{width:'100%',borderBottomColor:'#E7E7E7',borderBottomWidth:1}}/>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Centre d'aide</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Blog</Text>
    </TouchableOpacity>
    <TouchableOpacity style={{minWidth:120,width:'100%',padding:15}} onPress={() => navigation.navigate('Signin')}>
        <Text style={{...styles.TextLASelected,color:'black'}}>Newsletter</Text>
    </TouchableOpacity>

            </View>
                }
            
        </Animated.View>
        </View>
        
        </View>
    );
}

const { width, height } = Dimensions.get('window');
const getStyles = (width) => {
  const isSmallDevice = width < 1920;

  return StyleSheet.create({
    autocompleteContainer:{
        flex: 1,
        width:'100%',
        zIndex: 1,
    },
    buttonMenuOpen: {
        padding:20,
        borderBottomWidth:1
    },
    navbar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        paddingTop: 20,
        paddingBottom: 20,
        width: '100vw',
        zIndex:10,
        position:'fixed',
        top:0,
        borderBottomWidth:1,
        borderColor:'#E7E7E7',

        
        // Ajoutez d'autres styles pour la navbar comme vous le souhaitez
    },
    searchBar: {
        maxWidth:'80vw',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent:'center',
        alignItems:'center',
        borderRadius: 100,
        borderWidth: 1,
        borderColor: '#E7E7E7',
        shadowColor: '#E7E7E7',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.40,
        shadowRadius: 7,
        elevation: 5,  // ajouté pour l'ombre sur Android
    },
    LA: {
        flexDirection: 'row',
    },
    logols: {
        marginLeft:80,
        minWidth:150,
        padding:20,
        resizeMode:'cover',
    },
    logo: {
        width:'100%',
        height: '100%',
        resizeMode:'contain',
    },
    
    inputSB: {
        height: 'auto',
        paddingTop: 3,
        paddingBottom: 10,
        fontSize: 15,
        color:'#808080',
        fontFamily:'Avenir Next Regular'
    },
    input: {
        maxWidth:'20%',
        outlineStyle: 'none',
        marginLeft:10,
        borderRightWidth:1,
        borderColor: '#ddd',
        padding: 10,
        margin: 5,
        width: '100%',
        fontFamily: 'Avenir Next Regular',
        fontWeight:'bold',
    },
    input1: {
        maxWidth:'20%',
        minWidth:100,
        outlineStyle: 'none',
        marginLeft:10,
        borderRightWidth:1,
        borderColor: '#ddd',
        padding: 10,
        margin: 5,
        width: '100%',
        fontFamily: 'Avenir Next Regular',
        fontWeight:'bold',
    },
    day: {
        justifyContent:'center',
        alignItems:'center',
        width:40,
        height:40,
        margin: 2.5,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius:100
    },
    daySelected: {
        justifyContent:'center',
        alignItems:'center',
        width:40,
        height:40,
        margin: 2.5,
        borderWidth: 1,
        borderColor: '#ddd',
        backgroundColor: '#6F2CF7',
        borderRadius:100
    },
    textDay: {
        color:'black',
        fontSize:10,
        fontFamily: 'Avenir Next Regular',
        fontWeight:'bold',
    },
    textDaySelected: {
        color:'white',
        fontSize:10,
        fontFamily: 'Avenir Next Regular',
        fontWeight:'bold',
    },
    buttonLA: {
        borderRadius: 100,
        marginLeft:2.5,
        marginRight:2.5,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:15,
        paddingBottom:15,
        borderWidth: 1,
        borderColor: '#E7E7E7',
        justifyContent:'center',
        alignContent:'center',
        backgroundColor: '#fff',
    },
    buttonLASelected: {
        borderRadius: 100,
        marginLeft:5,
        marginRight:5,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:15,
        paddingBottom:15,
        justifyContent:'center',
        alignContent:'center',
        alignContent:'center',
        backgroundColor: '#6F2CF7',  // Couleur violet
    },
    TextLA: {
        color:'black',
        fontWeight:'Bold',
        fontFamily: 'Avenir Next Regular',
    },
    TextLASelected: {
        fontWeight:'Bold',
        color:'white',
        fontFamily: 'Avenir Next Regular',
    },
    searchButton: {
        right:0,
        borderRadius: 100,
        border: 1 ,
        borderWidth: 1,
        marginRight:5,
        marginLeft:7,
        padding:15,
        backgroundColor: '#6F2CF7', 

    },
    searchButtonScrolled: {
        right:0,
        borderRadius: 100,
        border: 1 ,
        borderWidth: 1,
        marginLeft:7,
        padding:10,
        backgroundColor: '#6F2CF7', 

    },
    containerProfilMenu: {
        flexDirection: 'row',
    },
    buttonMenu:{
        borderRadius:50,
        borderWidth:1,
        borderColor:'#E7E7E7',
        padding:10,
        margin:5,
        marginHorizontal:2.5,
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center'
    },
    buttonHearth:{
        borderRadius:50,
        borderWidth:1,
        borderColor:'#E7E7E7',
        padding:10,
        margin:5,
        marginHorizontal:2.5,
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center'
    },
    buttonProfil:{
        borderRadius:50,
        borderWidth:1,
        borderColor:'#6F2CF7',
        padding:10,
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
        backgroundColor:'#6F2CF7',
        borderRadius:40, 
        marginHorizontal:2.5,
        margin:5,
        zIndex:10,
        elevation:10
    },
    iconMenuProfil : {
        width: 20,
        padding: 10,
    },
    menu: {
        position: 'fixed',
        padding:10,
        top: 100,
        right: 80,
        width: 300, // Largeur du menu
        backgroundColor:'white',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        borderRadius:15,
        elevation: 9,
        minHeight: 300,
        zIndex:15,
         // Couleur de fond du menu
        // Ajoutez d'autres styles pour votre menu
    },
    
    LinearBordure : {
        width:'100%',
        height:10
    }
})
};
