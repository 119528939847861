import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1;
const MIN = 3;
const MAX = 18;

function AgeRangePicker({ ageMin, ageMax, onChange }) {
  const defaultMin = ageMin !== undefined && ageMin !== null ? ageMin : MIN;
  const defaultMax = ageMax !== undefined && ageMax !== null ? ageMax : MAX;
  const [values, setValues] = useState([defaultMin, defaultMax]);

  // Mettre à jour les valeurs lorsque les props changent
  useEffect(() => {
    setValues([
      ageMin !== undefined && ageMin !== null ? ageMin : defaultMin,
      ageMax !== undefined && ageMax !== null ? ageMax : defaultMax
    ]);
  }, [ageMin, ageMax]);

  const handleChange = (values) => {
    setValues(values);
    if (onChange) {
      onChange(values);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '1em',
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#ccc', '#6F2CF7', '#ccc'],
                  min: MIN,
                  max: MAX
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '18px',
              width: '18px',
              borderRadius:20,
              backgroundColor: '#6F2CF7',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                borderRadius:20,
                height: '16px',
                width: '16px',
                backgroundColor: isDragged ? '#ccc' : '#6F2CF7'
              }}
            />
          </div>
        )}
      />
      <output style={{ marginTop: '10px', color: '#6F2CF7', fontWeight: 'bold', fontFamily: 'Avenir Next Bold' }} id="output">
        {values[0]} ans - {values[1]} ans
      </output>
    </div>
  );
}

export default AgeRangePicker;
