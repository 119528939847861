import React, { useState, useEffect, useRef } from 'react';

import { View,Animated, StyleSheet, Text, Button, TouchableOpacity,TextInput,Pressable,Dimensions } from 'react-native';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence
import CustomImage from '../components/customImage.js';
import { onAuthStateChanged } from "firebase/auth";
import Checkbox from 'expo-checkbox';
import { Ionicons } from '@expo/vector-icons';
import { ActivityIndicator } from 'react-native';

// Exemple d'écrans - Remplacez ceci par vos propres composants

function MyCheckbox() {
    const [checked, setChecked] = useState(false);
    return (
      <Pressable
        style={[styles.checkbox, checked && styles.checkboxChecked]}
        onPress={() => setChecked(!checked)}>
        {checked && <Ionicons name="checkmark" size={15} color="white" />}
      </Pressable>
    );
  }
// Composant Animated pour TouchableOpacity
const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

export default function  LoginScreen({ navigation }) {
    
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const widthAnim = useRef(new Animated.Value(screenWidth/3)).current;
    const [isRow, setIsRow] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    

     // Fonction pour animer la largeur
     const animateWidth = (expand) => {
        Animated.timing(widthAnim, {
          toValue: expand ? screenWidth/3 : 50, // 200 pour la largeur originale, 100 pour la largeur réduite
          duration: 100,
          useNativeDriver: false
        }).start();
      };
        const loginUser = async (email, password) => {
            setIsLoading(true); // Commence l'animation de chargement
            animateWidth(false); // Réduit la largeur du bouton
            try {
                console.log("Étape 1: Tentative de connexion de l'utilisateur");
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                
                console.log("Étape 2: Utilisateur connecté avec succès");
                const user = userCredential.user;
                console.log("Utilisateur connecté in LOGIN:", user);
                
                console.log("Étape 3: Navigation vers la page d'accueil");
                navigation.navigate('Home');
            } catch (error) {
                console.error("Échec à l'étape 1 ou 2: Erreur lors de la connexion de l'utilisateur");
                console.error("Erreur de connexion in LOGIN:", error);
                // Ici, vous pouvez également gérer l'affichage d'un message d'erreur à l'utilisateur
            } finally {
                animateWidth(true); // Réduit la largeur du bouton
                setIsLoading(false); // Arrête l'animation de chargement dans tous les cas
            }
        };
    
        useEffect(() => {
            const updateLayout = () => {
                const newWidth = Dimensions.get('window').width;
                setScreenWidth(newWidth);
                const newTotalWidthNeeded = 1300 ;
                setIsRow(newWidth > newTotalWidthNeeded);
            };
    
            Dimensions.addEventListener('change', updateLayout);
            return () => Dimensions.removeEventListener('change', updateLayout);
        }, [screenWidth]);

        useEffect(() => {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("Utilisateur connecté in LOGIN gth :", user);
                navigation.navigate('Home')
                // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
             } else {
                console.log("Utilisateur déconnecté in LOGIN");
                // Mettez à jour votre état en conséquence
            }
        });
  
    return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
  }, [auth]);

    return (
      <View style={{ flex: 1,flexDirection:'row' }}>
        <View style={{flex :1,justifyContent:'center',alignItems:'center'}}>
            <View style={{justifyContent:'center',width:'70%'}}>
            <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{marginBottom:20}}>
                <CustomImage source={require('../assets/Logo4x.png')} style={styles.logo} />
            </TouchableOpacity>
            <Text style={{fontSize:'2vw',fontFamily:'Avenir Next Regular'}}>Connectez-vous </Text>
            <View style={{marginTop:30,alignContent:'center',justifyContent:'center'}}>
                <Text> Adresse email</Text>
                <TextInput 
                style={styles.input} 
                placeholder="Email"
                value={email}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={setEmail}
                />
                <Text style={{marginTop:10}}> Mot de passe</Text>
                <TextInput 
                style={styles.input} 
                placeholder="Mot de passe"
                value={password}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={setPassword}
                secureTextEntry={true}
                />
                <View style={{flexDirection:'row',margin:5,marginTop:10,marginBottom:10,alignItems:'center'}}>
                
                    <MyCheckbox />
                    <Text>Se souvenir de moi</Text>
                </View>
                <View style={{width:'100%',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                    
                <AnimatedTouchableOpacity 
                    onPress={() => {
                      loginUser(email, password);
                    }}
                    style={[styles.buttonLogin, { width: widthAnim }]}> 
                    {isLoading ? 
                        <ActivityIndicator size="small" color="#ffffff" /> : 
                        <Text style={styles.textLogin}>Se connecter</Text>
                    }
                </AnimatedTouchableOpacity>
                
                </View>

                <View style={{flexDirection:'row',padding:10,height:50,justifyContent:'center',alignItems:'center'}}>
                    <View style={{height:5,width:'100%',borderBottomWidth:1,flex:1,borderColor:'#c1bebe'}}></View>
                    <View style={{padding:10}}> <Text>Ou</Text> </View>
                    <View style={{height:5,width:'100%',borderBottomWidth:1,flex:1,borderColor:'#c1bebe'}}></View>
                </View>


                {
                    console.log('isRow :',isRow)
                }
                <View style={{flexDirection: isRow ? 'row' : 'column',justifyContent:'center',alignItems:'center'}}>
                <TouchableOpacity style={{...styles.buttonLoginOther,marginTop:10}}> 
                    <CustomImage source={require('../assets/googleLogo.png')} style={styles.LogoLogin}/>
                    <Text style={styles.textLoginOther}>Se connecter avec Google</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{...styles.buttonLoginOther,marginTop:10}}> 
                    <CustomImage source={require('../assets/appleLogo.png')} style={styles.LogoLogin}/>
                    <Text style={styles.textLoginOther}>Se connecter avec Apple</Text>
                </TouchableOpacity>


                </View>

                <View style={{marginTop:20,justifyContent:'center',alignItems:"center",width:'100%'}}>
                    <TouchableOpacity style={styles.buttonTextInsc}> <Text style={styles.textInsc}>Mot de passe oublié ?</Text> </TouchableOpacity>
                    <View style={{flexDirection:'row',width:'100%',maxWidth:'80vw',justifyContent:'center',alignItems:'center',padding:5}}>
                        <Text>Vous n'avez pas de compte ? </Text>
                        <TouchableOpacity onPress={()=> navigation.navigate('Signin')} style={styles.buttonTextInsc}> <Text style={styles.textInsc}>S'inscrire</Text> </TouchableOpacity>
                    </View>
                </View>
            </View>
            </View>
                
        </View>
        <View style={{flex:1}}>
            <CustomImage source={require('../assets/LoginImg.png')} style={{width:'100%',height:'100%',borderTopLeftRadius:50,borderBottomLeftRadius:50}}/>
        </View>
      </View>
    );
  }

const styles = StyleSheet.create({
    navbar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        width: '100%',
        // Ajoutez d'autres styles pour la navbar comme vous le souhaitez
    },
    text: {
        color:'black',
        
        fontFamily: 'Avenir Next Regular',
    },
    textLogin: {
        color:'white',
        
        fontFamily: 'Avenir Next Regular',
    },
    textLoginOther: {
        color:'black',
        padding:10,
        marginRight:5,
        fontFamily: 'Avenir Next Regular',
    },
    textInsc: {
        color:'rgba(111, 44, 247,1)',
        
        fontFamily: 'Avenir Next Regular',
        textDecorationLine:'underline'
    },
    logo: {
        width:'10vw',
        height: '5vh',
        resizeMode:'contain',
    },
    input: {
        outlineStyle: 'none',
        borderColor: '#757575',
        padding: 10,
        width: '100%',
        fontFamily: 'Avenir Next Regular',
        borderRadius: 40,
        borderWidth: 1 ,
    },
    buttonText: {
        width:'100%',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        padding:5
    },
    buttonTextInsc: {
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
    },
    buttonLogin: {
        padding:10,
        
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        borderRadius:50,
        backgroundColor:'#6F2CF7'
    },
    buttonLoginOther: {
        margin:10,
        flexDirection:'row',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        borderRadius:50,
        borderWidth:1,
        borderColor:'#c1bebe'
    },
    LogoLogin : {
        width:24,
        height:24,
        margin:10,
    },
    
    checkbox: {
        alignSelf: 'center',
        alignItems:'center',
        justifyContent:'center',
        marginRight:10,
        borderRadius:5,
        borderWidth:1,
        width: 20,
        height: 20,
    },
    checkboxChecked: {
        backgroundColor:'rgba(111, 44, 247,1)'
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkboxLabel: {
        marginLeft: 8,
        fontWeight: 500,
        fontSize: 18,
    }
})