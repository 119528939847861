import React, {useState,useEffect} from 'react';
import { View, Text, StyleSheet,TouchableOpacity,Image ,
    Dimensions,} from 'react-native';
import { ScrollView } from 'react-native-web';
import { Picker } from "@react-native-picker/picker";

const styles = StyleSheet.create({
  
  containerCard: {
    width: '100%',
    alignItems: 'flex-start',
    gap: 12,
    flexShrink: 0,
    marginRight: 20
},
imageAct: {
    width: '100%',
    aspectRatio: 2 / 3,
    borderRadius: 20,
},
nameAct: {
    color: '#000',
    fontFamily: 'Avenir Next Regular',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
},
iconStar: {
    width: 15,
    height: 15,
    marginRight: 5
},
descAct: {
    color: '#808080',
    fontFamily: 'Avenir Next Regular',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
},
priceAct: {
    fontSize: 15,
    fontFamily: 'Avenir Next Regular',
},
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderBottomWidth: 1,
  },
  cell: {
    width: 100/4, 
    height: 50, 
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderRightWidth: 1,
  },
  hourLabel: {
    width: 100,
    height:30,
    textAlign: 'flex-start',
    color:'white',
    borderLeftWidth:1,
    borderBottomWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    paddingLeft:5,
    backgroundColor:'rgb(162 125 237)'

  },
  filtersContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 10,
  },
  dayLabel: {
    height: 50,
    width:100,
    textAlign: 'center',
    borderRightWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    backgroundColor:'rgb(162 125 237)'

  },
  activity: {
    borderColor: 'blue',
    backgroundColor:'rgba(111, 44, 247,0.3 ) ',
    justifyContent:'center',
    alignContent:'center'
  }
});
const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };


const Activity1 = ({ day,time,room,teacher,address,price,title,key,colorKey }) => (
  <View key={key+'View'} style={{width:300,marginLeft:10}}>
    <TouchableOpacity key={'activity'}  style={styles.containerCard}>
                        <Image source={require('../assets/inscImg1.png')} style={styles.imageAct} />
                        <View style={{ margin: 5, width: '100%' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Text style={styles.nameAct}>{title}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                    <Image source={require('../assets/icon/clock-icon.png')} style={styles.iconStar} />
                                    <Text style={styles.nameAct}>
                                      {time}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Text style={styles.nameAct}></Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                    <Image source={require('../assets/icon/clock-icon.png')} style={styles.iconStar} />
                                    <Text style={styles.nameAct}>
                                      {day === 'Lun' ? 'Lundi' : day === 'Mar' ? 'Mardi' : day === 'Mer' ? 'Mercredi' : day === 'Jeu' ? 'Jeudi' : day === 'Ven' ? 'Vendredi' : day === 'Sam' ? 'Samedi' :  day === 'Dim' ? 'Dimanche' : day}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <Text style={styles.descAct}>{room}</Text>
                                <Text style={styles.descAct}>{teacher.name}</Text>
                                
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <Text style={styles.priceAct}><b>{price} crédits</b> par activités</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
  </View>
);

const Activity = ({ day,time,room,teacher,age,category,address,price,title,group,key,colorKey }) => (
  <View style={{marginRight:10}}>
        <View style={{ width: 265, marginBottom: 40 }}>
            <TouchableOpacity onPress={() => console.log('clicked')} style={styles.containerCard}>
                <Image source={require('../assets/judo.jpeg')} style={styles.imageAct} />
                <View style={{ margin: 5, width: '100%' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <Text style={styles.nameAct}>{group}</Text>
                    </View>
                    <View style={{ justifyContent: 'space-between', width: '100%' }}>
                        {age[1] == null ?
                            <Text style={styles.nameAct}>Dès {age[0]} ans</Text>
                            :
                            <Text style={styles.nameAct}>De {age[0]} à {age[1]} ans</Text>
                        }
                        {category == 'Activité récurrente' ?
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                <Text style={styles.nameAct}>
                                    {daysMapping[day] || day}
                                </Text>
                                <Text style={styles.nameAct}>  {time}</Text>
                            </View>
                            :
                            category == 'Activité unique' ?
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                    <Text style={styles.nameAct}>  Luc : a faire </Text>
                                </View>
                                :
                                category == 'Stage' ?
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                        <Text style={styles.nameAct}>  Luc : a faire </Text>
                                    </View>
                                    : null
                        }
                    </View>
                    <View style={{ marginTop: 5 }}>
                        <Text style={styles.descAct}>{room}</Text>
                        <Text style={styles.descAct}>{teacher.name}</Text>
                    </View>
                    <View style={{ marginTop: 5 }}>
                        <Text style={styles.priceAct}><b>{price} crédits</b> par activités</Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    </View>
);
// ...
const ListingActivitiesHour = ({ profilInfo }) => {
  // Organizing data by city
  const groupByCity = {};
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedDay, setSelectedDay] = useState("Tous");
  const [selectedRoom, setSelectedRoom] = useState("Tous");
  const daysOrdered = ['Tous', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  const daysMapping = {
    'MON': 'Lundi',
    'TUE': 'Mardi',
    'WED': 'Mercredi',
    'THU': 'Jeudi',
    'FRI': 'Vendredi',
    'SAT': 'Samedi',
    'SUN': 'Dimanche'
  };
  const daysMappingInverse = {
    Lundi: "MON",
    Mardi: "TUE",
    Mercredi: "WED",
    Jeudi: "THU",
    Vendredi: "FRI",
    Samedi: "SAT",
    Dimanche: "SUN",
  };
  const [animateListItems, setAnimateListItems] = useState(false);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [days, setDays] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  useEffect(() => {
    const dayOrder = { MON: 1, TUE: 2, WED: 3, THU: 4, FRI: 5, SAT: 6, SUN: 7 };

    let tempFilteredActivities = [];
    Object.values(profilInfo.places).forEach((place) => {
      place.calendar.forEach((activity) => {
        const dayInEnglish =
          selectedDay === "Tous" ? "all" : daysMappingInverse[selectedDay];
        const isDayMatch =
          dayInEnglish === "all" || activity.day === dayInEnglish;
        const isRoomMatch =
          selectedRoom === "Tous" || activity.room === selectedRoom;
        const isTeacherMatch =
          selectedTeacher === "Tous" || activity.teacher === selectedTeacher;

        if (isDayMatch && isRoomMatch && isTeacherMatch) {
          tempFilteredActivities.push(activity);
        }
      });
    });

    // Trier les activités par jour, puis par heure
    tempFilteredActivities.sort((a, b) => {
      const dayA = dayOrder[a.day];
      const dayB = dayOrder[b.day];
      if (dayA !== dayB) return dayA - dayB;

      const timeA = parseInt(a.time.split(" - ")[0].replace(":", ""), 10);
      const timeB = parseInt(b.time.split(" - ")[0].replace(":", ""), 10);
      return timeA - timeB;
    });

    tempFilteredActivities.push({ isAddButton: true });
    setFilteredActivities(tempFilteredActivities);
    setIsLoading(false);
  }, [profilInfo, selectedDay, selectedRoom, selectedTeacher]);

  useEffect(() => {
    // Sets pour garantir des valeurs uniques
    const daysSet = new Set(["Tous"]); // Initialise avec l'option 'Tous'
    const roomsSet = new Set(["Tous"]); // Initialise avec l'option 'Tous'
    const teachersSet = new Set(["Tous"]); // Initialise avec l'option 'Tous'

    // Parcourir les données pour collecter les valeurs uniques
    Object.values(profilInfo.places).forEach((place) => {
      place.calendar.forEach((activity) => {
        daysSet.add(daysMapping[activity.day]); // Utiliser le mapping pour stocker les valeurs en français
        roomsSet.add(activity.room);
        teachersSet.add(activity.teacher);
      });
    });

    // Convertir les ensembles en tableaux et mettre à jour les états pour les Picker
    setDays(Array.from(daysSet));
    setRooms(Array.from(roomsSet));
    setTeachers(Array.from(teachersSet));
    setAnimateListItems(true); // Réactiver l'animation lors du changement de filtre
  }, [profilInfo]);

  useEffect(() => {
    const dayOrder = { 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6, 'SUN': 7 };
  
    let tempFilteredActivities = [];
    
  if (profilInfo && profilInfo.places) {
    Object.values(profilInfo.places).forEach(place => {
      place.calendar.forEach(activity => {
        const dayInEnglish = selectedDay === 'Tous' ? 'all' : daysMappingInverse[selectedDay];
        const isDayMatch = dayInEnglish === 'all' || activity.day === dayInEnglish;
        const isRoomMatch = selectedRoom === 'Tous' || activity.room === selectedRoom;
        const isTeacherMatch = selectedTeacher === 'Tous' || activity.teacher.name === selectedTeacher;
  
        if (isDayMatch && isRoomMatch && isTeacherMatch) {
          tempFilteredActivities.push(activity);
        }
      });
    });
  }
  
    // Trier les activités par jour, puis par heure
    tempFilteredActivities.sort((a, b) => {
      const dayA = dayOrder[a.day];
      const dayB = dayOrder[b.day];
      if (dayA !== dayB) return dayA - dayB;
  
      const timeA = parseInt(a.time.split(" - ")[0].replace(":", ""), 10);
      const timeB = parseInt(b.time.split(" - ")[0].replace(":", ""), 10);
      return timeA - timeB;
    });
  
tempFilteredActivities.push({ isAddButton: true });
setFilteredActivities(tempFilteredActivities);
    setIsLoading(false);
  }, [profilInfo, selectedDay, selectedRoom, selectedTeacher]);

  const groupByRecurrent = {};

Object.keys(profilInfo.places).forEach(index => {
  const place = profilInfo.places[index];

  place.calendar.forEach(activity => {
    const recurrentType = activity.recurrent;
    
    if (!groupByRecurrent[recurrentType]) {
      groupByRecurrent[recurrentType] = [];
    }

    groupByRecurrent[recurrentType].push({
      ...activity,
      address: place.address, // Ajouter l'adresse de 'place' à 'activity'
      city: place.city       // Ajouter la ville aussi, pourquoi pas ?
    });
  });
});

  return (
      <View style={{ marginTop: 20, borderRadius: 50,width:'100vw'}}>
        
        <View style={styles.filtersContainer}>
          {screenWidth < 760 ? (
            <View>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  itemStyle={{
                    backgroundColor: "grey",
                    color: "blue",
                    fontFamily: "Avenir Next Bold",
                    fontSize: 17,
                  }}
                  selectedValue={selectedDay}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedDay(itemValue)
                  }
                >
                  {daysOrdered.map((day) => (
                    <Picker.Item key={day} label={day} value={day} />
                  ))}
                </Picker>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  selectedValue={selectedRoom}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedRoom(itemValue)
                  }
                >
                  {rooms.map((room) => (
                    <Picker.Item key={room} label={room} value={room} />
                  ))}
                </Picker>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  selectedValue={selectedTeacher}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedTeacher(itemValue)
                  }
                >
                  {teachers.map((teacher) => (
                    <Picker.Item key={teacher.id} label={teacher.name} value={teacher.id} />
                  ))}
                </Picker>
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  itemStyle={{
                    backgroundColor: "grey",
                    color: "blue",
                    fontFamily: "Avenir Next Bold",
                    fontSize: 17,
                  }}
                  selectedValue={selectedDay}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedDay(itemValue)
                  }
                >
                  {daysOrdered.map((day) => (
                    <Picker.Item key={day} label={day} value={day} />
                  ))}
                </Picker>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  selectedValue={selectedRoom}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedRoom(itemValue)
                  }
                >
                  {rooms.map((room) => (
                    <Picker.Item key={room} label={room} value={room} />
                  ))}
                </Picker>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  marginRight: 10,
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Picker
                  style={{ borderWidth: 0, padding: 5 }}
                  selectedValue={selectedTeacher}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedTeacher(itemValue)
                  }
                >
                  {teachers.map((teacher) => (
                    <Picker.Item key={teacher.id} label={teacher.name} value={teacher.id} />
                  ))}
                </Picker>
              </View>
            </View>
          )}
        </View>
          {
    Object.keys(groupByRecurrent).map(recurrentType => (
        <View key={recurrentType} style={{marginBottom:50}}>
            {/* Afficher le type de récurrence */}
            <Text style={{ fontWeight: 'bold' }}>
                            {recurrentType === 'recurrent' ? 'Événements Réguliers' : 'Événements Ponctuels'}
                        </Text>
            <ScrollView isScrollable={true} showsHorizontalScrollIndicator={false} horizontal={true} style={{marginLeft:-150}}>
                <View style={{ flexDirection: 'row',marginRight:150,marginLeft:150 }}>
                
                    {
                        // Pour chaque type de récurrence, parcourir ses activités
                        groupByRecurrent[recurrentType].map((activity, idx) => (
                            <Activity
                                day={activity.day}
                                time={activity.time}
                                room={activity.room}
                                price={activity.price}
                                teacher={activity.teacher}
                                title={activity.group} // Assurez-vous que ceci est correct
                                key={activity.id}
                                address={activity.address}
                                age={activity.age}
                                recurrent={activity.recurrent}
                                colorKey={'B'} // Peut-être dynamiser cette valeur aussi ?
                            />
                        ))
                    }
                </View>
            </ScrollView>
        </View>
    ))
}

      </View>
  );
}


export default ListingActivitiesHour;
