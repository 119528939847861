import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const renderMemberItem = ({ item, openEditPanel, privilege }) => {
  const placeholderImage = 'https://via.placeholder.com/150';
  const profilePicture = item.profilePicture ? { uri: item.profilePicture } : { uri: placeholderImage };

  const memberName = `${item.name || 'Nom inconnu'} ${item.lastname || ''}`;
  const memberRoles = item.role && Array.isArray(item.role) ? item.role.map(role => privilege[role]).join(', ') : 'Rôle inconnu';

  return (
    <TouchableOpacity onPress={() => openEditPanel(item)} style={styles.memberItem}>
      <View style={styles.memberCard}>
        <Image 
          source={profilePicture}
          style={styles.profileImage}
        />
        <Text style={styles.nameText} numberOfLines={1} ellipsizeMode="tail">
          {memberName}
        </Text>
        <Text style={styles.roleText}>
          {memberRoles}
        </Text>
        <View style={styles.iconContainer}>
          <TouchableOpacity onPress={() => { /* Logique pour appeler */ }}>
            <Ionicons name="call-outline" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { /* Logique pour envoyer un email */ }}>
            <Ionicons name="mail-outline" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { /* Logique pour envoyer un message */ }}>
            <Ionicons name="chatbox-ellipses-outline" size={24} color="black" />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  memberItem: {
    padding: 10,
    width: 250,
    margin: 10,
    backgroundColor: '#fff',
    borderRadius: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
  },
  memberCard: {
    alignItems: 'center',
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 10,
  },
  nameText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    maxWidth: 200, // Limiter la largeur pour éviter les débordements
  },
  roleText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 10,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
  },
});

export default renderMemberItem;
