// LegalNameInput.js
import React, { useState, useCallback } from 'react';
import { TextInput,StyleSheet, View, TouchableOpacity, Text } from 'react-native';

const EmailInput = ({ initialEmail, onUpdate }) => {
    const [email, setEmail] = useState(initialEmail);

    const handleTextChange = useCallback((text) => {
        setEmail(text);
        onUpdate(text); // Propage la mise à jour vers le composant parent
    }, [onUpdate]);

    return (
        <View style={styles.inputGroup}>
            <TextInput
                style={styles.infoText}
                placeholder='Entrez votre email'
                value={email}
                onChangeText={handleTextChange}
            />
            {/* ...autres éléments d'interface utilisateur si nécessaire */}
        </View>
    );
};

const styles = StyleSheet.create({

    inputGroup: {
        flexDirection:'row',
        justifyContent:'space-between',
      },
})
export default EmailInput;
