// LegalNameInput.js
import React, { useState, useCallback } from 'react';
import { TextInput,StyleSheet, View, TouchableOpacity, Text } from 'react-native';

const AdressInput = ({ initialAdress, onUpdate }) => {
    const [adress, setAdress] = useState(initialAdress);

    const handleTextChange = useCallback((text) => {
        setAdress(text);
        onUpdate(text); // Propage la mise à jour vers le composant parent
    }, [onUpdate]);

    return (
        <View style={styles.inputGroup}>
            <TextInput
                style={styles.infoText}
                placeholder='Entrez votre Adresse'
                value={adress}
                onChangeText={handleTextChange}
            />
            {/* ...autres éléments d'interface utilisateur si nécessaire */}
        </View>
    );
};

const styles = StyleSheet.create({

    inputGroup: {
        flexDirection:'row',
        justifyContent:'space-between',
      },
})
export default AdressInput;
