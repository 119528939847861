import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const FirstNameInput = ({ initialName, onUpdate }) => {
  const [firstName, setFirstName] = useState(initialName);

  useEffect(() => {
    setFirstName(initialName);
  }, [initialName]);

  const handleChange = (text) => {
    setFirstName(text);
    onUpdate(text);
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        value={firstName}
        onChangeText={handleChange}
        placeholder="Prénom"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
});

export default FirstNameInput;
