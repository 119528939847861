import React, { useState, useMemo, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Image, FlatList, Animated, Dimensions, Alert } from 'react-native';
import algoliasearch from 'algoliasearch/lite';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../firebase'; // Assurez-vous que ce chemin est correct
import * as Location from 'expo-location';
import { Star } from 'iconsax-react-native';
import _ from 'lodash';
import { getFunctions, httpsCallable } from 'firebase/functions';
const ALGOLIA_APP_ID = "CW1522LSUY";
const ALGOLIA_API_KEY = "6a99ff5d6dccd69b4309971d3ad9f4a7";
const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const index = searchClient.initIndex('Place');

const Places = ({ navigation, onActivitySelect, searchActivity, address, selectedDay, category }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activitiesIdArray, setActivitiesIdArray] = useState([]);
  const [activitiesInfoArray, setActivitiesInfoArray] = useState({});
  const [location, setLocation] = useState(null);
  const [locationPermission, setLocationPermission] = useState(null);

  const [animatedIndex, setAnimatedIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

  const ITEM_WIDTH = 238; // Largeur de l'élément + marginRight
  const calculateItemsPerRow = (width) => {
    return Math.floor((width - 360) / ITEM_WIDTH);
  };

  const [itemsPerRow, setItemsPerRow] = useState(calculateItemsPerRow(screenWidth));

  useEffect(() => {
    const updateLayout = () => {
      const newWidth = Dimensions.get('window').width;
      const widthDifference = Math.abs(newWidth - screenWidth);
      if (widthDifference > 50) { // Ajustez ce seuil selon vos besoins
        setScreenWidth(newWidth);
        setItemsPerRow(calculateItemsPerRow(newWidth));
      }
    };

    Dimensions.addEventListener('change', updateLayout);
    return () => Dimensions.removeEventListener('change', updateLayout);
  }, [screenWidth, itemsPerRow]);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync().catch(() => Location.requestPermissionsAsync());
      setLocationPermission(status);
      if (status !== 'granted') {
        Alert.alert(
          'Permission de localisation requise',
          'Nous avons besoin de la permission de localisation pour vous montrer les activités à proximité.',
          [{ text: 'OK' }]
        );
        
setLocation(Object.create({"coords":{"latitude":43.483136,"longitude":6.1865984,"altitude":null,"accuracy":1521.024049906203,"altitudeAccuracy":null,"heading":null,"speed":null},"timestamp":1718366171424}));
 
        return;
      }

      let loc = await Location.getCurrentPositionAsync({});
      setLocation(loc);
    })();
  }, []);

  useEffect(() => {
    if (location) {
      fetchActivitiesNearby(location.coords.latitude, location.coords.longitude);
    }
  }, [location]);

  const fetchActivitiesNearby = async (lat, lng) => {
    try {
      const { hits } = await index.search('', {
        aroundLatLng: `${lat},${lng}`,
        aroundRadius: 100000000000000000, // 1000km
      });

      const ids = hits.map(hit => hit.objectID);
      setActivitiesIdArray(ids);
      await fetchActivityDetails(ids);
      console.log('activitiesIdArray', activitiesIdArray);
      console.log('activitiesInfoArray', activitiesInfoArray);
    } catch (error) {
      console.error('Error fetching activities from Algolia:', error);
      setIsLoading(false);
    }
  };

  const fetchActivityDetails = async (ids) => {
    const functions = getFunctions();
    const fetchActivityDetailsFunction = httpsCallable(functions, 'fetchActivityDetails');
  
    try {
      const result = await fetchActivityDetailsFunction({ ids });
      const activitiesData = result.data.activitiesData;
      setActivitiesInfoArray(activitiesData);
      console.log('activitiesData:', activitiesData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching activities from Firebase:', error);
      setIsLoading(false);
    }
  };
  const filteredActivities = useMemo(() => {
    return activitiesIdArray.filter(activity => {
      const activityInfo = activitiesInfoArray[activity];
      return !onActivitySelect || onActivitySelect === activityInfo?.category[0] || onActivitySelect === activityInfo?.category[1] || onActivitySelect === '';
    });
  }, [activitiesIdArray, activitiesInfoArray, onActivitySelect]);

  const getOrganizationType = (choice) => {
    switch (choice) {
      case 0:
        return "Organisation Publique";
      case 1:
        return "Association non lucrative";
      case 2:
        return "Entreprise Privée";
      default:
        return "Type inconnu";
    }
  };

  const renderItem = ({ item, index }) => {
    return <AnimatedListItem item={item} index={index} key={`activity-${item}`} />;
  };

  const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

  const AnimatedListItem = React.memo(({ item: activity, index }) => {
    const scaleValue = useRef(new Animated.Value(1)).current;
    const activityInfo = activitiesInfoArray[activity];
    let imageUrl = activityInfo?.logo ? activityInfo.logo : require('../assets/imageActTest.png');
    let imageCoverUrl = activityInfo?.coverPicture ? activityInfo.coverPicture : require('../assets/imageActTest.png');
    const hasAnimated = useRef(false); // Marqueur pour vérifier si l'élément a déjà été animé

    useEffect(() => {
      const subscription = Dimensions.addEventListener('change', ({ window }) => {
        setScreenWidth(window.width);
        setItemsPerRow(calculateItemsPerRow(window.width));
      });

      return () => subscription?.remove();
    }, []);

    useEffect(() => {
      if (!hasAnimated.current) {
        Animated.timing(scaleValue, {
          toValue: 1,
          duration: 300,
          delay: index * 100, // Délai pour créer un effet en cascade
          useNativeDriver: true,
        }).start(() => {
          hasAnimated.current = true;
        });
      }
    }, [index, scaleValue]);

    if (!activityInfo) return null;

    return (
      <AnimatedTouchableOpacity
        onPress={() => navigation.navigate('ActivityProfil', { idProfil: activity, profilInfo: activityInfo })}
        style={{ ...styles.containerCard, width: `${94 / itemsPerRow}%`, transform: [{ scale: scaleValue }] }}
      >
        <Image source={{ uri: imageCoverUrl }} style={{ ...styles.imageAct, width: '100%', aspectRatio: 1 / 1 }} />
        <Image source={{ uri: imageUrl }} style={{ position: 'absolute', top: 10, left: 10, width: 50, aspectRatio: 1 / 1, borderRadius: 10, backgroundColor: 'white' }} />
        <View style={{ width: '100%' }}>
          <View style={{ marginTop: 5, width: '100%', height: 'auto' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Text style={styles.nameAct}>{activityInfo?.name || 'Nom par défaut'}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                <Star color={'#6F2CF7'} variant='Bold' size={15} style={{ marginRight: 5 }} />
                <Text style={styles.nameAct}>
                  {activityInfo?.note || 'null'}
                </Text>
              </View>
            </View>
            <View style={{ height: 'auto' }}>
              <Text style={styles.descAct}>{getOrganizationType(activityInfo?.typeAct)}</Text>
              <Text style={styles.descAct}>{(activityInfo?.category && activityInfo.category[0]) || 'Type par défaut'} / {(activityInfo?.places && Object.keys(activityInfo.places).length) || 0} lieux</Text>
              <Text style={styles.descAct}>
                {(activityInfo.places ? Object.values(activityInfo.places).reduce((total, place) => total + (place.calendar ? place.calendar.length : 0), 0) : 0)} créneaux
              </Text>
            </View>
            <View style={{ marginTop: 10, flexDirection: 'row', alignItems: 'center', height: 'auto' }}>
              <Text style={styles.nameAct}>De </Text>
              <Text style={styles.nameAct}>
                {activityInfo?.price ? (activityInfo.price[0] || 0) : 0} à {activityInfo?.price ? (activityInfo.price[1] || 0) : 0} crédits
              </Text>
              <Text style={styles.nameAct}> par activités</Text>
            </View>
          </View>
        </View>
      </AnimatedTouchableOpacity>
    );
  });

  if (isLoading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={{ marginTop: 10, justifyContent: 'center', alignContent: 'center', alignItems: 'center', zIndex: 1, width: '100%' }}>
      <FlatList
        data={filteredActivities}
        scrollEnabled={false}
        renderItem={renderItem}
        keyExtractor={(activity) => activity.toString()}
        numColumns={itemsPerRow}
        style={{ width: '90%' }}
        key={`flatlist-${itemsPerRow}`}
        contentContainerStyle={{ paddingHorizontal: 0 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  containerCard: {
    height: '100%',
    alignItems: 'flex-start',
    gap: 12,
    flexShrink: 0,
    margin: 10,
    marginVertical: 20,
  },
  imageAct: {
    width: '100%',
    aspectRatio: 1 / 1,
    borderRadius: 15,
  },
  nameAct: {
    color: '#000',
    fontFamily: 'Avenir Next Regular',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },
  iconStar: {
    width: 15,
    height: 15,
    marginRight: 5
  },
  descAct: {
    color: '#808080',
    fontFamily: 'Avenir Next Regular',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 22,
    height: 'auto'
  },
  priceAct: {
    fontSize: 15,
    fontWeight: 'bold',
  }
});

export default Places;
