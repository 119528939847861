import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 0.25;
const MIN = 8;
const MAX = 23;

function formatHour(value) {
  const hour = Math.floor(value);
  let minute;
  const fraction = value % 1;

  if (fraction === 0) minute = '00';
  else if (fraction === 0.25) minute = '15';
  else if (fraction === 0.5) minute = '30';
  else if (fraction === 0.75) minute = '45';
  else minute = '00';

  return `${hour}h${minute}`;
}

function HourRangePicker({ hourMin = MIN, hourMax = MAX, hourStart = MIN, hourEnd = MAX, onChange }) {
  const [values, setValues] = useState([hourStart, hourEnd]);

  useEffect(() => {
    setValues([hourStart, hourEnd]);
  }, [hourStart, hourEnd]);

  const handleChange = (values) => {
    setValues(values);
    if (onChange) {
      onChange(values);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '1em',
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={hourMin}
        max={hourMax}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#ccc', '#6F2CF7', '#ccc'],
                  min: hourMin,
                  max: hourMax
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '18px',
              width: '18px',
              borderRadius: 20,
              backgroundColor: '#6F2CF7',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                borderRadius: 20,
                height: '16px',
                width: '16px',
                backgroundColor: isDragged ? '#ccc' : '#6F2CF7'
              }}
            />
          </div>
        )}
      />
      <output style={{ marginTop: '10px', color: '#6F2CF7', fontWeight: 'bold', fontFamily: 'Avenir Next Bold' }} id="output">
        De {formatHour(values[0])} à {formatHour(values[1])}
      </output>
    </div>
  );
}

export default HourRangePicker;
