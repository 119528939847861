import React, { useState, useEffect } from 'react';

import { View, StyleSheet, Text, Button, TouchableOpacity,TextInput,Pressable } from 'react-native';
import Checkbox from 'expo-checkbox';
import CustomImage from '../components/customImage.js';
// Exemple d'écrans - Remplacez ceci par vos propres composants
import { getAuth, createUserWithEmailAndPassword, updateProfile,signInWithPopup,GoogleAuthProvider } from "firebase/auth";
import firebase from "firebase/app";
import { Ionicons } from '@expo/vector-icons';
import { getFunctions, httpsCallable } from "firebase/functions";



// Exemple d'écrans - Remplacez ceci par vos propres composants

export default function LoginScreen({ navigation }) {
    const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [CGUCheck, setCGUcheck] = useState(false);
    const [CGVCheck, setCGVcheck] = useState(false);

    const registerUser = async (email, lastName, firstName, phone, password, CGUCheck, CGVCheck) => {
        const auth = getAuth();
        const functions = getFunctions();
        const createUserProfile = httpsCallable(functions, 'createUserProfile');
        
        console.log("Étape 1: Vérification des champs");
        if (email && lastName && firstName && phone && password) {
            if (CGUCheck && CGVCheck) {
                console.log("Étape 2: Création de l'utilisateur");
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    console.log("Étape 3: Utilisateur créé", userCredential);
                    
                    console.log("Étape 4: Mise à jour du profil utilisateur");
                    await updateProfile(auth.currentUser, {
                        displayName: firstName + ' ' + lastName[0],
                        phoneNumber: phone
                    });
                    
                    console.log("Étape 5: Enregistrement des données utilisateur via fonction cloud");
                    const user = userCredential.user;
                    const createdAt = new Date(user.metadata.creationTime).toISOString();
                    const provider = user.providerData[0].providerId;

                    createUserProfile({
                        userId: user.uid,
                        email,
                        lastName,
                        firstName,
                        phone,
                        createdAt,
                        provider
                    })
                    .then((result) => {
                        console.log(result.data.message);
                        console.log("Étape 6: Navigation vers la page d'accueil");
                        navigation.navigate('Home');
                    })
                    .catch((error) => {
                        console.error("Erreur lors de l'appel à la fonction cloud", error);
                    });
                } catch (error) {
                    console.error("Échec à l'étape 3 ou 4: Erreur lors de la création de l'utilisateur ou de la mise à jour du profil", error);
                }
            } else {
                console.error("Veuillez accepter nos conditions générales d'utilisation");
            }
        } else {
            console.error("Tous les champs doivent être remplis");
        }
    };

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
          const result = await signInWithPopup(provider);
          const user = result.user;
          console.log("Utilisateur connecté :", user);
        } catch (error) {
          console.log("Erreur lors de la connexion :", error);
        }
      };
      
    return (
        <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ justifyContent: 'center', width: '70%' }}>
                    <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{ marginBottom: 20 }}>
                        <CustomImage source={require('../assets/Logo4x.png')} style={styles.logo} />
                    </TouchableOpacity>
                    <Text style={{ fontSize: '2vw', fontFamily: 'Avenir Next Regular' }}>Inscrivez-vous </Text>
                    <View style={{ marginTop: 30, alignContent: 'center', justifyContent: 'center' }}>
                        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <View style={{ flex: 1, marginRight: 5 }}>
                                <Text>Nom</Text>
                                <TextInput 
                                    style={styles.input} 
                                    placeholder="Votre Nom de famille"
                                    value={lastName}
                                    onChangeText={setLastName}
                                />
                            </View>
                            <View style={{ flex: 1, marginLeft: 5 }}>
                                <Text>Prénom</Text>
                                <TextInput 
                                    style={styles.input} 
                                    placeholder="Votre Prénom"
                                    value={firstName}
                                    onChangeText={setFirstName}
                                />
                            </View>
                        </View>
                        <Text style={{ marginTop: 10 }}> Adresse email</Text>
                        <TextInput 
                            style={styles.input} 
                            placeholder="Email"
                            value={email}
                            onChangeText={setEmail}
                        />
                        <Text style={{ marginTop: 10 }}> Téléphone</Text>
                        <TextInput 
                            style={styles.input} 
                            placeholder="+33"
                            value={phone}
                            onChangeText={setPhone}
                        />
                        <Text style={{ marginTop: 10 }}> Mot de passe</Text>
                        <TextInput 
                            style={styles.input} 
                            placeholder="Mot de passe"
                            value={password}
                            onChangeText={setPassword}
                            secureTextEntry={true}
                        />
                        <View style={{ flexDirection: 'row', margin: 5, marginTop: 10, marginBottom: 10, alignItems: 'center' }}>
                            <Pressable
                                style={[styles.checkbox, CGUCheck && styles.checkboxChecked]}
                                onPress={() => setCGUcheck(!CGUCheck)}>
                                {CGUCheck && <Ionicons name="checkmark" size={15} color="white" />}
                            </Pressable>
                            <Text>J'accepte les Conditions générales d'utilisation : </Text>
                            <TouchableOpacity style={styles.buttonTextInsc}>
                                <Text style={styles.textInsc}>CGU</Text>  
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'row', margin: 5, marginTop: 10, marginBottom: 10, alignItems: 'center' }}>
                            <Pressable
                                style={[styles.checkbox, CGVCheck && styles.checkboxChecked]}
                                onPress={() => setCGVcheck(!CGVCheck)}>
                                {CGVCheck && <Ionicons name="checkmark" size={15} color="white" />}
                            </Pressable>
                            <Text>J'accepte les Conditions générales de vente : </Text>
                            <TouchableOpacity style={styles.buttonTextInsc}> 
                                <Text style={styles.textInsc}>CGV</Text>  
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity onPress={() => registerUser(email, lastName, firstName, phone, password, CGUCheck, CGVCheck)} style={{ ...styles.buttonLogin, marginTop: 10 }}> 
                            <Text style={styles.textLogin}>S'inscrire</Text> 
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', padding: 10, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                            <View style={{ height: 5, width: '100%', borderBottomWidth: 1, flex: 1, borderColor: '#c1bebe' }}></View>
                            <View style={{ padding: 10 }}> <Text>Ou</Text> </View>
                            <View style={{ height: 5, width: '100%', borderBottomWidth: 1, flex: 1, borderColor: '#c1bebe' }}></View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={{ ...styles.buttonLoginOther, marginTop: 10 }} onPress={googleSignIn}> 
                                <CustomImage source={require('../assets/googleLogo.png')} style={styles.LogoLogin}/>
                                <Text style={styles.textLoginOther}>S'inscrire avec Google</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ ...styles.buttonLoginOther, marginTop: 10 }}> 
                                <CustomImage source={require('../assets/appleLogo.png')} style={styles.LogoLogin}/>
                                <Text style={styles.textLoginOther}>S'inscrire avec Apple</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ marginTop: 20, justifyContent: 'center', alignItems: "center", width: '100%' }}>
                            <View style={{ flexDirection: 'row', width: '100%', maxWidth: '80vw', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
                                <Text>Vous avez déjà un compte ? </Text>
                                <TouchableOpacity onPress={() => navigation.navigate('Login')} style={styles.buttonTextInsc}> 
                                    <Text style={styles.textInsc}>Se connecter</Text> 
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <CustomImage source={require('../assets/SignInImg.jpg')} style={{ width: '100%', height: '100%', borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
  navbar: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      backgroundColor: 'rgba(240, 240, 240, 0.8)',
      width: '100%',
  },
  text: {
      color:'black',
      fontFamily: 'Avenir Next Regular',
  },
  textLogin: {
      color:'white',
      fontFamily: 'Avenir Next Regular',
  },
  textLoginOther: {
      color:'black',
      padding:10,
      marginRight:5,
      fontFamily: 'Avenir Next Regular',
  },
  textInsc: {
      color:'rgba(111, 44, 247,1)',
      fontFamily: 'Avenir Next Regular',
      textDecorationLine:'underline'
  },
  logo: {
      width:'10vw',
      height: '5vh',
      resizeMode:'contain',
  },
  input: {
      outlineStyle: 'none',
      borderColor: '#757575',
      padding: 10,
      width: '100%',
      fontFamily: 'Avenir Next Regular',
      borderRadius: 40,
      borderWidth: 1 ,
  },
  buttonText: {
      width:'100%',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      padding:5
  },
  buttonTextInsc: {
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      padding:5
  },
  buttonLogin: {
      padding:10,
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      borderRadius:50,
      backgroundColor:'#6F2CF7'
  },
  buttonLoginOther: {
      margin:10,
      flexDirection:'row',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center',
      borderRadius:50,
      borderWidth:1,
      borderColor:'#c1bebe'
  },
  LogoLogin : {
      width:24,
      height:24,
      margin:10,
  },
  checkbox: {
      alignSelf: 'center',
      alignItems:'center',
      justifyContent:'center',
      marginRight:10,
      borderRadius:5,
      borderWidth:1,
      width: 20,
      height: 20,
  },
  checkboxChecked: {
      backgroundColor:'rgba(111, 44, 247,1)'
  },
  checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
  },
  checkboxLabel: {
      marginLeft: 8,
      fontWeight: 500,
      fontSize: 18,
  }
});
