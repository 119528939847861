import React, { useState,useEffect } from 'react';

import { View, StyleSheet, Text, Button } from 'react-native';
import NavBar from '../components/NavBar.js'
import HomeLieux from '../components/HomeLieux.js'
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence

import CustomImage from '../components/customImage.js';
import { onAuthStateChanged } from "firebase/auth";
import { TouchableOpacity } from 'react-native-web';
// Exemple d'écrans - Remplacez ceci par vos propres composants


export default function  FormuleCreditScreen({ navigation,user }) {
  const [selectedButton, setSelectedButton] = useState('Lieux');
  const [searchActivity, setSearchActivity] = useState('');
  const [address, setAddress] = useState('');
  const [selectedDay, setSelectedDay] = useState({}); // Pour stocker les jours sélectionnés
  

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("Utilisateur connecté:", user);
          // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
        } else {
          console.log("Utilisateur déconnecté");
          // Mettez à jour votre état en conséquence
        }
      });
    
      return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
    }, [auth]);
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <NavBar user={user} navigation={navigation} selectedButton={selectedButton} setSelectedButton={setSelectedButton} searchActivity={searchActivity} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        <View style={{ flex:1, justifyContent:'center',alignItems:'center'}}>
            <View style={{flexDirection:'row'}}>
                <Text style={styles.textH2}> Optimisez la gestion de votre </Text> <Text style={{...styles.textH2,color:'#6F2CF7'}}> organisation </Text><Text style={styles.textH2}> ! </Text>
            </View>
            <Text style={styles.textH5}> Sélectionnez le plan adapté à vos besoins et transformez votre gestion d'activités. </Text>
            <View style={{flexDirection:'row'}}>
            <View style={styles.card}> 
                <View style={{borderBottomWidth:1,width:'100%',height:'50%',justifyContent:'space-between',alignItems:'center', borderColor:'#E6E6E6',padding:20}}>
                    <View style={{width:'100%',padding:20}}>
                        <Text style={{...styles.textH2,color:'#B1B1B1'}}>Basic</Text>
                        <View style={{flexDirection:'row',alignItems:'flex-end'}}>
                            <Text style={styles.textH1}>80</Text><Text style={{...styles.textH1,fontSize:50,marginBottom:5}}> crédits</Text>

                        </View>
                    <Text style={{fontFamily:'Avenir Next Regular',color:'#A1A1A1'}}><b style={{color:'black'}}>100 € </b>par mois</Text>
                    </View>
                    <TouchableOpacity style={styles.buttonPlan}> 
                        <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}>
                            Choisir ce plan
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{width:'90%',padding:20}}>
                    <Text style={{color:'#A1A1A1',fontFamily:'Avenir Next Bold'}}>Fonctionnalités</Text>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:5}}> Réservation d'activités pour un enfant</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:5}}> Visualisation et gestion des activités planifiées</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:5}}> Acceptation et gestion des réservations</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:5}}> Communication avec les parents</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:5}}> Aperçu des statistiques de base sur les réservations
                            et les revenus</Text>
                    </View>
                </View>
            </View>
            <View style={styles.cardPop}> 
                <View style={{borderBottomWidth:1,width:'100%',height:'50%',justifyContent:'space-between',alignItems:'center', borderColor:'#E6E6E6',padding:20}}>
                    
                    <View style={{width:'100%',padding:20}}>
                        <View style={{width:'100%',justifyContent:'space-between',flexDirection:'row'}}>
                            <Text style={{...styles.textH2,color:'#6F2CF7'}}>Premium</Text>
                            <Text style={{fontFamily:'Avenir Next Regular',color:'white',padding:15,backgroundColor:'#6F2CF7',borderRadius:50}}>Populaire</Text>

                        </View>
                        <View style={{flexDirection:'row',alignItems:'flex-end'}}>
                            <Text style={styles.textH1}>160</Text><Text style={{...styles.textH1,fontSize:50,marginBottom:5}}> crédits</Text>

                        </View>
                    <Text style={{fontFamily:'Avenir Next Regular',color:'#A1A1A1'}}><b style={{color:'black'}}>200 € </b>par mois</Text>
                     </View>
                    <TouchableOpacity style={styles.buttonPlan}> 
                        <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}>
                            Choisir ce plan
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{width:'90%',padding:20}}>
                    <Text style={{color:'#A1A1A1',fontFamily:'Avenir Next Bold'}}>Fonctionnalités</Text>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Ajout et gestion d'un nombre illimité d'activités</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Gestion des disponibilités et des plages horaires</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Acceptation, annulation et gestion des réservations </Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Communication avec l'équipe et les parents</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Attribution de tâches, communication interne,
suivi des membres et gestion des rôles</Text>
                    </View>
                </View>
            </View>
            <View style={styles.card}> 
                <View style={{borderBottomWidth:1,width:'100%',height:'50%',justifyContent:'space-between',alignItems:'center', borderColor:'#E6E6E6',padding:20}}>
                    <View style={{width:'100%',padding:20}}>
                        <Text style={{...styles.textH2,color:'black'}}>Pro</Text>
                        <View style={{flexDirection:'row',alignItems:'flex-end'}}>
                            <Text style={styles.textH1}>240</Text><Text style={{...styles.textH1,fontSize:50,marginBottom:5}}> crédits</Text>

                        </View>
                    <Text style={{fontFamily:'Avenir Next Regular',color:'#A1A1A1'}}><b style={{color:'black'}}>300 € </b>par mois</Text>
                     </View>
                    <TouchableOpacity style={styles.buttonPlan}> 
                        <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}>
                            Choisir ce plan
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{width:'90%',padding:20}}>
                    <Text style={{color:'#A1A1A1',fontFamily:'Avenir Next Bold'}}>Fonctionnalités</Text>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Ajout et gestion d'un nombre illimité d'activités</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Gestion des disponibilités et des plages horaires</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Acceptation, annulation et gestion des réservations </Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Communication avec l'équipe et les parents</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Attribution de tâches, communication interne,
suivi des membres et gestion des rôles</Text>
                    </View>
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <CustomImage source={require('../assets/tickCircle.png')} style={styles.tickCircleIcon} />
                        <Text style={{fontSize:13,fontFamily:'Avenir Next Regular',color:'#A1A1A1',marginLeft:10}}> Possibilité d'offrir des réductions spéciales</Text>
                    </View>
                </View>
            </View>
            </View>
        </View>
      </View>
    );
  }

  const styles = StyleSheet.create({
    textH1: {
        fontFamily:'Avenir Next Bold',
        fontSize:70
    },
    textH2: {
        fontFamily:'Avenir Next Bold',
        fontSize:30,
        fontWeight:500

    },
    textH3: {

    },
    textH4: {

    },
    textH5: {
        fontFamily:'Avenir Next Regular',
        fontSize:20,
        fontWeight:500,
        color:'#B1B1B1'
    },
    card: {
        borderWidth:1,
        borderColor:'#E6E6E6',
        borderRadius:40,
        alignItems:'center',
        width:425,
        height: 650,
        margin:20

    },
    cardPop: {
        borderWidth:1,
        borderColor:'#6F2CF7',
        borderRadius:40,
        alignItems:'center',
        width:425,
        height: 650,
        margin:20,
        backgroundColor:'rgba(111, 44, 247, 0.05)'

    },
    buttonPlan: {
        padding:12,
        margin:5,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#6F2CF7',
        borderRadius:50,
        width:'80%'
    },
    tickCircleIcon: {
        width:24,
        height:24
    }

})
