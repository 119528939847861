import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1;
const MIN = 3;
const MAX = 18;

function AgePicker({age,onAgeChange}) {
  const defaultAge = age !== undefined && age !== null ? age : 12;
  const [value, setValue] = useState(defaultAge);
  const handleChange = (values) => {
    setValue(values[0]);
    onAgeChange(values[0]); // Appelle la fonction callback avec la nouvelle valeur
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '1em',
      }}
    >
      <Range
        values={[value]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: [value],
                  colors: ['#ccc', '#6F2CF7', '#ccc'],
                  min: MIN,
                  max: MAX
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '18px',
              width: '18px',
              borderRadius:20,
              backgroundColor: '#6F2CF7',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                borderRadius:20,
                height: '16px',
                width: '16px',
                backgroundColor: isDragged ? '#ccc' : '#6F2CF7'
              }}
            />
          </div>
        )}
      />
    </div>
  );
}

export default AgePicker;
