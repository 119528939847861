import React, { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, TextInput, Text, FlatList, TouchableOpacity } from 'react-native';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, useSearchBox, useInfiniteHits } from 'react-instantsearch-core';

const searchClient = algoliasearch('CW1522LSUY', 'bb590f42f21c4d84ac69040b08c87fe0');

function Hit({ hit, onSelect }) {
    return (
        <TouchableOpacity onPress={onSelect}>
            <Text>{hit.nameActB} : {hit.objectId}</Text>
        </TouchableOpacity>
    );
}

function InfiniteHits({ hitComponent: Hit, searchQuery, onHitSelect  }) {
    const { hits, isLastPage, showMore } = useInfiniteHits();

    if (searchQuery.length > 0) {
        return (
            <View style={styles.hits}>
                <FlatList
                    data={hits}
                    keyExtractor={(item) => item.objectID}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    onEndReached={() => {
                        if (!isLastPage) showMore();
                    }}
                    renderItem={({ item }) => (
                        <View style={styles.item}>
                            <Hit hit={item} onSelect={() => onHitSelect(item.nameActB)} />
                        </View>
                    )}
                />
            </View>
        );
    } else {
        return null;
    }
}

 function AlgoliaInput() {
    const { query, refine } = useSearchBox();
    const [inputValue, setInputValue] = useState(query);
    const [showHits, setShowHits] = useState(true); // Nouvel état pour contrôler l'affichage des hits
    const inputRef = useRef(null);
    const handleHitSelect = (hitName) => {
        setInputValue(hitName);
        setShowHits(false); // Réafficher les hits quand le texte change
        // Vous pouvez également appeler `refine` ici si nécessaire
    };
    useEffect(() => {
        if (query !== inputValue && !inputRef.current?.isFocused()) {
            setInputValue(query);
        }
    }, [query]);

    const setQuery = (newQuery) => {
        setInputValue(newQuery);
        setShowHits(true); // Réafficher les hits quand le texte change
        refine(newQuery);
    };

    return (
        <View style={styles.container}>
            <Text style={{paddingLeft:25,paddingTop:15,fontSize:12,fontFamily:'Avenir Next Bold'}}>Activités</Text>
            <TextInput
                ref={inputRef}
                style={styles.input}
                value={inputValue}
                onChangeText={setQuery}
                placeholder='Rechercher une activité'
                clearButtonMode="while-editing"
                autoCapitalize="none"
                autoCorrect={false}
                spellCheck={false}
                autoComplete="off"
            />
            {showHits ? <InfiniteHits hitComponent={Hit} searchQuery={inputValue} onHitSelect={handleHitSelect} /> :<View/> }
        </View>
    );
}
export default function AlgoliaSearchWrapper() {
    return (
        <View style={styles.container}>
            <InstantSearch searchClient={searchClient} indexName="TypeOfAct">
                <AlgoliaInput />
            </InstantSearch>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        height:'100%'
    },
    input: {
        height: 'auto',
        paddingLeft: 25,
        paddingTop: 3,
        paddingRight: 12,
        paddingBottom: 10,
        fontSize: 15,
        color:'#808080',
        fontFamily:'Avenir Next Regular'
    },
    hits: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        top: 60
    },
    separator: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
    },
    item: {
        padding: 18,
    }
});
