import React, { useState, useMemo, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator,   Image, FlatList,Animated, Dimensions } from 'react-native';

import { Star } from 'iconsax-react-native';

import { MaterialIcons } from '@expo/vector-icons';
import CustomImage from './customImage';
import _ from 'lodash';

export default function Activities({ navigation, onActivitySelect, searchActivity, address, selectedDay, category }) {
    const [isLoading, setIsLoading] = useState(true);
    const [activitiesIdArray, setActivitiesIdArray] = useState(['ID1', 'ID2', 'ID3','ID4', 'ID5', 'ID6','ID11', 'ID12', 'ID13','ID14', 'ID15', 'ID16','ID17','ID18', 'ID19','ID20','ID22', 'ID23', 'ID24','ID25','ID26','ID27',]);
    const [activitiesInfoArray, setActivitiesInfoArray] = useState({    
        'ID1': {
            name: 'Dalhae Academie', phoneNumber: '0682888745', email: 'dalhae.academie@gmail.com', desc: 'Notre mission est de fournir une formation de qualité supérieure en Taekwondo, tout en encourageant la croissance personnelle et l excellence. Nous visons à renforcer le corps et l esprit, tout en inspirant nos membres à atteindre leurs objectifs personnels et à donner le meilleur d eux-mêmes. ',
            typeAct: 'Association privée', category: ['Club sportif','Centre de loisirs'], price: [1, 3], imgUrl: require('../assets/dalhaeLogo.jpg'),note:4,imgClub:'',
            nbAvis:7,age: [3, 18],imgCoverUrl: require('../assets/musicImg.jpg'),note:4,
            address:{
                description:'15 Chemin des Aubagnens, Allauch, France', 
                lat: 43.2934045, 
                lng: 5.567245199999999
            },
            team: 
                [
                    {id:"jBxKTj59TYW98sXhxtMZr3mlQ6k2", name:"Marie-Catherine",lastname:"Leseine",role:[0,2],email:"mcleseine@gmail.com",phone:"0682888745"},
                    {id:"9ERAyuiAHwh1jqDV3Qu3dqhhfoJ3", name:"Clément",lastname:"Martinez",role:[1,2],email:"clem13dutae@gmail.com",phone:"0612345678"},
                    {id:'ZiceOlMwNIXkwRRsefRzBq4oiso1', name:"Luc-Arnaud",lastname:"Bonnet",role:[0],email:"luc.bonnet.13@gmail.com",phone:"0650007947"},
                ],
            teamPrivilege: {
                0:'Admin',
                1:'Sous-Admin',
                2:'Professeur',
                3:'Secrétariat',
            },
            places:{
                1:{
                    geoloc:'',
                    imgLieux: '',
                    address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                    city:'Herndon, Virginie, États-Unis',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 19,
                        imgAct: '',
                        frequency:'Stage',
                        desc:'Petite desc des familles',
                        day:"Sam",
                        category:'Activité récurrente',
                        time: "08:00 - 10:00",
                        room: "Gymnase Gilberti",
                        price: 1,
                        capacity: 10,
                        group: "TITRE",
                        teacher: {"id":1254321,"name":"Marie-Catherine Leseine"},
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 25,
                        imgAct: '',
                        frequency:'Mensuel',
                        nthDay: '4',
                        desc:'Petite desc des familles',
                        day:"Mer",
                        category:'Activité récurrente',
                        time: "10:15 - 15:45",
                        room: "Gymnase Gilllberti",
                        price: 2,
                        capacity: 20,
                        group: "Groupe 1",
                        teacher: {
                            id:12556721,
                            name:"Clément Martinez"
                        },
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 41,
                        imgAct: '',
                        frequency:'Hebdomadaire',
                        desc:'Petite desc des familles',
                        day:"Dim",
                        category:'Activité récurrente',
                        time: "17:00 - 18:00",
                        room: "Gymnase Gillberti",
                        price: 3,
                        nthDay: '0',
                        capacity: 30,
                        group: "Groupe 1",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[3,8],
                        recurrent:false
                      },
                    ]
                },
                0:{
                    geoloc:'',
                    imgLieux: '',
                    address:'15 Chemin des Aubagnens, Allauch, France',
                    city:'Allauch, France',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 12,
                        imgAct: '',
                        day:"Lun",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "09:00 - 12:00",
                        room: "Gymnase du Charrel",
                        price: 3,
                        frequency:'Mensuel',
                        nthDay: '4',
                        capacity: 15,
                        group: "Groupe 1",
                        teacher: {id:1254321,name:"Marie-Catherine Leseine"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 28,
                        imgAct: '',
                        day:"Ven",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "11:15 - 13:45",
                        room: "Gymnase du Charrel",
                        price: 2,
                        frequency:'Hebdomadaire',
                        nthDay: '0',
                        capacity: 25,
                        group: "Groupe 2&3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 49,
                        imgAct: '',
                        day:"Mer",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "11:00 - 18:00",
                        room: "Gymnase du Charrel",
                        price: 1,
                        frequency:'Hebdomadaire',
                        nthDay: '0',
                        capacity: 35,
                        group: "Groupe 3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[12,null],
                        recurrent:false
                      },
                    ]
                },
                2:{
                    geoloc:'',
                    imgLieux: '',
                    address:'13 cour foch, Aubagne 13400',
                    city:'Aubagne 13400',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 72,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Sam",
                        category:'Activité récurrente',
                        time: "11:00 - 12:00",
                        room: "Gymnase Foch",
                        price: 3,
                        frequency:'Mensuel',
                        nthDay: '2',
                        capacity: 17,
                        group: "Groupe 1",
                        teacher: {id:1254321,name:"Marie-Catherine Leseine"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 68,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Dim",
                        category:'Activité récurrente',
                        time: "11:15 - 13:45",
                        room: "Gymnase Bras d'Or",
                        price: 2,
                        frequency:'Mensuel',
                        nthDay: '1',
                        capacity: 26,
                        group: "Groupe 2&3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 59,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Jeu",
                        category:'Activité récurrente',
                        time: "11:00 - 18:00",
                        room: "Gymnase Charrel",
                        price: 1,
                        frequency:'Mensuel',
                        nthDay: '3',
                        capacity: 32,
                        group: "Groupe 3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[12,null],
                        recurrent:false
                      },
                    ]
                },
            }
        },
        'ID3': {
            name: 'Dalhae loisir', category: ['Centre de loisirs'], price: [1, 3], imgUrl: require('../assets/inscImg3.png'),imgCoverUrl: require('../assets/musicImg.jpg'),note:4,
            nbAvis:7,
            places:{
                1:{
                    address:'13 place imaginaire, Gemenos 13420',
                    city:'Gemenos 13420',
                    calendar: [
                        {
                        id: 19,
                        day:"SAT",
                        time: "08:00 - 10:00",
                        room: "Gymnase Gilberti",
                        price: 1,
                        group: "Groupe 2&3",
                        teacher: "Marie-Catherine Leseine",
                        address:'13 place imaginaire, Gemenos 13420',
                        age:[8,null]
                      },
                      {
                        id: 25,
                        day:"WED",
                        time: "10:15 - 15:45",
                        room: "Gymnase Gilberti",
                        price: 2,
                        group: "Groupe 1",
                        teacher: "Clément Martinez",
                        address:'13 place imaginaire, Gemenos 13420',
                        age:[3,8]
                      },
                      {
                        id: 41,
                        day:"SUN",
                        time: "17:00 - 18:00",
                        room: "Gymnase Gilberti",
                        price: 3,
                        group: "Groupe 1",
                        teacher: "Clément Martinez",
                        address:'13 place imaginaire, Gemenos 13420',
                        age:[3,8]
                      },
                    ]
                },
                0:{
                    address:'15 place inventée, Aubagne 13400',
                    city:'Aubagne 13400',
                    calendar: [
                        {
                        id: 12,
                        day:"MON",
                        time: "09:00 - 12:00",
                        room: "Gymnase du Charrel",
                        price: 3,
                        group: "Groupe 1",
                        teacher: "Marie-Catherine Leseine",
                        address:'15 place inventée, Aubagne 13400',
                        age:[3,8]
                      },
                      {
                        id: 28,
                        day:"FRI",
                        time: "11:15 - 13:45",
                        room: "Gymnase du Charrel",
                        price: 2,
                        group: "Groupe 2&3",
                        teacher: "Clément Martinez",
                        address:'15 place inventée, Aubagne 13400',
                        age:[8,null]
                      },
                      {
                        id: 49,
                        day:"WED",
                        time: "11:00 - 18:00",
                        room: "Gymnase du Charrel",
                        price: 1,
                        group: "Groupe 3",
                        teacher: "Clément Martinez",
                        address:'15 place inventée, Aubagne 13400',
                        age:[12,null]
                      },
                    ]
                },
                2:{
                    address:'13 cour foch, Aubagne 13400',
                    city:'Aubagne 13400',
                    calendar: [
                        {
                        id: 72,
                        day:"SAT",
                        time: "11:00 - 12:00",
                        room: "Gymnase Foch",
                        price: 3,
                        group: "Groupe 1",
                        teacher: "Marie-Catherine Leseine",
                        address:'13 cour foch, Aubagne 13400',
                        age:[3,8]
                      },
                      {
                        id: 68,
                        day:"SUN",
                        time: "11:15 - 13:45",
                        room: "Gymnase Foch",
                        price: 2,
                        group: "Groupe 2&3",
                        teacher: "Clément Martinez",
                        address:'13 cour foch, Aubagne 13400',
                        age:[8,null]
                      },
                      {
                        id: 59,
                        day:"THU",
                        time: "11:00 - 18:00",
                        room: "Gymnase Foch",
                        price: 1,
                        group: "Groupe 3",
                        teacher: "Clément Martinez",
                        address:'13 cour foch, Aubagne 13400',
                        age:[12,null]
                      },
                    ]
                },
            }
        },
        'ID2': {
            name: 'Olympique de Marseille', phoneNumber: '0612345678', email: 'O.M@gmail.com', desc: 'Notre mission est de fournir une formation de qualité supérieure pour devenir proféssionel en Footbal, tout en encourageant la croissance personnelle et l excellence. Nous visons à renforcer le corps et l esprit, tout en inspirant nos membres à atteindre leurs objectifs personnels et à donner le meilleur d eux-mêmes. ',
            typeAct: 'Association privée', category: ['Club sportif'], price: [1, 3], imgUrl: require('../assets/OMlogo.png'),imgCoverUrl: require('../assets/imageActTest.png'),note:4,
            nbAvis:7,
            places:{
                1:{
                    address:'33 Trav. de la Martine, 13012 Marseille',
                    city:'Gemenos 13420',
                    calendar: [
                      {
                        id: 25,
                        day:"TUE",
                        time: "10:15 - 15:45",
                        room: "Centre Robert Louis Dreyfus",
                        price: 2,
                        group: "Enfant",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[3,8]
                      },
                      {
                        id: 41,
                        day:"WED",
                        time: "13:00 - 14:00",
                        room: "Centre Robert Louis Dreyfus",
                        price: 3,
                        group: "Enfant",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[3,8]
                      },
                      {
                        id: 42,
                        day:"WED",
                        time: "14:00 - 15:00",
                        room: "Centre Robert Louis Dreyfus",
                        price: 3,
                        group: "U10",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[10]
                      },
                      {
                        id: 43,
                        day:"WED",
                        time: "15:00 - 16:00",
                        room: "Centre Robert Louis Dreyfus",
                        price: 3,
                        group: "U12",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[12]
                      },
                      {
                        id: 44,
                        day:"WED",
                        time: "16:00 - 17:00",
                        room: "Centre Robert Louis Dreyfus",
                        price: 3,
                        group: "U14",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[14]
                      },
                      {
                        id: 45,
                        day:"WED",
                        time: "17:00 - 18:00",
                        room: "Centre Robert Louis Dreyfus",
                        price: 3,
                        group: "semi-pro",
                        teacher: "Clément Martinez",
                        address:'33 Trav. de la Martine, 13012 Marseille',
                        age:[16]
                      },
                    ]
                },
            }
        },
        
        'ID4': {
            name: 'Club de Judo Marseille',
            phoneNumber: '0655112233',
            email: 'judo.marseille@gmail.com',
            desc: 'Le Judo est plus qu\'un sport, c\'est un art martial. Rejoignez-nous pour apprendre les techniques, l\'endurance et la discipline.',
            typeAct: 'Association privée',
            category: ['Club sportif'],
            price: [1, 2],
            imgUrl: require('../assets/judo.jpeg') ,
            note: 4.5, 
            nbAvis: 15,
            places: {
                1: {
                    address: '22 rue du dojo, Marseille 13005',
                    city: 'Marseille 13005',
                    calendar: [
                        {
                            id: 46,
                            day: "MON",
                            time: "10:00 - 12:00",
                            room: "Salle principale",
                            price: 1,
                            group: "Débutant",
                            teacher: "Sensei François",
                            address: '22 rue du dojo, Marseille 13005',
                            age: [5, null]
                        },
                        {
                            id: 47,
                            day: "WED",
                            time: "15:00 - 16:30",
                            room: "Salle principale",
                            price: 2,
                            group: "Avancé",
                            teacher: "Sensei François",
                            address: '22 rue du dojo, Marseille 13005',
                            age: [10, null]
                        }
                    ]
                }
            }
        },
        'ID5': {
            name: 'Club d\'échecs Marseille',
            phoneNumber: '0677889900',
            email: 'echecs.marseille@gmail.com',
            desc: 'Venez développer votre esprit stratégique avec le jeu d\'échecs. Tous niveaux acceptés.',
            typeAct: 'Association publique',
            category: ['Echecs'],
            price: [1,1],
            imgUrl: require('../assets/echecs.jpg') ,
            note: 4.7,
            nbAvis: 25,
            places: {
                0: {
                    address: '10 place du roi, Marseille 13003',
                    city: 'Marseille 13003',
                    calendar: [
                        {
                            id: 48,
                            day: "FRI",
                            time: "18:00 - 20:00",
                            room: "Salle du roi",
                            price: 1,
                            group: "Tous niveaux",
                            teacher: "Maître Dupont",
                            address: '10 place du roi, Marseille 13003',
                            age: [7, null]
                        }
                    ]
                }
            }
        },
        'ID6': {
            name: 'Centre Yoga Marseille',
            phoneNumber: '0644223355',
            email: 'yoga.marseille@gmail.com',
            desc: 'Relaxation, concentration et bien-être. Venez découvrir les bienfaits du yoga.',
            typeAct: 'Association privée',
            category: ['Yoga et bien-être'],
            price: [2, 3],
            imgUrl: require('../assets/yoga.jpg') ,
            note: 4.9,
            nbAvis: 30,
            places: {
                2: {
                    address: '55 boulevard du bien-être, Marseille 13006',
                    city: 'Marseille 13006',
                    calendar: [
                        {
                            id: 49,
                            day: "TUE",
                            time: "10:00 - 11:30",
                            room: "Salle de relaxation",
                            price: 2,
                            group: "Débutant",
                            teacher: "Guru Marie",
                            address: '55 boulevard du bien-être, Marseille 13006',
                            age: [18, null]
                        },
                        {
                            id: 50,
                            day: "THU",
                            time: "16:00 - 17:30",
                            room: "Salle de relaxation",
                            price: 3,
                            group: "Avancé",
                            teacher: "Guru Marie",
                            address: '55 boulevard du bien-être, Marseille 13006',
                            age: [18, null]
                        }
                    ]
                }
            }
        },
            "ID11": {
                "name": "Evasion Loisirs",
                "phoneNumber": "0644001122",
                "email": "evasion.loisirs@gmail.com",
                "desc": "Découvrez une multitude d'activités pour petits et grands.",
                "typeAct": "Association privée",
                "category": ["Centre de loisirs"],
                "price": [2, 5],
                "imgUrl": require('../assets/loisirImg.jpg'),
                "note": 4.5,
                "nbAvis": 50,
                "places": {}
            },
            "ID12": {
                "name": "Créativ'Art",
                "phoneNumber": "0644001123",
                "email": "creativ.art@gmail.com",
                "desc": "Créativ Art - Révélez le Talent Artistique de Votre Enfant !\nPlongez votre enfant dans un univers d exploration artistique avec l association publique Créativ Art. Nos ateliers créatifs, reconnus par la satisfaction de nombreux parents (4.8 étoiles sur 45 avis), sont conçus pour éveiller la passion et le talent de chaque jeune artiste, qu il soit débutant ou avancé.\n🎨 Nos Ateliers :\nPeinture : Laissez leur imagination déborder sur la toile.\nSculpture : Façonnez leurs compétences à travers l argile et d autres matériaux.\nDessin : Dessinez le monde à travers leurs yeux.\n💡 Pourquoi choisir Créativ Art ?\nUne pédagogie bienveillante et adaptée à tous les niveaux.\nUn environnement stimulant pour encourager la créativité.\nUne équipe passionnée et dédiée à l épanouissement artistique de votre enfant.\nInscrivez votre enfant dès maintenant et observez sa créativité s épanouir !\n📞 Pour plus d informations et inscriptions : 0644001123.\nNe manquez pas cette opportunité unique de cultiver le potentiel artistique de votre enfant avec Créativ Art.",
                "typeAct": "Association publique",
                "category": ["Ateliers créatifs"],
                "price": [3, 4],
                "imgUrl": require('../assets/sculptImg.webp'),
                "note": 4.8,
                "nbAvis": 45,
                "places": {}
            },
            "ID13": {
                "name": "Sport Club Elite",
                "phoneNumber": "0644001124",
                "email": "sport.club@gmail.com",
                "desc": "Rejoignez le club et améliorez vos compétences sportives.",
                "typeAct": "Association privée",
                "category": ["Club sportif"],
                "price": [4, 7],
                "imgUrl": require('../assets/sportImg.jpg'),
                "note": 4.7,
                "nbAvis": 60,
                "places": {}
            },
            "ID14": {
                "name": "Mélodie Musique",
                "phoneNumber": "0644001125",
                "email": "melodie.musique@gmail.com",
                "desc": "Cours de musique pour tous instruments et niveaux.",
                "typeAct": "Entreprise privée",
                "category": ["Cours de musique"],
                "price": [5, 8],
                "imgUrl": require('../assets/musicImg.jpg'),
                "note": 4.8,
                "nbAvis": 35,
                "places": {}
            },
            "ID15": {
                "name": "Sciences Explorers",
                "phoneNumber": "0644001126",
                "email": "science.explores@gmail.com",
                "desc": "Partez à la découverte des merveilles de la science.",
                "typeAct": "Association publique",
                "category": ["Sciences découverte"],
                "price": [2, 6],
                "imgUrl": require('../assets/scienceImg.jpg'),
                "note": 4.6,
                "nbAvis": 40,
                "places": {}
            },
            "ID16": {
                "name": "Tech Innov",
                "phoneNumber": "0644001127",
                "email": "techinnov@gmail.com",
                "desc": "Initiation aux dernières technologies.",
                "typeAct": "Association privée",
                "category": ["Technologies"],
                "price": [6, 9],
                "imgUrl": require('../assets/techImg.jpeg'),
                "note": 4.9,
                "nbAvis": 55,
                "places": {}
            },
            "ID17": {
                "name": "Langues Mondiales",
                "phoneNumber": "0644001128",
                "email": "languesmondiales@gmail.com",
                "desc": "Apprenez une nouvelle langue et découvrez une nouvelle culture.",
                "typeAct": "Entreprise privée",
                "category": ["Langues étrangères"],
                "price": [3, 7],
                "imgUrl": require('../assets/langueImg.jpg'),
                "note": 4.6,
                "nbAvis": 30,
                "places": {}
            },
            "ID18": {
                "name": "Spectacle Vivant",
                "phoneNumber": "0644001129",
                "email": "spectacle.vivant@gmail.com",
                "desc": "Découvrez l'art de la scène et du spectacle.",
                "typeAct": "entreprise publique",
                "category": ["Arts du spectacle"],
                "price": [5, 10],
                "imgUrl": require('../assets/theatreImg.jpg'),
                "note": 4.8,
                "nbAvis": 25,
                "places": {}
            },
            "ID19": {
                "name": "Intérêt Club",
                "phoneNumber": "0644001130",
                "email": "interet.club@gmail.com",
                "desc": "Partagez vos passions et découvrez-en de nouvelles.",
                "typeAct": "Association privée",
                "category": ["Clubs d'intérêts"],
                "price": [1, 3],
                "imgUrl": require('../assets/interetImg.png'),
                "note": 4.5,
                "nbAvis": 50,
                "places": {}
            },
            "ID20": {
                "name": "Eco World",
                "phoneNumber": "0644001131",
                "email": "eco.world@gmail.com",
                "desc": "Préservez notre planète en vous initiant à l'écologie.",
                "typeAct": "Association publique",
                "category": ["Environnement"],
                "price": [4, 7],
                "imgUrl": require('../assets/ecoImg.jpg'),
                "note": 4.9,
                "nbAvis": 65,
                "places": {}
            },
            "ID22": {
                "name": "Combat Arts",
                "phoneNumber": "0644001133",
                "email": "combat.arts@gmail.com",
                "desc": "Maîtrisez les arts martiaux et découvrez la discipline.",
                "typeAct": "Association privée",
                "category": ["Arts martiaux"],
                "price": [6, 9],
                "imgUrl": require('../assets/martialImg.jpg'),
                "note": 4.8,
                "nbAvis": 58,
                "places": {}
            },
            "ID23": {
                "name": "Culture Histoire",
                "phoneNumber": "0644001134",
                "email": "culture.histoire@gmail.com",
                "desc": "Découvrez l'histoire et la culture du monde.",
                "typeAct": "Entreprise publique",
                "category": ["Histoire et culture"],
                "price": [2, 5],
                "imgUrl": require('../assets/chImg.jpg'),
                "note": 4.6,
                "nbAvis": 60,
                "places": {}
            },
            "ID24": {
                "name": "Devoirs Aide",
                "phoneNumber": "0644001135",
                "email": "devoirs.aide@gmail.com",
                "desc": "Obtenez de l'aide pour vos devoirs et améliorez vos notes.",
                "typeAct": "Association publique",
                "category": ["Aide aux devoirs"],
                "price": [3, 6],
                "imgUrl": require('../assets/hhwImg.jpg'),
                "note": 4.7,
                "nbAvis": 55,
                "places": {}
            },
            "ID25": {
                "name": "Bien-être Yoga",
                "phoneNumber": "0644001136",
                "email": "bienetre.yoga@gmail.com",
                "desc": "Relaxez-vous et découvrez le yoga pour un bien-être total.",
                "typeAct": "Associoation privée",
                "category": ["Yoga et bien-être"],
                "price": [4, 8],
                "imgUrl": require('../assets/yogaImg.jpg'),
                "note": 4.9,
                "nbAvis": 70,
                "places": {}
            },
            "ID26": {
                "name": "Etoiles Astronomie",
                "phoneNumber": "0644001137",
                "email": "etoiles.astronomie@gmail.com",
                "desc": "Découvrez l'univers et ses merveilles.",
                "typeAct": "Association privée",
                "category": ["Astronomie"],
                "price": [5, 10],
                "imgUrl": require('../assets/astroImg.jpg'),
                "note": 4.8,
                "nbAvis": 40,
                "places": {}
            },
            "ID27": {
                "name": "Echecs Maîtres",
                "phoneNumber": "0644001138",
                "email": "echecs.maitres@gmail.com",
                "desc": "Apprenez le jeu d'échecs et affrontez des adversaires.",
                "typeAct": "Association privée",
                "category": ["Echecs"],
                "price": [1, 4],
                "imgUrl": require('../assets/chessImg.jpg'),
                "note": 4.7,
                "nbAvis": 50,
                "places": {}
            }
        
    })
    
    const [animatedIndex, setAnimatedIndex] = useState(0); // Suivre l'index de l'élément animé
    //const animationDuration = filteredActivities ? 300 * filteredActivities.length : 0;
    const animationDuration = filteredActivities ? 300 * filteredActivities.length : 0;

    const screenWidthRef = useRef(Dimensions.get('window').width);
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const [layoutChange, setLayoutChange] = useState(0);
    const ITEM_WIDTH = 310; // Largeur de l'élément + marginRight
    const imageCache = {};
    function loadImageWithCache(uri) {
        return new Promise((resolve, reject) => {
          if (imageCache[uri]) {
            resolve(imageCache[uri]);
          } else {
            Image.getSize(uri, (width, height) => {
              imageCache[uri] = { uri, width, height };
              resolve(imageCache[uri]);
            }, reject);
          }
        });
      }
      
    const MyImage = ({ source, style }) => {
        return (
          <Image
            source={source}
            style={style}
            key={source.uri}
          />
        );
      };
      
    const handleAnimationEnd = () => {
        if (animatedIndex < filteredActivities.length - 1) {
            setAnimatedIndex(animatedIndex + 1); // Incrémenter l'index
        }
    };
    const [itemsPerRow, setItemsPerRow] = useState(calculateItemsPerRow(screenWidthRef));
    const [actualItemWidth, setActualItemWidth] = useState(calculateItemsPerRow(screenWidth).actualItemWidth);
    
    function calculateItemsPerRow(width) {
        let itemsPerRow = 4;
        let padding;
    
        if (width >= 1640) { // Pour les écrans de 1640px et plus
            itemsPerRow = 5;
            padding = 80; // Padding de 80px pour les grands écrans
        } else if (width >= 1480) { // Pour les écrans entre 1480px et 1639px
            itemsPerRow = 4;
            padding = 80; // Padding de 80px pour les écrans moyennement grands
        } else if (width >= 1128) { // Pour les écrans entre 1128px et 1479px
            itemsPerRow = 4;
            padding = 80; // Padding de 40px pour les écrans moyens
        }else if (width >= 800) { // Pour les écrans entre 1128px et 1479px
            itemsPerRow = 3;
            padding = 40; // Padding de 40px pour les écrans moyens
        } else if (width >= 630) { // Pour les écrans entre 1128px et 1479px
            itemsPerRow = 2;
            padding = 40; // Padding de 40px pour les écrans moyens
        } else { // Pour les écrans plus petits que 1128px
            itemsPerRow = 1;
            padding = 40; // Padding de 40px pour les petits écrans
        }
    
        const gapBetweenItems = 20;
        const totalGapWidth = gapBetweenItems * (itemsPerRow - 1);
        const actualItemWidth = (width - padding * 2 - totalGapWidth) / itemsPerRow;
        
        console.log("calculateItemsPerRow: "+  String(width) + ' '+ String(itemsPerRow)+ ' '+ String(actualItemWidth) );
        return { itemsPerRow, actualItemWidth };
    }
    
    

    const filteredActivities = useMemo(() => {
        
        return activitiesIdArray.filter(activity => {
            const activityInfo = activitiesInfoArray[activity];
            return !onActivitySelect || onActivitySelect === activityInfo?.category[0] || onActivitySelect === activityInfo?.category[1] || onActivitySelect === '';
        });
    }, [activitiesIdArray, activitiesInfoArray, onActivitySelect]);



    const renderItem = ({ item, index }) => {
        return <AnimatedListItem item={item} index={index} key={item.id} />; // Assurez-vous que `item.id` est unique
      };



    const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);
    
    const AnimatedListItem = React.memo(({ item: activity, index }) => {
        const scaleValue = useRef(new Animated.Value(1)).current;
    const activityInfo = activitiesInfoArray[activity];
    let imageUrl = activityInfo?.imgUrl ? activityInfo.imgUrl : require('../assets/imageActTest.png');
    const hasAnimated = useRef(true); // Marqueur pour vérifier si l'élément a déjà été animé

    useEffect(() => {
        const subscription = Dimensions.addEventListener('change', ({ window }) => {
          screenWidthRef.current = window.width;
          // Vous pouvez également recalculer itemsPerRow ici si nécessaire
        });
      
        return () => subscription?.remove();
      }, []);

      useEffect(() => {
        const updateLayout = ({ window: { width } }) => {
            screenWidthRef.current = width;
            if (width !== screenWidth) {
                const { itemsPerRow: newItemsPerRow, actualItemWidth: newActualItemWidth } = calculateItemsPerRow(width);
                setScreenWidth(width);
                setItemsPerRow(newItemsPerRow);
                setActualItemWidth(newActualItemWidth);
            }
        };
    
        const subscription = Dimensions.addEventListener('change', updateLayout);
        return () => subscription.remove();
    }, [screenWidth]);
    
     

      useEffect(() => {
        // Effect pour l'animation
        if (!hasAnimated.current) {
          Animated.timing(scaleValue, {
            toValue: 1,
            duration: 300,
            delay: index * 100, // Délai pour créer un effet en cascade
            useNativeDriver: true,
          }).start(() => {
            hasAnimated.current = true;
          });
        }
      }, [index, scaleValue]);
      
        if (isLoading) {
            return(
                <View style={styles.centered}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }

        return (
            <AnimatedTouchableOpacity onPress={() => navigation.navigate('ActivityProfil', { idProfil: activity, profilInfo: activityInfo })} style={{...styles.containerCard,width: actualItemWidth, transform:[{ scale: scaleValue }]}}>
            <MyImage source={{ uri: imageUrl }} style={{...styles.imageAct,height:actualItemWidth}} />
            <View style={{  width: '100%' }}> 
                    <View style={{ marginTop: 5, width: '100%' ,height:'auto'}}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <Text style={styles.nameAct}>{activityInfo?.name || 'Nom par défaut'}</Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                <Star color={'#6F2CF7'} variant='Bold' size={15} style={{marginRight: 5}}/>
                                <Text style={styles.nameAct}>
                                    {activityInfo?.note || 'null'}
                                </Text>
                            </View>
                        </View>
                        <View style={{ height:'auto'}}>
                            <Text style={styles.descAct}>{activityInfo?.typeAct || 'Description par défaut'}</Text>
                            <Text style={styles.descAct}>{activityInfo?.category[0] || 'Type par défaut'} / {Object.keys(activityInfo?.places || {}).length} lieux</Text>
                            <Text style={styles.descAct}>
                            { Object.values(activityInfo?.places || {})
                                    .map(place => place.calendar.length)
                                    .reduce((total, currentSlots) => total + currentSlots, 0)
                            } créneaux
                            </Text>
                        </View>
                        <View style={{ marginTop: 10,flexDirection:'row',alignItems:'center' ,height:'auto'}}>
                            <Text style={styles.nameAct}>De</Text>
                            <Text style={styles.nameAct}> {activityInfo?.price[0] || 0} à {activityInfo?.price[1] || 0} crédits </Text>
                            <Text style={styles.nameAct}>par activités</Text>
                        </View>
                    </View>
                </View>
        </AnimatedTouchableOpacity>
        );
    });
    
          /*      
    const [listKey, setListKey] = useState(Math.random().toString());
      
        
         
        
    useEffect(() => {
        setListKey(Math.random().toString());
    }, [onActivitySelect]);
    useEffect(() => {
        // Simuler un appel API
        setTimeout(() => {
            // Supposons que vos données sont maintenant chargées
            setIsLoading(false);
        }, 2000); // 2 secondes pour l'exemple
    }, []);
    useEffect(() => {
        const updateLayout = () => {
            const newWidth = Dimensions.get('window').width;
            setScreenWidth(newWidth);
            setItemsPerRow(calculateItemsPerRow(newWidth));
            setLayoutChange(prev => prev + 1); // Incrémenter pour indiquer un changement de mise en page
        };

        Dimensions.addEventListener('change', updateLayout);
        return () => Dimensions.removeEventListener('change', updateLayout);
    }, []);
    */
   
    const [listKey, setListKey] = useState('flatlist-1'); // Initialiser avec une key
    /*
    useEffect(() => {
        const updateLayout = () => {
            const newWidth = Dimensions.get('window').width;
            const { itemsPerRow: newItemsPerRow } = calculateItemsPerRow(newWidth);
            if (newItemsPerRow !== itemsPerRow) { // Changement uniquement si le nombre de colonnes change
                setScreenWidth(newWidth);
                setItemsPerRow(newItemsPerRow);
                setListKey(`flatlist-${newItemsPerRow}`); // Mettre à jour la key pour forcer le re-rendu
            }
        };
    
        Dimensions.addEventListener('change', updateLayout);
        return () => Dimensions.removeEventListener('change', updateLayout);
    }, [itemsPerRow]); // Ajouter itemsPerRow comme dépendance
    */
    
    useEffect(() => {
        // Simuler un appel API
        setTimeout(() => {
            // Supposons que vos données sont maintenant chargées
            setIsLoading(false);
        }, 20); // 2 secondes pour l'exemple
    }, []);
    if (isLoading) {
    // Afficher l'indicateur de chargement
        return (
            <View style={styles.centered}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        );
    }
    return (
        <View style={{ marginTop: 10 ,justifyContent:'center',alignContent:'center',alignItems:'center',zIndex:1,minWidth:screenWidth-160}}>
             <FlatList
  data={filteredActivities}
  scrollEnabled={false}
  renderItem={renderItem}
  keyExtractor={(activity) => activity.toString()}
  numColumns={itemsPerRow}
  style={{minWidth:screenWidth-160}}
  key={`flatlist-${itemsPerRow}`}
  contentContainerStyle={{ paddingHorizontal: 0 }}
/>
        </View>
    );
}

const styles = StyleSheet.create({
    
    centered: {
        flex: 1,

        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom:10
    },
    containerCard: {
        height: '100%',
        alignItems: 'flex-start',
        gap: 12,
        flexShrink: 0,
        margin: 10,
        marginVertical:20,

    },
    imageAct: {
        width: '100%',
        height: '60%',
        borderRadius: 15,
        
    },
    nameAct: {
        color: '#000',
        fontFamily: 'Avenir Next Regular',
        fontSize: 15,
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
    },
    iconStar: {
        width: 15,
        height: 15,
        marginRight: 5
    },
    descAct: {
        
        color: '#808080',
        fontFamily: 'Avenir Next Regular',
        fontSize: 15,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 22,
        height:'auto'
    },
    priceAct: {
        fontSize: 15,
        fontWeight: 'bold',
    }
});
