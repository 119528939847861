import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TextInput,CheckBox, TouchableOpacity, Dimensions, Animated, StyleSheet, Button } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const TeamPanel = ({ isVisible, onClose, memberData, privilege }) => {
  const screenWidth = Dimensions.get('window').width;
  const slideAnim = useRef(new Animated.Value(screenWidth)).current;

  const [email, setEmail] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (isVisible) {
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();

      // Initialize fields when panel becomes visible
      if (memberData) {
        setEmail(memberData.email);
        setSelectedRoles(memberData.role);
      } else {
        setEmail('');
        setSelectedRoles([]);
      }
    } else {
      Animated.timing(slideAnim, {
        toValue: screenWidth,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }, [isVisible, memberData]);

  useEffect(() => {
    console.log('selectedRoles: ', selectedRoles);
  }, [selectedRoles]);

  const handleRoleChange = (role) => {
    setSelectedRoles(prevRoles => {
      const newRoles = prevRoles.includes(role) ? prevRoles.filter(r => r !== role) : [...prevRoles, role];
      console.log('newRoles: ', newRoles);
      return newRoles;
    });
  };

  const handleSave = () => {
    const memberInfo = {
      name: memberData ? memberData.name : '',
      lastname: memberData ? memberData.lastname : '',
      email,
      phone: memberData ? memberData.phone : '',
      role: selectedRoles,
    };
    // Logique pour enregistrer le membre
    onClose();
  };

  return (
    <Animated.View style={[styles.slidingPanel, { transform: [{ translateX: slideAnim }] }]}>
      <View style={styles.panelContent}>
        <View style={styles.headerContainer}>
          <Text style={styles.header}>{memberData ? 'Modifier le membre' : 'Ajouter un membre'}</Text>
          <TouchableOpacity onPress={onClose}>
            <Ionicons name="close-outline" size={30} color="black" />
          </TouchableOpacity>
        </View>
        {memberData ? (
          <>
            <View style={styles.inputContainer}>
              <Text>Prénom</Text>
              <Text>{memberData.name}</Text>
            </View>
            <View style={styles.inputContainer}>
              <Text>Nom</Text>
              <Text>{memberData.lastname}</Text>
            </View>
            <View style={styles.inputContainer}>
              <Text>Email</Text>
              <Text>{memberData.email}</Text>
            </View>
            <View style={styles.inputContainer}>
              <Text>Téléphone</Text>
              <Text>{memberData.phone}</Text>
            </View>
          </>
        ) : (
          <View style={styles.inputContainer}>
            <Text style={{marginBottom:10}}>Email</Text>
            <TextInput
              style={styles.input}
              value={email}
              onChangeText={setEmail}
              placeholder="Entrez l'email du prochain membre"
            />
          </View>
        )}
        <View style={styles.inputContainer}>
          <Text style={{marginBottom:10}}>Rôles</Text>
          {privilege && Object.entries(privilege).map(([key, role]) => (
            <View key={key} style={styles.checkboxContainer}>
              <CheckBox
                value={selectedRoles.includes(parseInt(key))}
                onValueChange={() => handleRoleChange(parseInt(key))}
              />
              <Text>  {role}</Text>
            </View>
          ))}
        </View>
        <Button title="Sauvegarder" onPress={handleSave} />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  slidingPanel: {
    position: 'fixed',
    right: 0,
    top: 86,
    bottom: 0,
    minWidth: 310,
    maxWidth: '50%',
    backgroundColor: 'white',
    padding: 20,
    zIndex: 100,
  },
  panelContent: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  inputContainer: {
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 8,
    padding: 10,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
});

export default TeamPanel;
