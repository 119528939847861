import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, StyleSheet, Text, StatusBar, TextInput, TouchableOpacity, Animated } from 'react-native';
import { getAuth, updateProfile, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, onValue } from 'firebase/database';
import CustomImage from '../components/customImage.js';
import PhotoPicker from '../components/PhotoPicker.js';
import FirstNameInput from '../components/SettingProfilComponent/FirstNameInput.js';
import LastNameInput from '../components/SettingProfilComponent/LastNameInput.js';
import EmailInput from '../components/SettingProfilComponent/EmailInput.js';
import PhoneInput from '../components/SettingProfilComponent/PhoneInput.js';
import AdressInput from '../components/SettingProfilComponent/AdressInput.js';
import { LogoutCurve } from 'iconsax-react-native';
import NavBarWS from '../components/NavBarWS.js';
import axios from 'axios';

export default function SettingsProfilScreen({ navigation }) {
  const [profilInfo, setProfilInfo] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const opacityAnim = useRef(new Animated.Value(0)).current;
  const translateYAnim = useRef(new Animated.Value(20)).current;


  const functions = getFunctions();
  const updateUserProfile = async (profileData) => {
    try {
      const response = await axios.post(
        'https://us-central1-periscool-366d0.cloudfunctions.net/updateUserProfile',
        profileData
      );
      console.log('Profile updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating profile:', error.response ? error.response.data : error.message);
      // Gérer l'erreur au niveau de l'interface utilisateur si nécessaire
    }
  };
  
  const updateUserPhoto = httpsCallable(functions, 'updateUserPhoto');
  const user1 = getAuth().currentUser;
  const handleUpdateProfile = useCallback(async () => {
    const user = getAuth().currentUser;
    if (user) {
      try {
        const result = await updateUserProfile({
          userId: user.uid,
          firstName,
          lastName,
          email,
          phone,
          address,
        });
        console.log('Profile updated successfully:', result.data);
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  }, [firstName, lastName, email, phone, address]);

  const handleUploadSuccess = async (downloadURL) => {
    const user = getAuth().currentUser;
    if (user) {
      try {
        const result = await updateUserPhoto({
          userId: user.uid,
          photoURL: downloadURL,
        });
        console.log('Photo updated successfully:', result.data);
      } catch (error) {
        console.error('Error updating photo:', error);
      }
    }
  };

  useEffect(() => {
    opacityAnim.setValue(0);
    Animated.parallel([
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.timing(translateYAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            try {
                const functions = getFunctions();
                const getUserProfile = httpsCallable(functions, 'getUserProfile');
                const result = await getUserProfile();
                const data = result.data;

                if (data) {
                    setFirstName(data.firstName || '');
                    setLastName(data.lastName || '');
                    setEmail(data.email || '');
                    setPhone(data.phone || '');
                    setAddress(data.address || '');
                }
            } catch (error) {
                console.error('Error fetching user profile from Firebase:', error);
            }
        }
    };

    fetchUserProfile();
}, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const animatedButtonStyle = {
    opacity: opacityAnim,
    transform: [{ translateY: translateYAnim }],
  };

  const MainSettingComponent = React.memo(({ profilInfo }) => {
    return (
      <View style={{ paddingLeft: 150, paddingRight: 150, paddingTop: 50, flex: 1, width: '100%' }}>
        <View style={{ minWidth: '70%', maxWidth: '80vw', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginBottom: 10 }}>
          <Text style={styles.textH2}> Bienvenue sur votre compte </Text>
          <Text style={{ ...styles.textH2, color: 'rgba(111, 44, 247,1)' }}>
            {firstName !== undefined && lastName !== undefined ? firstName+' '+lastName : 'undefined'}
          </Text>
          <Text style={styles.textH2}>, </Text>
        </View>
        <Animated.View style={[styles.containerbuttonProfilsettings, animatedButtonStyle]}>
          <TouchableOpacity style={styles.buttonProfilSetting} onPress={() => handlePageChange(2)}>
            <CustomImage source={require('../assets/userIcon.png')} style={styles.iconButton} />
            <Text style={{ ...styles.textH3, marginTop: 10, fontWeight: 'bold' }}>Informations personnelles</Text>
            <Text style={{ ...styles.textH3, marginTop: 10, color: '#969696' }}>Fournissez vos informations personnelles pour faciliter la communication et l'accès à nos services.</Text>
          </TouchableOpacity>
        </Animated.View>
        <Animated.View style={[styles.containerbuttonProfilsettings, animatedButtonStyle]}>
          <TouchableOpacity style={styles.buttonProfilSetting} onPress={() => handlePageChange(1)}>
            <CustomImage source={require('../assets/lock.png')} style={styles.iconButton} />
            <Text style={{ ...styles.textH3, marginTop: 10, fontWeight: 'bold' }}>Sécurité</Text>
            <Text style={{ ...styles.textH3, marginTop: 10, color: '#969696' }}>Votre sécurité est notre priorité. Veuillez configurer un mot de passe robuste pour protéger votre compte</Text>
          </TouchableOpacity>
        </Animated.View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Animated.View style={[styles.containerbuttonProfilsettings2, animatedButtonStyle]}>
            <TouchableOpacity style={{ ...styles.buttonProfilSetting2, marginRight: 5 }} onPress={() => handlePageChange(1)}>
              <CustomImage source={require('../assets/img1profilSettings.png')} style={styles.imgButton} />
              <Text style={{ ...styles.textH3, marginTop: 10, fontWeight: 'bold' }}>Inscrire mes enfants</Text>
              <Text style={{ ...styles.textH3, marginTop: 10, color: '#969696' }}>Votre sécurité est notre priorité. Veuillez configurer un mot de passe robuste pour protéger votre compte</Text>
            </TouchableOpacity>
          </Animated.View>
          <Animated.View style={[styles.containerbuttonProfilsettings2, animatedButtonStyle]}>
            <TouchableOpacity style={{ ...styles.buttonProfilSetting2, marginLeft: 5 }}>
              <CustomImage source={require('../assets/img2profilSettings.png')} style={styles.imgButton} />
              <Text style={{ ...styles.textH3, marginTop: 10, fontWeight: 'bold' }}>Inscrire mon établissement</Text>
              <Text style={{ ...styles.textH3, marginTop: 10, color: '#969696' }}>Votre sécurité est notre priorité. Veuillez configurer un mot de passe robuste pour protéger votre compte</Text>
            </TouchableOpacity>
          </Animated.View>
        </View>
        <View style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginBottom: 20 }}>
          <HoverableTouchableOpacityDeco hoverStyle={styles.hoverStyle} />
        </View>
      </View>
    );
  });

  const SecuInfoComponent = ({ profilInfo }) => {
    return (
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" />
        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
          <TouchableOpacity onPress={() => handlePageChange(0)}>
            <Text>Compte  </Text>
          </TouchableOpacity>
          <Text>\ Informations confidentielles</Text>
        </View>
        <Text style={styles.headerText}>Vos informations de sécurité</Text>
        <Text style={styles.label}>Mot de passe</Text>
        <View style={styles.inputGroup}>
          <TextInput style={styles.input} secureTextEntry={true} placeholder="*********" />
          <TouchableOpacity style={styles.button}>
            <Text style={styles.buttonText}>Modifier</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.label}>Adresse email de récupération</Text>
        <View style={styles.inputGroup}>
          <TextInput style={styles.input} value="karine-frontiere+1@gmail.com" editable={false} />
          <TouchableOpacity style={styles.button}>
            <Text style={styles.buttonText}>Modifier</Text>
          </TouchableOpacity>
        </View>
        <View>
          <Text style={styles.label}>Compte</Text>
          <TouchableOpacity style={styles.button}>
            <Text style={[styles.buttonText, styles.deactivateText]}>Désactiver mon compte</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const PersonalInfoComponent = React.memo(({ profilInfo }) => {
    return (
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" />
        <View style={styles.breadcrumb}>
          <TouchableOpacity onPress={() => handlePageChange(0)}>
            <Text>Compte  </Text>
          </TouchableOpacity>
          <Text>\ Informations personnelles</Text>
        </View>
        <Text style={styles.headerText}>Vos informations personnelles</Text>
        <View style={styles.inputGroup}>
          <View style={styles.inputColumn}>
            <Text style={styles.label}>Prénom</Text>
            <FirstNameInput initialName={firstName} onUpdate={setFirstName} />
          </View>
          <View style={styles.inputColumn}>
            <Text style={styles.label}>Nom</Text>
            <LastNameInput initialName={lastName} onUpdate={setLastName} />
          </View>
        </View>
        <View style={styles.inputGroup}>
          <View style={styles.inputColumn}>
            <Text style={styles.label}>Email</Text>
            <EmailInput initialEmail={email} onUpdate={setEmail} />
          </View>
          <View style={styles.inputColumn}>
            <Text style={styles.label}>Numéro de téléphone</Text>
            <PhoneInput initialPhone={phone} onUpdate={setPhone} />
          </View>
        </View>
        <View style={styles.inputGroup}>
          <View style={styles.inputColumn}>
            <Text style={styles.label}>Adresse</Text>
            <AdressInput initialAdress={address} onUpdate={setAddress} />
          </View>
        </View>
        <View style={styles.photoPicker}>
          <Text style={styles.label}>Photo de Profil</Text>
          <PhotoPicker user={user1} onUploadSuccess={handleUploadSuccess} />
        </View>
        <TouchableOpacity onPress={handleUpdateProfile} style={styles.button}>
          <Text style={styles.buttonText}>Modifier</Text>
        </TouchableOpacity>
      </View>
    );
  });

  const handleSignOut = useCallback(async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log("Utilisateur déconnecté");
      navigation.navigate('Home');
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  }, [navigation]);

  const HoverableTouchableOpacityDeco = ({ hoverStyle }) => {
    const [isHovered, setIsHovered] = useState(false);
    const combinedStyle = isHovered ? [styles.navDeco, hoverStyle] : styles.navDeco;

    return (
      <TouchableOpacity
        style={combinedStyle}
        onPress={() => handleSignOut()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LogoutCurve size={25} color="red" />
        <Text style={{ ...styles.navText, color: 'black' }}>Déconnexion</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <NavBarWS navigation={navigation} page="Home" style={{ position: 'sticky', top: 0 }} />
      {currentPage === 0 && <MainSettingComponent profilInfo={profilInfo} />}
      {currentPage === 1 && <SecuInfoComponent profilInfo={profilInfo} />}
      {currentPage === 2 && <PersonalInfoComponent profilInfo={profilInfo} />}
    </View>
  );
}

const styles = StyleSheet.create({
  containerbuttonProfilsettings: {
    minWidth: '70%',
    maxWidth: '80vw',
    padding: 5,
  },
  navItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    borderRadius: 30,
    padding: 10,
    paddingLeft: 30,
  },
  hoverStyle: {
    backgroundColor: 'rgba(200,200,200,0.1)',
  },
  containerbuttonProfilsettings2: {
    padding: 5,
    width: '50%',
  },
  imgButton: {
    width: '10vw',
    height: '10vw',
    alignSelf: 'center',
    blendMode: 'multiply',
  },
  iconButton: {
    width: 40,
    height: 40,
  },
  buttonProfilSetting: {
    borderWidth: 1,
    borderColor: '#c1bebe',
    borderRadius: 20,
    padding: 20,
  },
  navDeco: {
    flexDirection: 'row',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: 5,
    borderRadius: 30,
    padding: 10,
    borderWidth: 1,
    borderColor: '#E7E7E7',
  },
  buttonProfilSetting2: {
    borderWidth: 1,
    borderColor: '#c1bebe',
    borderRadius: 20,
    padding: 20,
    flex: 1,
    width: '100%',
  },
  textH1: {
    fontFamily: 'Avenir Next Bold',
    fontSize: 70,
  },
  textH2: {
    fontFamily: 'Avenir Next Bold',
    fontSize: 30,
    fontWeight: 500,
  },
  iconStar: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  textH4: {
    fontFamily: 'Avenir Next Regular',
  },
  textH3: {
    fontFamily: 'Avenir Next Regular',
    fontSize: 20,
  },
  container: {
    flex: 1,
    paddingTop: 35,
    paddingHorizontal: 20,
  },
  breadcrumb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  headerText: {
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 30,
  },
  inputGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  inputColumn: {
    flex: 1,
    marginHorizontal: 10,
  },
  label: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: '500',
  },
  input: {
    height: 40,
    padding: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#cccccc',
  },
  button: {
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: '#007aff',
    fontSize: 16,
  },
  deactivateText: {
    color: '#ff3b30',
  },
  photoPicker: {
    alignItems: 'center',
    marginTop: 20,
  },
});
