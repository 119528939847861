import React, { useState } from 'react';
import { View, Text, StyleSheet,FlatList } from 'react-native';
import { ScrollView } from 'react-native-web';
import CustomImage from './customImage.js';

const calculateAverage = (reviews, key) => {
  if (!reviews.length) return 0;

  const total = reviews.reduce((acc, review) => acc + review[key], 0);
  return (total / reviews.length).toFixed(1); // rounded to 1 decimal place
};

//<Text>Global: {review.globalRating}/10</Text>
//<Text>Qualité pédagogique: {review.pedagogicalQuality}/10</Text>
//<Text>Encadrement: {review.supervision}/10</Text>
//<Text>Infrastructure: {review.infrastructure}/10</Text>
//<Text>Rapport Qualité-prix: {review.valueForMoney}/10</Text>

const ReviewCard = ({ review }) => (
  <View style={{flex:1,padding:20}}>
    <View style={{flexDirection:'row',alignItems:'center'}}>
      <CustomImage source={require('../assets/imgProfil50x50.png')} style={styles.iconImg} />
      <View>
        <Text style={{fontFamily:'Avenir Next Bold',fontSize:20,padding:5,marginLeft:5}}>{review.name}</Text>
        <Text style={{fontFamily:'Avenir Next Regular',fontSize:15,marginLeft:15,color:'rgba(0, 0, 0, 0.50)'}}>{new Date(review.time).toLocaleDateString()}</Text>
      </View>
    </View>
    <Text style={{padding:20,fontFamily:'Avenir Next Regular',fontSize:17,marginLeft:15,color:'rgba(0, 0, 0, 0.70)'}}>{review.description}</Text>
  </View>
);

// ...

const AvisActivity = () => {

  const [allReviews, setAllReviews] = useState({
    "activityId": "ID1",
    "reviews": [
      {
        "idProfil":"123",
        "name": "Jean Dupont",
        "globalRating": 9,
        "pedagogicalQuality": 8,
        "supervision": 7,
        "infrastructure": 9,
        "valueForMoney": 8,
        "time": "2023-09-10T14:34:56Z", // Format ISO de date-heure
        "description": "Super activité, j'ai beaucoup appris ! L'encadrement est top."
      },
      {
        "idProfil":"124",
        "name": "Marie Martin",
        "globalRating": 8,
        "pedagogicalQuality": 8,
        "supervision": 9,
        "infrastructure": 7,
        "valueForMoney": 7,
        "time": "2023-08-05T10:15:30Z",
        "description": "Très bon cours, mais l'infrastructure pourrait être améliorée."
      },
      {
        "idProfil":"125",
        "name": "Alexandre Dumas",
        "globalRating": 7,
        "pedagogicalQuality": 6,
        "supervision": 8,
        "infrastructure": 8,
        "valueForMoney": 7,
        "time": "2023-07-23T11:12:15Z",
        "description": "Je suis impressionné par la qualité pédagogique, mais le lieu est un peu bondé."
      },
      {
        "idProfil":"126",
        "name": "Lucie Lecomte",
        "globalRating": 9,
        "pedagogicalQuality": 9,
        "supervision": 10,
        "infrastructure": 9,
        "valueForMoney": 9,
        "time": "2023-09-02T13:45:23Z",
        "description": "Excellent cours ! Tout est bien organisé et les instructeurs sont professionnels."
      },
      {
        "idProfil":"127",
        "name": "Mathieu Bernard",
        "globalRating": 6,
        "pedagogicalQuality": 6,
        "supervision": 6,
        "infrastructure": 7,
        "valueForMoney": 6,
        "time": "2023-08-21T15:22:34Z",
        "description": "C'était correct, mais j'espérais un peu plus compte tenu du prix."
      },
      {
        
        "idProfil":"128",
        "name": "Sophie Durand",
        "globalRating": 8,
        "pedagogicalQuality": 7,
        "supervision": 9,
        "infrastructure": 8,
        "valueForMoney": 7,
        "time": "2023-06-18T10:08:56Z",
        "description": "J'ai adoré l'approche pédagogique, mais l'équipement pourrait être renouvelé."
      },
      {
        
        "idProfil":"129",
        "name": "Olivier Leroux",
        "globalRating": 9,
        "pedagogicalQuality": 9,
        "supervision": 9,
        "infrastructure": 10,
        "valueForMoney": 8,
        "time": "2023-09-05T09:05:10Z",
        "description": "Un des meilleurs cours auxquels j'ai assisté. Hautement recommandé !"
      }
      // ... d'autres avis
    ]
  })
  
  // Calcul des moyennes
  const avgGlobalRating = calculateAverage(allReviews.reviews, "globalRating");
  const avgPedagogicalQuality = calculateAverage(allReviews.reviews, "pedagogicalQuality");
  const avgSupervision = calculateAverage(allReviews.reviews, "supervision");
  const avgInfrastructure = calculateAverage(allReviews.reviews, "infrastructure");
  const avgValueForMoney = calculateAverage(allReviews.reviews, "valueForMoney");

  return (
    <View style={{ height: '100%',width:'100%', justifyContent: 'center', alignContent: 'center' }}>
      <Text style={{fontFamily:'Avenir Next Bold',fontSize:30,marginTop:30}}> Avis </Text>
      <View style={{flexDirection:'row',alignItems:'center'}}>
        <CustomImage source={require('../assets/icon-star.png')} style={styles.iconStar} />
        <Text style={{fontFamily:'Avenir Next Regular',fontSize:20}}> {avgGlobalRating/2} </Text>
        <Text style={{fontFamily:'Avenir Next Regular',fontSize:20}}> {allReviews.reviews.length} commentaires </Text>
      </View>
      <View style={{justifyContent:'center',alignItems:'center',width:'100%'}}>
        <View style={{flexDirection:'row',width:'100%'}}>
          <View style={{flex:1,justifyContent:'center',padding:50}}>
            <View style={{flexDirection:'row',flex:1,alignItems:'center',justifyContent:'space-between',padding:20}}>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>Qualité pédagogique: </Text>
              <View style={{width:'10vw',flexDirection:'row'}}>
                <View style={{width: '90%', height: 5, backgroundColor: '#DDDDDD', margin: 10, overflow: 'hidden'}}>
                  <View style={{backgroundColor: '#6F2CF7', width: `${avgPedagogicalQuality * 10}%`, height: '100%'}} />
                </View>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>{avgPedagogicalQuality/2}</Text>
              </View>
            </View>
            <View style={{flexDirection:'row',flex:1,alignItems:'center',justifyContent:'space-between',padding:20}}>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>Encadrement: </Text>
              <View style={{width:'10vw',flexDirection:'row'}}>
                <View style={{width: '90%', height: 5, backgroundColor: '#DDDDDD', margin: 10, overflow: 'hidden'}}>
                  <View style={{backgroundColor: '#6F2CF7', width: `${avgSupervision * 10}%`, height: '100%'}} />
                </View>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>{avgSupervision/2}</Text>
              </View>
            </View>
          </View>
          <View style={{flex:1,justifyContent:'center',padding:50}}>

          <View style={{flexDirection:'row',flex:1,alignItems:'center',justifyContent:'space-between',padding:20}}>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>Infrastructure: </Text>
              <View style={{width:'10vw',flexDirection:'row'}}>
                <View style={{width: '90%', height: 5, backgroundColor: '#DDDDDD', margin: 10, overflow: 'hidden'}}>
                  <View style={{backgroundColor: '#6F2CF7', width: `${avgInfrastructure * 10}%`, height: '100%'}} />
                </View>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>{avgInfrastructure/2}</Text>
              </View>
            </View>
            <View style={{flexDirection:'row',flex:1,alignItems:'center',justifyContent:'space-between',padding:20}}>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>Rapport Qualité-prix: </Text>
              <View style={{width:'10vw',flexDirection:'row'}}>
                <View style={{width: '90%', height: 5, backgroundColor: '#DDDDDD', margin: 10, overflow: 'hidden'}}>
                  <View style={{backgroundColor: '#6F2CF7', width: `${avgValueForMoney * 10}%`, height: '100%'}} />
                </View>
              <Text style={{fontFamily:'Avenir Next Regular',fontSize:20,marginRight:10}}>{avgValueForMoney/2}</Text>
              </View>
            </View>
          </View>

        </View>
      </View>

      <FlatList
        data={allReviews.reviews}
        renderItem={({ item }) => <ReviewCard review={item} />}
        keyExtractor={(item, index) => 'review-' + index}
        numColumns={2}
        style={{width:'100%'}}
      />
    </View>
  );
};


const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderBottomWidth: 1,
  },
  cell: {
    width: 100/4, 
    height: 50, 
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderRightWidth: 1,
  },
  hourLabel: {
    width: 100,
    height:30,
    textAlign: 'flex-start',
    color:'white',
    borderLeftWidth:1,
    borderBottomWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    paddingLeft:5,
    backgroundColor:'rgb(162 125 237)'

  },
  dayLabel: {
    height: 50,
    width:100,
    textAlign: 'center',
    borderRightWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    backgroundColor:'rgb(162 125 237)'

  },
  iconStar: {
      width: 15,
      height: 15,
  },
  iconImg: {
      width: 50,
      height: 50,
      
      resizeMode:'contain',
  },
  activity: {
    position: 'absolute',
    borderColor: 'blue',
    zIndex: 10,
    height: 50, 
  }
});
export default AvisActivity;
