import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-web';


const HourLabel = ({ hour }) => (
  <Text style={styles.hourLabel}>{hour}:00</Text>
);

const DayLabel = ({ day }) => (
  <Text style={{...styles.dayLabel,padding:10,color:'white'}}>{day}</Text>
);

const Activity = ({ dayIndex, startHour, duration, title, colorKey, onHover, isHovered }) => (
  <View 
    style={{ 
      ...styles.activity, 
      backgroundColor: isHovered ?  'rgba(111, 44, 247,1 ) ': 'rgba(111, 44, 247,0.3 ) ',
      zIndex: isHovered ? 100 : 10, 
      top: (dayIndex) * 51+30, 
      marginLeft: startHour * 25 +100, 
      width: duration * 25, 
      height: 50 ,
      borderRadius:50,
      justifyContent:'center',
      alignItems:'center',
    }}
    onMouseEnter={() => onHover(title)}
    onMouseLeave={() => onHover(null)}
  >
    <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}>{title}</Text>
  </View>
);
// ...

const CalendarPlaceAct = ({ profilInfo }) => {
  console.log('profilInfo.places : ',profilInfo.places);

const [hoveredActivity, setHoveredActivity] = React.useState(null);

const handleActivityHover = (activityTitle) => {
  setHoveredActivity(activityTitle);
}

    return (
        <ScrollView showsHorizontalScrollIndicator={false}  horizontal={true} style={{flex:1}}>
        <View style={{ width:1800,position: 'relative', marginTop:20,borderRadius:50,backgroundColor:'rgba(111, 44, 247, 0.5)',overflow:'hidden'}}>
            <View style={{ flexDirection: 'row',marginLeft:99}}>
                <HourLabel hour={"8"} />
                {[...Array(15)].map((_, i) => (
                    <HourLabel hour={9 + i} key={i} />
                ))}
            </View>

            {['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI','SAMEDI','DIMANCHE'].map((day, dayIndex) => (
                <View style={styles.grid} key={day}>
                    <DayLabel day={day} />
                    {[...Array(64)].map((_, i) => (
                        <View style={styles.cell} key={i} />
                    ))}
                </View>
            ))}

{
  
  Object.keys(profilInfo.places).map(index => 
    profilInfo.places[index].calendar.map(act => {
        const [startTime, endTime] = act.time.split(' - ');
        const getQuarters = (time) => {
            const [hour, minutes] = time.split(':').map(t => parseInt(t, 10));
            return hour * 4 + minutes / 15;
        };
    
        const startHour = (getQuarters(startTime) - 8 * 4); // -8*4 because 8:00 is the starting point
        const duration = getQuarters(endTime) - getQuarters(startTime);
    
        return (
          <Activity 
              dayIndex={['Lun', 'Mar', 'Mer', 'Jeu', 'Ven','Sam','Dim'].indexOf(act.day)}
              startHour={startHour}
              duration={duration}
              title={act.group}
              key={act.id}
              colorKey={'B'}
              onHover={handleActivityHover}
              isHovered={hoveredActivity === act.group}
          />
      );

}) ).flat() }
        </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderBottomWidth: 1,
  },
  cell: {
    width: 100/4, 
    height: 50, 
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    borderStyle: 'dashed',
    borderRightWidth: 1,
  },
  hourLabel: {
    width: 100,
    height:30,
    textAlign: 'flex-start',
    color:'white',
    borderLeftWidth:1,
    borderBottomWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    paddingLeft:5,
    backgroundColor:'rgb(162 125 237)'

  },
  dayLabel: {
    height: 50,
    width:100,
    textAlign: 'center',
    borderRightWidth:1,
    borderColor: 'rgba(210 ,210 ,247, 0.7)',
    backgroundColor:'rgb(162 125 237)'

  },
  activity: {
    position: 'absolute',
    borderColor: 'blue',
    zIndex: 10,
    height: 50, 
  }
});
export default CalendarPlaceAct;
