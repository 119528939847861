import React,  { useState,useEffect,useRef } from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import CustomImage from './customImage.js';

import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { ScrollView } from 'react-native-web';

import {ArrowLeft2, ArrowRight2, House2, Brush2, Cup, Music, ChemicalGlass, Microscope, Tree, Translate, Code, ArchiveBook, Book1, Crown1, Drop, Bank, MagicStar, RulerPen, Bag } from 'iconsax-react-native';

import LoisirIcon from "../assets/iconsvg/loisir.svg";
import ActiviteSportiveIcon from "../assets/iconsvg/activite-sportive.svg";
import AideDevoirsIcon  from "../assets/iconsvg/aide-aux-devoirs.svg";
import ArtspectacleIcon  from "../assets/iconsvg/arts-du-spectacle.svg";
import ArtsMartiauxIcon  from "../assets/iconsvg/arts-martiaux.svg";
import AstronomieIcon  from "../assets/iconsvg/astronomie.svg";
import AtelierCuisineIcon from "../assets/iconsvg/atelier-cuisine.svg";
import AteliersCreatifsIcon from "../assets/iconsvg/ateliers-creatifs.svg";
import ClubInteretIcon from "../assets/iconsvg/club-interet.svg";
import CoursMusiqueIcon from "../assets/iconsvg/cours-musique.svg";
import EchecsIcon from "../assets/iconsvg/echecs.svg";
import EnvironnementIcon from "../assets/iconsvg/environnement.svg";
import HistoireCultureIcon from "../assets/iconsvg/histoire-culture.svg";
import LanguesEtrangereIcon from "../assets/iconsvg/langues-etrangeeres.svg";
import Scienceicon from "../assets/iconsvg/sciences.svg";
import TechnologiesIcon from "../assets/iconsvg/technologies.svg";
import YogaBienEtreIcon from "../assets/iconsvg/yoga-bien-etre.svg";

// Répétez pour les autres icônes

const categories = [
    { id: 1, label: 'Espaces de Loisirs',  icon: House2 },
    { id: 2, label: 'Ateliers créatifs', icon: Brush2},
    { id: 3, label: 'Activités sportives', icon: Cup},
    { id: 4, label: 'Musique', icon: Music},
    { id: 5, label: 'Sciences', icon: ChemicalGlass },
    { id: 6, label: 'Technologies', icon: Code },
    { id: 7, label: 'Langues étrangères', icon: Translate },
    { id: 8, label: 'Arts du spectacle', icon: MagicStar },
    { id: 9, label: "Clubs d'intérêts", icon: Book1  },
    { id: 10, label: 'Environnement', icon: Tree },
    { id: 13, label: 'Histoire et culture', icon: ArchiveBook},
    { id: 14, label: 'Apprentissage', icon: RulerPen },
    { id: 15, label: 'Bien-être', icon: Drop},
    { id: 16, label: 'Aventure', icon: Microscope },
    { id: 17, label: 'Cuisine', icon: Crown1 },
    // ... Ajoutez le reste des activités ici
];
const categories2 = [
    { id: 1, label: 'Centre de loisirs',  icon: 'loisir' },
    { id: 2, label: 'Ateliers créatifs', icon: 'ateliers-creatifs'},
    { id: 3, label: 'Club sportif', icon: 'activite-sportive'},
    { id: 4, label: 'Cours de musique', icon: 'cours-musique'},
    { id: 5, label: 'Sciences découverte', icon: 'sciences' },
    { id: 6, label: 'Technologies', icon: 'technoligies' },
    { id: 7, label: 'Langues étrangères', icon: 'langues-etrangerees' },
    { id: 8, label: 'Arts du spectacle', icon: 'arts-du-spectacle' },
    { id: 9, label: "Clubs d'intérêts", icon: 'club-interet'  },
    { id: 10, label: 'Environnement', icon: 'environnement' },
    { id: 12, label: 'Arts martiaux', icon: 'arts-martiaux' },
    { id: 13, label: 'Histoire et culture', icon: 'histoire-culture'},
    { id: 14, label: 'Aide aux devoirs', icon: 'aide-aux-devoirs' },
    { id: 15, label: 'Yoga et bien-être', icon: 'yoga-bien-etre'},
    { id: 16, label: 'Astronomie', icon: 'astronomie' },
    { id: 17, label: 'Echecs', icon: 'echecs' },
    // ... Ajoutez le reste des activités ici
];


export default function ActivityFilter({ onActivitySelect,setOnActivitySelect }) {
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [isAtStart, setIsAtStart] = useState(false); // Ajout de l'état isAtStart

    const scrollViewRef = useRef(); // Création de la référence
    const scrollToRight = () => {
        // Obtenez la position actuelle de défilement
        const currentScrollPosition = scrollViewRef.current?.scrollLeft || 0;
    
        // Défilement vers la droite
        scrollViewRef.current?.scrollTo({
            x: currentScrollPosition + 200, // Ajoutez 200 à la position actuelle
            animated: true,
        });
    };
    
    const scrollToLeft = () => {
        // Obtenez la position actuelle de défilement
        const currentScrollPosition = scrollViewRef.current?.scrollLeft || 0;
    
        // Défilement vers la gauche
        scrollViewRef.current?.scrollTo({
            x: currentScrollPosition - 200, // Soustrayez 200 de la position actuelle
            animated: true,
        });
    };
    const handleScroll = (event) => {
        // Vos variables existantes
        const contentWidth = event.nativeEvent.contentSize.width;
        const scrollViewWidth = event.nativeEvent.layoutMeasurement.width;
        const scrollOffset = event.nativeEvent.contentOffset.x;
    
        // Mise à jour de isAtEnd
        const isEnd = scrollOffset + scrollViewWidth >= contentWidth;
        setIsAtEnd(isEnd);
    
        const isStart = scrollOffset > 30;
        setIsAtStart(isStart);
    };
    
    return (
        <View style={{width:'100%',borderTopWidth:1,borderColor:'#E7E7E7'}}>

        
<ScrollView
            horizontal
            ref={scrollViewRef}
            showsHorizontalScrollIndicator={false}
            style={{ ...styles.container, zIndex: 1 }}
            onScroll={handleScroll}
            scrollEventThrottle={16} // Ajustez cette valeur si nécessaire
        >
            {categories.map(category => (
    <TouchableOpacity 
        key={category.id} 
        style={onActivitySelect === category.label ? styles.buttonSelected : styles.button}
        onPress={() => setOnActivitySelect(onActivitySelect === category.label ? null : category.label)}
    >
       <category.icon color={onActivitySelect === category.label ? '#6F2CF7' : '#808080'}  size={24}/>

        <Text style={onActivitySelect === category.label ? styles.TextFilterSelected : styles.TextFilter}>
            {category.label}
        </Text>
        <View style={{width:5, height:5, borderRadius:5, marginTop:5,marginBottom:5, backgroundColor: onActivitySelect === category.label ? '#6F2CF7' : 'transparent'}}></View>
    </TouchableOpacity>
))}
        </ScrollView>
        <LinearGradient 
    style={styles.LinearBordure}
    start={{x: 0, y: 0}} end={{x: 0, y: 1}}
    colors={['#E7E7E7', '#E7E7E7']}
/>
        {!isAtEnd && (
            <View style={{ position: 'absolute', right: 0,width:200,height:'100%',zIndex:10}}>
                <LinearGradient
                    colors={['transparent', '#FFFFFF', '#FFFFFF']}
                    start={[0, 1]} end={[1, 0]}
                    style={styles.button}
                >
                   
                   <TouchableOpacity  style={{...styles.buttonHearth}} onPress={scrollToRight}>
                        <ArrowRight2 size={20} color={`#000000`}/> 
                    </TouchableOpacity>
                </LinearGradient>
            </View>
        )}
        
        {isAtStart && (
            <View style={{ position: 'absolute', left: 0, width:200, height: '100%', zIndex: 10 ,paddingBottom:20}}>
                <LinearGradient
                    colors={['#FFFFFF', '#FFFFFF', 'transparent']}
                    start={[0, 0]} end={[1, 1]}
                    style={styles.button}
                >
                <TouchableOpacity  style={{...styles.buttonHearth}} onPress={scrollToLeft}>
                        <ArrowLeft2 size={20} color={`#000000`}/> 
                    </TouchableOpacity>
                </LinearGradient>
            </View>
        )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width:'100%',
        flexDirection: 'row',
        flexWrap: 'wrap', 
        paddingLeft:80,
        paddingRight:80
    },
    button: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        borderColor: '#ccc',
        justifyContent:'center',
        alignItems:'center',marginTop:10
    },
    buttonSelected: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        borderColor: '#ccc',
        justifyContent:'center',
        alignItems:'center',marginTop:10
    },
     icon: {
        width: 20,
        height:20,
        color: '#6F2CF7',
    },
    TextFilter: {
        color: '#808080',
        fontWeight: 'Bold',
        paddingTop:10,
        fontSize:11,
        fontFamily: 'Avenir Next Regular',
    },
    TextFilterSelected : {
        color: '#000000',
        fontWeight: 'Bold',
        paddingTop:10,
        fontSize:11,
        fontFamily: 'Avenir Next Regular',
    },
    LinearBordure : {
        width:'100%',
        height:1
    },
    buttonHearth:{
        borderRadius:50,
        borderWidth:1,
        borderColor:'#E7E7E7',
        padding:7,
        margin:5,
        marginHorizontal:2.5,
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center'
    },
});
