import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

// Fonction pour obtenir l'ID utilisateur Firebase
const getFirebaseUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : null;
};

// Fonction pour mélanger l'ID utilisateur et le timestamp
const mixIdAndTimestamp = (userId, timestamp) => {
  const userIdPart = btoa(userId); // Encode en base64
  console.log('userIdPart : '+String(userIdPart))
  const timestampPart = timestamp.toString(36); // Convertit en base36
  console.log('timestampPart : '+String(timestampPart))
  const mixedString = String(`${userIdPart}-${timestampPart}`);
  console.log('mixedString : '+mixedString)
  return mixedString;
};


// Hook personnalisé pour générer et retourner un identifiant unique
const useUniqueId = () => {

  const userId = getFirebaseUserId();
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  console.log('uderId : '+String(userId))
  console.log('timestamp : '+String(timestamp))
  const id = mixIdAndTimestamp(userId, timestamp);
  console.log('id : '+String(id))

  return id;
};

export default useUniqueId 