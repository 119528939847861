import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    TextInput,
    Button,
    Image,
    Animated,
    Dimensions,
    ActivityIndicator,
    FlatList,
    TouchableWithoutFeedback
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useDropzone } from 'react-dropzone';
import CustomImage from './customImage';
import { Timestamp } from 'firebase/firestore';
import { db, storage } from '../firebase';

import { collection, addDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { auth } from '../firebase'; 
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';



export default function  PhotoPicker ({ user, onUploadSuccess  }) {

    const opacityAnim = useRef(new Animated.Value(0)).current; // Animation pour l'opacité
    const translateYAnim = useRef(new Animated.Value(20)).current; // Animation pour la translation
    const [image1, setImage] = useState(null);

    const uploadImage = async (imageFile) => {
        console.log("Tentative d'upload de l'image", imageFile);
        const imageRef = storageRef(storage, 'images/' +user.uid);
    
        try {
            let snapshot = await uploadBytes(imageRef, imageFile);
            let downloadURL = await getDownloadURL(snapshot.ref)
            onUploadSuccess(downloadURL); // Appeler le callback avec l'URL
            
        } catch (error) {
            console.error("Error uploading image: ", error);
        }
    };


  
    const fileInputRef = useRef(null); // Création de la référence

    const handleDragOver = useCallback((event) => {
      event.preventDefault(); // Empêcher le comportement par défaut
    }, []);
    const handleStudentLimitChange = (value) => {
      const number = parseInt(value, 10);
      if (!isNaN(number) && number.toString() === value) {
        setStudentLimit(value);
      }
    };
    
  const handleDrop = useCallback((event) => {
    event.preventDefault(); // Empêcher le comportement par défaut du navigateur
    event.stopPropagation(); // Empêcher la propagation de l'événement
    const file = event.dataTransfer.files[0];
    processFile(file);
  }, []);
  const onDrop = useCallback(acceptedFiles => {
      // Do something with the files
      const file = acceptedFiles[0] || acceptedFiles;
  
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      processFile(file);
    }, [processFile]);
    
    const {getRootProps, getInputProps} = useDropzone({onDrop});
  
  
const processFile = (file) => {
    console.log("Fichier détecté", file);
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        // Appel de la fonction d'upload
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <TouchableOpacity 
      style={{...styles.imageUploader, overflow: 'hidden', borderWidth: 2, borderRadius: 20, borderStyle: 'dashed', borderColor: 'lightgray', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onPress={() => fileInputRef.current && fileInputRef.current.click()} // Vérifiez si fileInputRef.current existe avant de cliquer
    >
      <div {...getRootProps()} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
        {/* Ajoutez l'élément input ici */}
        <input type="file" {...getInputProps()} ref={fileInputRef} style={{ }} />
        {image1||user ? (
          <View style={{ alignItems: 'center', justifyContent: 'center', width: '100%', alignContent: 'center' }}>
          <CustomImage source={{ uri: image1||user.photoURL  }} style={{ width: '100%', aspectRatio:1/1 }}/>
          </View>
        ) : (
          <View style={{ alignItems: 'center', justifyContent: 'center', padding: 10, width: '80%', alignContent: 'center' }}>
            <CustomImage source={require('../assets/iconUploadImg.png')} style={{ width: 40, height: 40 }} />
            <Text style={styles.textH4}>Faites glisser votre photo de couverture ici</Text>
            <Text style={{ ...styles.textH4, marginBottom: 20 }}>ou cliquer pour télécharger depuis votre appareil</Text>
          </View>
        )}
      </div>
    </TouchableOpacity>
  );
}


const styles = StyleSheet.create({

    imageUploader: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'lightgray',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        marginBottom: 20,
        overflow:'hidden',
        width:'50%',
        height:'20vh'
      },
})