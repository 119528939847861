import React, { useState,useEffect,useRef,useMemo,useCallback } from 'react';

import { View, StyleSheet, Text, Button,TextInput,FlatList,Animated,Dimensions,textArea } from 'react-native';
import NavBarWS from '../components/NavBarWS.js'
import MapView, { Marker, ClusterProps, MarkerClusterer } from '@teovilla/react-native-web-maps';
import HomeLieux from '../components/HomeLieux.js'
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence

import { Star , Buildings, Location, Building3, Building } from 'iconsax-react-native';
import { Range, getTrackBackground } from 'react-range';
import { useDropzone } from 'react-dropzone';

import CustomImage from '../components/customImage.js';
import LocationSearchInput from '../components/LocationSearchInput.js';
import AgeRangePicker from '../components/AgeRangePicker.js';
import { onAuthStateChanged,getAuth } from "firebase/auth";
import { ScrollView, TouchableOpacity } from 'react-native-web';
// Exemple d'écrans - Remplacez ceci par vos propres composants
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { getDatabase, ref, set,push,onValue } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions"; // Ajoutez cette ligne


import PlacesAutocomplete from 'react-places-autocomplete';

export default function InscriptionActiviteScreen ({ navigation,user }) {
  const [selectedButton, setSelectedButton] = useState('Lieux');
  const [searchActivity, setSearchActivity] = useState('');
  const [selectedDay, setSelectedDay] = useState({}); // Pour stocker les jours sélectionnés
  const [location, setLocation] = useState({ lat: 43.26996612548828, lng: 5.395908832550049 });
  const [address, setAddress] = useState('');//NavBaradresse

  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [step, setStep] = useState(0);
  const [choice1, setChoice1] = useState(undefined); //choix Org
  const [input2, setInput2] = useState(undefined);//name
  const [input3, setInput3] = useState(undefined);//Adresse
  const [input4, setInput4] = useState(undefined);//Email
  const [input5, setInput5] = useState(undefined);//tel
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [input6, setInput6] = useState(undefined);//desc etablissement
  const [coverPic, setCoverPic] = useState(undefined);//coverPic
  const [doubleSliderValue, setDoubleSliderValuePic] = useState([3,18]);//agePicker
  const [selectedAvantages, setSelectedAvantages] = useState([]);//avantages
  const [input7, setInput7] = useState(undefined);//desc mission
  const [logo, setLogo] = useState('');

  const handleAgeRangeChange = (values) => {
    setDoubleSliderValue(values);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  const handleEmailChange = (text) => {
    // Filtrer les espaces et caractères non désirés (on pourrait ajouter plus de validation ici)
    const emailValue = text.replace(/[^a-zA-Z0-9@._-]/g, '');
    setInput4(emailValue); // Mettre à jour l'état de input4
  };
  const handlePhoneNumberChange = (event) => {
    if (event && event.target && event.target.value !== undefined) {
      const value = event.target.value;
      // Filtrer les caractères non numériques
      const numericValue = value.replace(/[^0-9]/g, '');
      setInput5(numericValue);
    }
  };

  const avantages = [
    {
      activity: 'Espaces de loisirs',
      options: [
        {
          label: 'Centre Aérés Public',
          avantages: [
            { id: 1, label: 'Aires de jeux extérieures', icon:'loisir11'},
            { id: 2, label: 'Salles polyvalentes', icon:'loisir11'},
            { id: 3, label: 'Terrains de sport', icon:'loisir11'},
            { id: 4, label: 'Piscines', icon:'loisir11'},
            { id: 5, label: 'Espaces verts', icon:'loisir11'},
            { id: 6, label: 'Zones de détente', icon:'loisir11'},
          ],
        },
        {
          label: 'Centre Aérés Privé',
          avantages: [
            { id: 1, label: 'Aires de jeux extérieures', icon:'loisir11'},
            { id: 2, label: 'Salles polyvalentes', icon:'loisir11'},
            { id: 3, label: 'Terrains de sport', icon:'loisir11'},
            { id: 4, label: 'Piscines', icon:'loisir11'},
            { id: 7, label: 'Salles de jeux', icon:'loisir11'},
            { id: 8, label: 'Ateliers créatifs', icon:'loisir11'},
          ],
        },
        {
          label: 'Camps de vacances',
          avantages: [
            { id: 5, label: 'Espaces verts', icon:'loisir11'},
            { id: 9, label: 'Hébergements', icon:'loisir11'},
            { id: 10, label: 'Parcours d’aventure', icon:'loisir11'},
            { id: 11, label: 'Espaces pour feux de camp', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Activités sportives',
      options: [
        {
          label: 'Sports Collectifs',
          avantages: [
            { id: 3, label: 'Terrain de sport', icon:'loisir11' },
            { id: 9, label: 'Gymnase', icon:'loisir11' },
            { id: 10, label: 'Vestiaire', icon:'loisir11' },
            { id: 11, label: 'Salle de musculation', icon:'loisir11' },
          ],
        },
        {
          label: 'Sports Individuels',
          avantages: [
            { id: 12, label: 'Salle de sport', icon:'loisir11' },
            { id: 13, label: 'Piste d’athlétisme' , icon:'loisir11'},
            { id: 14, label: 'Court de tennis', icon:'loisir11' },
            { id: 15, label: 'Mur d’escalade', icon:'loisir11' },
            { id: 4, label: 'Piscine', icon:'loisir11' },
          ],
        },
        {
          label: 'Danses',
          avantages: [
            { id: 16, label: 'Salle de danse', icon:'loisir11' },
            { id: 17, label: 'Studio de répétition' , icon:'loisir11'},
          ],
        },
        {
          label: 'Arts Martiaux',
          avantages: [
            { id: 18, label: 'Dojo', icon:'loisir11' },
            { id: 19, label: 'Tatami', icon:'loisir11' },
            { id: 20, label: 'Salle d’entraînement', icon:'loisir11' },
          ],
        },
        {
          label: 'Activités Nautiques',
          avantages: [
            { id: 4, label: 'Piscine', icon:'loisir11' },
            { id: 21, label: 'Bateaux', icon:'loisir11' },
            { id: 22, label: 'Base nautique' , icon:'loisir11'},
            { id: 23, label: 'Centre d\'accueil' , icon:'loisir11'},
          ],
        },
      ],
    },
  
    {
      activity: 'Activités créatives',
      options: [
        {
          label: 'Arts Visuels',
          avantages: [
            { id: 9, label: 'Atelier d\'art', icon:'loisir11'},
            { id: 10, label: 'Studio de photographie', icon:'loisir11'},
            { id: 11, label: 'Salle d\'exposition', icon:'loisir11'},
          ],
        },
        {
          label: 'Arts Manuels',
          avantages: [
            { id: 12, label: 'Atelier de poterie', icon:'loisir11'},
            { id: 13, label: 'Atelier de couture',icon:'loisir11' },
            { id: 14, label: 'Salle de bricolage', icon:'loisir11'},
          ],
        },
        {
          label: 'Lecture et Écriture',
          avantages: [
            { id: 15, label: 'Bibliothèque',icon:'loisir11' },
            { id: 16, label: 'Salle de lecture', icon:'loisir11'},
            { id: 17, label: 'Atelier d’écriture', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Musique',
      options: [
        {
          label: 'Instruments à Cordes',
          avantages: [
            { id: 18, label: 'Salle de musique', icon:'loisir11' },
            { id: 19, label: 'Studio d’enregistrement', icon:'loisir11' },
            { id: 20, label: 'Auditorium', icon:'loisir11' },
          ],
        },
        {
          label: 'Instruments à Vent',
          avantages: [
            // Réutilisation des id pour les mêmes installations
            { id: 18, label: 'Salle de musique',icon:'loisir11' },
            { id: 19, label: 'Studio d’enregistrement', icon:'loisir11'},
            { id: 20, label: 'Auditorium', icon:'loisir11'},
          ],
        },
        {
          label: 'Percussions',
          avantages: [
            { id: 21, label: 'Salle de percussion',icon:'loisir11' },
            { id: 19, label: 'Studio d’enregistrement',icon:'loisir11' },
            { id: 20, label: 'Auditorium',icon:'loisir11' },
          ],
        },
        {
          label: 'Chant',
          avantages: [
            { id: 22, label: 'Salle de chant',icon:'loisir11' },
            { id: 19, label: 'Studio d’enregistrement',icon:'loisir11' },
            { id: 20, label: 'Auditorium',icon:'loisir11' },
          ],
        },
        {
          label: 'Musique Électronique',
          avantages: [
            { id: 23, label: 'Studio de MAO (Musique Assistée par Ordinateur)',icon:'loisir11' },
            { id: 19, label: 'Studio d’enregistrement', icon:'loisir11'},
            { id: 24, label: 'Espace de création numérique', icon:'loisir11'},
          ],
        },
        {
          label: 'Théorie Musicale et Composition',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'},
            { id: 15, label: 'Bibliothèque musicale', icon:'loisir11'}, // Bibliothèque
            { id: 19, label: 'Studio d’enregistrement', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Arts du spectacle',
      options: [
        {
          label: 'Théâtre',
          avantages: [
            { id: 26, label: 'Salle de théâtre',icon:'loisir11' },
            { id: 2, label: 'Salle polyvalente',icon:'loisir11' }, // Salle polyvalente
            { id: 27, label: 'Espace de répétition', icon:'loisir11'},
          ],
        },
        {
          label: 'Cirque',
          avantages: [
            { id: 28, label: 'Chapiteau', icon:'loisir11'},
            { id: 29, label: 'Aire d’entraînement',icon:'loisir11' },
            { id: 30, label: 'Matériel de cirque',icon:'loisir11' },
          ],
        },
        {
          label: 'Magie',
          avantages: [
            { id: 31, label: 'Salle de spectacle', icon:'loisir11'},
            { id: 27, label: 'Espace de répétition', icon:'loisir11'}, // Espace de répétition
          ],
        },
      ],
    },
    {
      activity: 'Sciences',
      options: [
        {
          label: 'Biologie',
          avantages: [
            { id: 32, label: 'Laboratoire', icon:'loisir11'},
            { id: 33, label: 'Serre',icon:'loisir11' },
            { id: 34, label: 'Salle d\'observation', icon:'loisir11'},
          ],
        },
        {
          label: 'Chimie',
          avantages: [
            { id: 32, label: 'Laboratoire', icon:'loisir11'}, // Laboratoire
            { id: 35, label: 'Salle d\'expérimentation', icon:'loisir11'},
          ],
        },
        {
          label: 'Physique',
          avantages: [
            { id: 32, label: 'Laboratoire',icon:'loisir11' }, // Laboratoire
            { id: 36, label: 'Salle de mécanique', icon:'loisir11'},
            { id: 37, label: 'Salle d\'électricité', icon:'loisir11'},
          ],
        },
        {
          label: 'Sciences de la Terre',
          avantages: [
            { id: 32, label: 'Laboratoire',icon:'loisir11' }, // Laboratoire
            { id: 38, label: 'Salle de cartographie',icon:'loisir11' },
          ],
        },
        {
          label: 'Sciences Environnementales',
          avantages: [
            { id: 32, label: 'Laboratoire', icon:'loisir11'}, // Laboratoire
            { id: 39, label: 'Terrain d\'étude', icon:'loisir11'},
          ],
        },
        {
          label: 'Astronomie',
          avantages: [
            { id: 40, label: 'Observatoire', icon:'loisir11'},
            { id: 41, label: 'Planétarium', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Technologies',
      options: [
        {
          label: 'Programmation',
          avantages: [
            { id: 42, label: 'Salle informatique', icon:'loisir11'},
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 43, label: 'Atelier de programmation', icon:'loisir11'},
          ],
        },
        {
          label: 'Robotique',
          avantages: [
            { id: 35, label: 'Salle d\'expérimentation', icon:'loisir11'}, // Salle d'expérimentation
            { id: 44, label: 'Atelier de robotique',icon:'loisir11' },
            { id: 42, label: 'Salle informatique', icon:'loisir11'}, // Salle informatique
          ],
        },
        {
          label: 'Intelligence Artificielle',
          avantages: [
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 45, label: 'Studio IA', icon:'loisir11'},
          ],
        },
        {
          label: 'Design et Modélisation 3D',
          avantages: [
            { id: 46, label: 'Studio 3D',icon:'loisir11' },
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
          ],
        },
        {
          label: 'Électronique',
          avantages: [
            { id: 47, label: 'Atelier électronique',icon:'loisir11' },
            { id: 35, label: 'Salle d\'expérimentation',icon:'loisir11' }, // Salle d'expérimentation
          ],
        },
        {
          label: 'Cybersécurité',
          avantages: [
            { id: 42, label: 'Salle informatique', icon:'loisir11'}, // Salle informatique
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
          ],
        },
        {
          label: 'VR et AR',
          avantages: [
            { id: 48, label: 'Studio', icon:'loisir11'},
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 35, label: 'Salle d’expérimentation', icon:'loisir11'}, // Salle d'expérimentation
          ],
        },
      ],
    },
    {
      activity: 'Environnement',
      options: [
        {
          label: 'Conservation de la Nature',
          avantages: [
            { id: 39, label: 'Terrain d\'étude',icon:'loisir11' }, // Terrain d'étude
            { id: 49, label: 'Atelier de conservation', icon:'loisir11'},
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
          ],
        },
        {
          label: 'Éducation Écologique',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 50, label: 'Atelier d\'éco-activités',icon:'loisir11' },
            { id: 51, label: 'Salle d\'expérimentation écologique',icon:'loisir11' },
          ],
        },
        {
          label: 'Agriculture Durable',
          avantages: [
            { id: 33, label: 'Serre', icon:'loisir11'}, // Serre
            { id: 52, label: 'Terrain d\'agroécologie',icon:'loisir11' },
            { id: 53, label: 'Atelier de permaculture',icon:'loisir11' },
          ],
        },
        {
          label: 'Gestion des Ressources Naturelles',
          avantages: [
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
            { id: 54, label: 'Atelier sur les énergies renouvelables',icon:'loisir11' },
            { id: 39, label: 'Terrain d\'étude pour le recyclage', icon:'loisir11'}, // Terrain d'étude avec contexte spécifique
          ],
        },
        {
          label: 'Études Océaniques',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 55, label: 'Atelier de conservation marine', icon:'loisir11'},
            { id: 56, label: 'Terrain d\'étude côtier', icon:'loisir11'},
          ],
        },
        {
          label: 'Urbanisme Vert',
          avantages: [
            { id: 57, label: 'Studio de design urbain',icon:'loisir11' },
            { id: 58, label: 'Atelier de planification durable',icon:'loisir11' },
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
          ],
        },
      ],
    },
    {
      activity: 'Langues étrangères',
      options: [
        {
          label: 'Cours de Langues',
          avantages: [
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 59, label: 'Atelier de pratique linguistique', icon:'loisir11'},
          ],
        },
        {
          label: 'Échanges Linguistiques',
          avantages: [
            { id: 60, label: 'Salle de conversation',icon:'loisir11' },
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 61, label: 'Atelier d\'échange culturel',icon:'loisir11' },
          ],
        },
        {
          label: 'Immersion Culturelle',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 62, label: 'Atelier de cuisine internationale',icon:'loisir11' },
            { id: 63, label: 'Salle d\'expérimentation culturelle', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Club d\'intérêts',
      options: [
        {
          label: 'Club de Jeux de Rôles',
          avantages: [
            { id: 64, label: 'Salle de jeux',icon:'loisir11' },
            { id: 65, label: 'Atelier de jeux de rôles', icon:'loisir11'},
            { id: 42, label: 'Salle informatique', icon:'loisir11'}, // Salle informatique
          ],
        },
        {
          label: 'Club de Jeux de Stratégies',
          avantages: [
            { id: 64, label: 'Salle de jeux',icon:'loisir11' }, // Salle de jeux
            { id: 66, label: 'Atelier de tactiques et stratégies', icon:'loisir11'},
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
          ],
        },
        {
          label: 'Club de Jeux Vidéo',
          avantages: [
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 64, label: 'Salle de jeux',icon:'loisir11' }, // Salle de jeux
          ],
        },
        {
          label: 'Club de Lecture',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 67, label: 'Atelier d\'écriture créative',icon:'loisir11' },
            { id: 15, label: 'Bibliothèque', icon:'loisir11'}, // Bibliothèque
          ],
        },
        {
          label: 'Club de Cinéma',
          avantages: [
            { id: 68, label: 'Salle de projection',icon:'loisir11' },
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 69, label: 'Studio de cinéma pour création et montage', icon:'loisir11'},
            { id: 70, label: 'Atelier de critique cinématographique',icon:'loisir11'},
          ],
        },
        {
          label: 'Club de Débats',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 71, label: 'Atelier de rhétorique et d\'éloquence',icon:'loisir11' },
            { id: 19, label: 'Studio d\'enregistrement', icon:'loisir11'}, // Studio d’enregistrement
          ],
        },
        {
          label: 'Club d\'Informatique',
          avantages: [
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 72, label: 'Atelier de cybersécurité et de hacking éthique',icon:'loisir11' },
            { id: 73, label: 'Studio de développement logiciel', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Cuisine',
      options: [
        {
          label: 'Ateliers de Cuisine',
          avantages: [
            { id: 74, label: 'Cuisine pédagogique',icon:'loisir11' },
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
            { id: 75, label: 'Atelier de techniques culinaires',icon:'loisir11' },
          ],
        },
        {
          label: 'Ateliers de Pâtisserie et Boulangerie',
          avantages: [
            { id: 76, label: 'Atelier de pâtisserie', icon:'loisir11'},
            { id: 74, label: 'Cuisine pédagogique', icon:'loisir11'}, // Cuisine pédagogique
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
          ],
        },
      ],
    },
    {
      activity: 'Bien-être',
      options: [
        {
          label: 'Yoga',
          avantages: [
            { id: 77, label: 'Salle de yoga', icon:'loisir11'},
            { id: 78, label: 'Espace extérieur',icon:'loisir11' },
            { id: 5, label: 'Salle de relaxation',icon:'loisir11' }, // Salle de relaxation
          ],
        },
        {
          label: 'Méditation',
          avantages: [
            { id: 79, label: 'Salle de méditation', icon:'loisir11'},
            { id: 78, label: 'Espace extérieur',icon:'loisir11' }, // Espace extérieur
            { id: 5, label: 'Salle de relaxation', icon:'loisir11'}, // Salle de relaxation
          ],
        },
        {
          label: 'Émotions',
          avantages: [
            { id: 5, label: 'Salle de relaxation',icon:'loisir11' }, // Salle de relaxation
            { id: 80, label: 'Salle multimédia', icon:'loisir11'},
            { id: 6, label: 'Zone de détente', icon:'loisir11'}, // Zone de détente
          ],
        },
      ],
    },
    {
      activity: 'Aventure',
      options: [
        {
          label: 'Randonnée et Trekking',
          avantages: [
            { id: 81, label: 'Sentiers de randonnée', icon:'loisir11'},
            { id: 82, label: 'Base de départ pour trekking', icon:'loisir11'},
            { id: 83, label: 'Point de vue et zones de repos', icon:'loisir11'},
          ],
        },
        {
          label: 'Sports Extrêmes',
          avantages: [
            { id: 84, label: 'Parcours d\'accrobranche', icon:'loisir11'},
            { id: 85, label: 'Zone de saut à l\'élastique', icon:'loisir11'},
            { id: 86, label: 'Piste de VTT',icon:'loisir11' },
          ],
        },
        {
          label: 'Exploration Urbaine',
          avantages: [
            { id: 87, label: 'Circuit d\'exploration urbaine', icon:'loisir11'},
            { id: 88, label: 'Atelier de street art',icon:'loisir11' },
            { id: 89, label: 'Zone de parkour',icon:'loisir11' },
          ],
        },
        {
          label: 'Camping et Survie',
          avantages: [
            { id: 90, label: 'Site de camping sauvage', icon:'loisir11'},
            { id: 91, label: 'Zone de formation à la survie', icon:'loisir11'},
            { id: 92, label: 'Aire de bivouac et feux de camp', icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Histoire et culture',
      options: [
        {
          label: 'Cours',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 15, label: 'Bibliothèque',icon:'loisir11' }, // Bibliothèque
            { id: 32, label: 'Laboratoire',icon:'loisir11' }, // Laboratoire
          ],
        },
        {
          label: 'Sorties culturelles',
          avantages: [
            { id: 81, label: 'Centre d’accueil', icon:'loisir11'},
            { id: 20, label: 'Auditorium', icon:'loisir11'}, // Auditorium
            { id: 11, label: 'Espace d\'exposition',icon:'loisir11' }, // Salle d'exposition
          ],
        },
        {
          label: 'Ateliers Culturels',
          avantages: [
            { id: 82, label: 'Atelier d\'arts traditionnels',icon:'loisir11' },
            { id: 25, label: 'Salle de cours',icon:'loisir11'}, // Salle de cours
            { id: 83, label: 'Studio artistique et culturelle',icon:'loisir11'},
          ],
        },
      ],
    },
    {
      activity: 'Apprentissage',
      options: [
        {
          label: 'Soutien Scolaire',
          avantages: [
            { id: 84, label: 'Salle d’étude', icon:'loisir11'},
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
            { id: 42, label: 'Salle informatique',icon:'loisir11' }, // Salle informatique
            { id: 15, label: 'Bibliothèque', icon:'loisir11'}, // Bibliothèque
          ],
        },
        {
          label: 'Préparations aux Examens',
          avantages: [
            { id: 25, label: 'Salle de cours',icon:'loisir11' }, // Salle de cours
            { id: 85, label: 'Espace de travail collaboratif', icon:'loisir11'},
            { id: 80, label: 'Salle multimédia', icon:'loisir11'}, // Nouvel id attribué ici pour la salle multimédia
            { id: 15, label: 'Bibliothèque',icon:'loisir11' }, // Bibliothèque
          ],
        },
        {
          label: 'Méthodologie',
          avantages: [
            { id: 86, label: 'Atelier de méthodes d\'étude', icon:'loisir11'},
            { id: 87, label: 'Studio de compétences en communication',icon:'loisir11' },
            { id: 88, label: 'Espace de conseil',icon:'loisir11' },
          ],
        },
        {
          label: 'Softskills',
          avantages: [
            { id: 25, label: 'Salle de cours', icon:'loisir11'}, // Salle de cours
            { id: 19, label: 'Studio d\'enregistrement',icon:'loisir11' }, // Studio d’enregistrement
            { id: 89, label: 'Zone de projet',icon:'loisir11' },
            { id: 15, label: 'Bibliothèque',icon:'loisir11' }, // Bibliothèque
          ],
        },
      ],
    },
  ]
  
  const avantages1 = [
    {
      activity: 'Centre de loisirs',
      options: [
        {
          label: 'Espace intérieur',
          avantages: [
            { id: 18, label: 'Salle polyvalente', icon: 'loisir11' },
            { id: 19, label: 'Bibliothèque', icon: 'loisir11' },
          ],
        },
        {
          label: 'Espace extérieur',
          avantages: [
            { id: 20, label: 'Terrain de jeu', icon: 'loisir11' },
            { id: 21, label: 'Piscine', icon: 'loisir11' },
          ],
        },
        {
          label: 'Matériel',
          avantages: [
            { id: 22, label: 'Jeux de société', icon: 'loisir11' },
            { id: 23, label: 'Matériel artistique', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Structure similaire pour 'Ateliers créatifs', déjà existante
    {
      activity: 'Cours de musique',
      options: [
        {
          label: 'Espace intérieur',
          avantages: [
            { id: 29, label: 'Salle de répétition', icon: 'loisir11' },
            { id: 30, label: 'Studio d enregistrement', icon: 'loisir11' },
          ],
        },
        {
          label: 'Instruments',
          avantages: [
            { id: 31, label: 'Pianos', icon: 'loisir11' },
            { id: 32, label: 'Guitares', icon: 'loisir11' },
          ],
        },
        {
          label: 'Matériel audio',
          avantages: [
            { id: 33, label: 'Microphones', icon: 'loisir11' },
            { id: 34, label: 'Enceintes', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Ajoutez les avantages pour les autres activités de manière similaire...
    {
      activity: 'Sciences découverte',
      options: [
        {
          label: 'Laboratoire',
          avantages: [
            { id: 35, label: 'Microscopes', icon: 'loisir11' },
            { id: 36, label: 'Équipement de chimie', icon: 'loisir11' },
          ],
        },
        {
          label: 'Bibliothèque scientifique',
          avantages: [
            { id: 37, label: 'Livres de science', icon: 'loisir11' },
            { id: 38, label: 'Ressources numériques', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Technologies'
    {
      activity: 'Technologies',
      options: [
        {
          label: 'Matériel informatique',
          avantages: [
            { id: 39, label: 'Ordinateurs', icon: 'loisir11' },
            { id: 40, label: 'Tablettes', icon: 'loisir11' },
          ],
        },
        {
          label: 'Logiciels et applications',
          avantages: [
            { id: 41, label: 'Programmes de codage', icon: 'loisir11' },
            { id: 42, label: 'Applications éducatives', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Langues étrangères'
    {
      activity: 'Langues étrangères',
      options: [
        {
          label: 'Matériel didactique',
          avantages: [
            { id: 43, label: 'Manuels de langue', icon: 'loisir11' },
            { id: 44, label: 'Ressources audio', icon: 'loisir11' },
          ],
        },
        {
          label: 'Echange culturel',
          avantages: [
            { id: 45, label: 'Programmes d\'échange', icon: 'loisir11' },
            { id: 46, label: 'Evénements multiculturels', icon: 'loisir11' },
          ],
        },
      ],
    },
    {
      activity: 'Arts du spectacle',
      options: [
        {
          label: 'Installations de scène',
          avantages: [
            { id: 47, label: 'Scène théâtrale', icon: 'loisir11' },
            { id: 48, label: 'Éclairage de scène', icon: 'loisir11' },
          ],
        },
        {
          label: 'Accessoires et costumes',
          avantages: [
            { id: 49, label: 'Garde-robe de costumes', icon: 'loisir11' },
            { id: 50, label: 'Accessoires de scène', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Clubs d'intérêts'
    {
      activity: 'Clubs d\'intérêts',
      options: [
        {
          label: 'Clubs variés',
          avantages: [
            { id: 51, label: 'Club de lecture', icon: 'loisir11' },
            { id: 52, label: 'Club d\'astronomie', icon: 'loisir11' },
          ],
        },
        {
          label: 'Équipement pour clubs',
          avantages: [
            { id: 53, label: 'Télescopes pour l\'astronomie', icon: 'loisir11' },
            { id: 54, label: 'Matériel d\'art pour club de peinture', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Environnement'
    {
      activity: 'Environnement',
      options: [
        {
          label: 'Activités en plein air',
          avantages: [
            { id: 55, label: 'Jardins éducatifs', icon: 'loisir11' },
            { id: 56, label: 'Parcours nature', icon: 'loisir11' },
          ],
        },
        {
          label: 'Équipement de terrain',
          avantages: [
            { id: 57, label: 'Matériel d\'observation', icon: 'loisir11' },
            { id: 58, label: 'Kits de jardinage', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Arts martiaux'
    {
      activity: 'Arts martiaux',
      options: [
        {
          label: 'Installations',
          avantages: [
            { id: 59, label: 'Dojo', icon: 'loisir11' },
            { id: 60, label: 'Ring de boxe', icon: 'loisir11' },
          ],
        },
        {
          label: 'Équipement',
          avantages: [
            { id: 61, label: 'Gants de boxe', icon: 'loisir11' },
            { id: 62, label: 'Kimono', icon: 'loisir11' },
          ],
        },
      ],
    },
    {
      activity: 'Histoire et culture',
      options: [
        {
          label: 'Ressources éducatives',
          avantages: [
            { id: 63, label: 'Expositions culturelles', icon: 'loisir11' },
            { id: 64, label: 'Bibliothèque d\'histoire', icon: 'loisir11' },
          ],
        },
        {
          label: 'Activités interactives',
          avantages: [
            { id: 65, label: 'Ateliers d\'histoire', icon: 'loisir11' },
            { id: 66, label: 'Jeux éducatifs', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Aide aux devoirs'
    {
      activity: 'Aide aux devoirs',
      options: [
        {
          label: 'Soutien scolaire',
          avantages: [
            { id: 67, label: 'Tuteurs qualifiés', icon: 'loisir11' },
            { id: 68, label: 'Salles d\'étude', icon: 'loisir11' },
          ],
        },
        {
          label: 'Ressources d\'apprentissage',
          avantages: [
            { id: 69, label: 'Matériel didactique', icon: 'loisir11' },
            { id: 70, label: 'Ordinateurs pour recherche', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Yoga et bien-être'
    {
      activity: 'Yoga et bien-être',
      options: [
        {
          label: 'Installations',
          avantages: [
            { id: 71, label: 'Studio de yoga', icon: 'loisir11' },
            { id: 72, label: 'Espace méditation', icon: 'loisir11' },
          ],
        },
        {
          label: 'Équipement',
          avantages: [
            { id: 73, label: 'Tapis de yoga', icon: 'loisir11' },
            { id: 74, label: 'Accessoires de relaxation', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Astronomie'
    {
      activity: 'Astronomie',
      options: [
        {
          label: 'Observation',
          avantages: [
            { id: 75, label: 'Télescope', icon: 'loisir11' },
            { id: 76, label: 'Planétarium', icon: 'loisir11' },
          ],
        },
        {
          label: 'Ressources éducatives',
          avantages: [
            { id: 77, label: 'Livres d\'astronomie', icon: 'loisir11' },
            { id: 78, label: 'Ateliers d\'astronomie', icon: 'loisir11' },
          ],
        },
      ],
    },
    // Avantages pour 'Echecs'
    {
      activity: 'Echecs',
      options: [
        {
          label: 'Matériel',
          avantages: [
            { id: 79, label: 'Échiquiers', icon: 'loisir11' },
            { id: 80, label: 'Horloges d\'échecs', icon: 'loisir11' },
          ],
        },
        {
          label: 'Formation et compétition',
          avantages: [
            { id: 81, label: 'Cours d\'échecs', icon: 'loisir11' },
            { id: 82, label: 'Tournois', icon: 'loisir11' },
          ],
        },
      ],
    },
  ];
  
  const handleSubmit = () => {
    const functions = getFunctions();
    const saveInscriptionData = httpsCallable(functions, 'saveInscriptionData');
    const changeOwner = httpsCallable(functions, 'changeOwner');
  
    const auth = getAuth();
    const user1 = auth.currentUser;
  
    if (!user1) {
      console.error("Utilisateur non authentifié");
      // Afficher un message d'erreur à l'utilisateur
      return;
    }else{
      console.error("Utilisateur authentifié : "+String(user1.uid));

    }
  
    const userId = user1.uid;
  
    const newSubmission = {
      typeAct: choice1,
      name: input2,
      address: input3,
      email: input4,
      phoneNumber: input5,
      category: selectedActivities,
      desc: input6,
      coverPicture: coverPic,
      ageRange: doubleSliderValue,
      advantages: selectedAvantages,
      missionDescription: input7,
      logo,
      note: 5,
      avis: [],
      price: [0],
      age: [3],
      userId: userId,
      team: [{id:userId,name:user1.displayName,email:user1.email,phone:user1.phoneNumber}],
      teamPrivilege: {
        0:'Admin',
        1:'Sous-Admin',
        2:'Professeur',
        3:'Secrétariat',
      },

    };
  
    saveInscriptionData(newSubmission)
      .then((result) => {
        console.log(result.data.message);
  
      })
      .catch((error) => {
        console.error("Erreur lors de l'enregistrement des données", error);
        // Afficher un message d'erreur à l'utilisateur
      });
  };
  


  useEffect(() => {
   console.log('selectedAct:'+String(selectedActivities))
  }, [selectedActivities]);
  /*useEffect(() => {
    console.log("Selected Activities: ", String(selectedActivities));
    console.log("Avantages: ",Object( avantages));
      console.log("filteredAvantagesWithLabels: ", filteredAvantagesWithLabels)
  }, [step]);*/


  const getFilteredAvantagesWithLabels = (selectedActivities, avantages) => {
    if (!selectedActivities || !avantages) {
      console.log("selectedActivities or avantages is undefined or null");
      return [];
    }
  
    console.log("selectedActivities: ", selectedActivities);
    console.log("avantages: ", avantages);
  
    const activitiesArray = typeof selectedActivities === 'string' 
      ? selectedActivities.split(',').map(activity => activity.trim())
      : selectedActivities;
    
    const filtered = avantages
      .filter(avantage => activitiesArray.includes(avantage.activity))
      .flatMap(avantage => avantage.options.map(option => ({
        optionLabel: option.label,
        avantages: option.avantages
      })));
  
    console.log("Filtered avantages: ", filtered);
    return filtered;
  };
  
  
  // Puis, utilisez cette fonction pour obtenir vos données filtrées
  const filteredAvantagesWithLabels = getFilteredAvantagesWithLabels(selectedActivities, avantages);
  
  

  const handleSelectAddress = (data, details = null) => {
    // 'details' est fourni lorsque fetchDetails = true
    console.log(data, details);
    setInput3(details);
    if (details && details.geometry) {
        setLocation({
            lat: details.geometry.location.lat,
            lng: details.geometry.location.lng
        });
    }
};
  const handleSelectActivity = (activityLabel) => {
    setSelectedActivities((prevActivities) => {
      const isAlreadySelected = prevActivities.includes(activityLabel);
      if (isAlreadySelected) {
        // Enlever l'élément s'il est déjà dans le tableau
        return prevActivities.filter((label) => label !== activityLabel);
      } else {
        // Ajouter à la fin du tableau
        if (prevActivities.length<4) {
          return [...prevActivities, activityLabel];
        }else {

          return prevActivities
        }
      }
    });
  };
  const handleSelectAvantages = (avantageLabel) => {
    setSelectedAvantages((prevAvantage) => {
      const isAlreadySelected = prevAvantage.includes(avantageLabel);
      if (isAlreadySelected) {
        // Enlever l'élément s'il est déjà dans le tableau
        return prevAvantage.filter((label) => label !== avantageLabel);
      } else {
        // Ajouter à la fin du tableau
        return [...prevAvantage, avantageLabel];
      }
    });
  };
  

  const [region, setRegion] = useState(null);

  const mapRef = useRef(null);
// Fonction pour créer un objet animé pour chaque activité
const useAnimatedValue = (initialValue) => {
  return useRef(new Animated.Value(initialValue)).current;
};

const PriorityList = ({ selectedActivities }) => {
  const fadeAnims = useRef(selectedActivities.reduce((acc, activity) => {
    acc[activity] = new Animated.Value(0); // Initialisé à 0 pour fade in
    return acc;
  }, {})).current;

  useEffect(() => {
    // Animer chaque activité pour fade in si elle n'est pas déjà animée
    selectedActivities.forEach((activity) => {
      Animated.timing(fadeAnims[activity], {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    });
    // Animer chaque activité pour fade out si elle a été supprimée
    Object.keys(fadeAnims).forEach((activity) => {
      if (!selectedActivities.includes(activity)) {
        Animated.timing(fadeAnims[activity], {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }).start(() => {
          // Supprimer l'animation de la liste après qu'elle soit terminée
          delete fadeAnims[activity];
        });
      }
    });
  }, [selectedActivities]);

  return (
    <View style={styles.priorityListContainer}>
      {selectedActivities.map((activity, index) => {
        return (
          <Animated.View
            key={activity}
            style={[
              styles.priorityItem,
              {
                opacity: fadeAnims[activity],
                transform: [
                  {
                    translateY: fadeAnims[activity].interpolate({
                      inputRange: [0, 1],
                      outputRange: [20, 0],
                    }),
                  },
                ],
              },
            ]}
          >
              {index === 0 ?
            <View style={{width:'100%',height:'7vh',minHeight:20,flexDirection:'row',marginBottom:10}}>
                <View style={{flexDirection:'row',width:'100%',height:'100%',borderRadius:10,backgroundColor:'rgba(111, 44, 247,1)'}}>
                  <View style={{width:'30%',height:'100%',borderRightWidth:1,borderColor:'#ccc',justifyContent:'center',alignItems:'center'}}>
                    <Text style={{color:'white',fontWeight:'bold',padding:10}}>1</Text>
                  </View>
                  <View style={{width:'70%',height:'100%',justifyContent:'center',alignItems:'center'}}>
                    <Text style={{color:'white',paddingTop:10,textAlign:'center',fontSize:10}}>Catégorie principale</Text>
                    <Text style={{color:'white',fontWeight:'bold',paddingBottom:10,textAlign:'center'}}>{activity}</Text>
                  </View>
                </View>
                </View>
                :
                <></>
              }
              {index != 0 ?
            <View style={{width:'100%',height:'5vh',minHeight:20,flexDirection:'row',marginBottom:10}}>
                <View style={{flexDirection:'row',width:'100%',height:'100%',borderRadius:10,backgroundColor:'rgba(111, 44, 247,1)'}}>
                  <View style={{width:'30%',height:'100%',borderRightWidth:1,borderColor:'#ccc',justifyContent:'center',alignItems:'center'}}>
                    <Text style={{color:'white',fontWeight:'bold',padding:10}}>{index+1}</Text>
                  </View>
                  <View style={{width:'70%',height:'100%',justifyContent:'center',alignItems:'center'}}>
                    <Text style={{color:'white',fontWeight:'bold',padding:10,textAlign:'center'}}>{activity}</Text>
                  </View>
                </View>
                </View>
                :
                <></>
              }
              
              
              
          </Animated.View>
        );
      })}
    </View>
  );
};



// Continuez pour 'Sciences découverte', 'Technologies', 'Langues étrangères', etc.

  const activities = [
    { id: 1, label: 'Espaces de Loisirs',icon: 'loisir11' },
    { id: 2, label: 'Ateliers créatifs',icon: 'brush'},
    { id: 3, label: 'Activités sportives',icon: 'trophy' },
    { id: 4, label: 'Musique',icon: 'music'},
    { id: 5, label: 'Sciences',icon: 'theatre'  },
    { id: 6, label: 'Technologies',icon: 'tech' },
    { id: 7, label: 'Langues étrangères',icon: 'science'  },
    { id: 8, label: 'Arts du spectacle',icon: 'langue'  },
    { id: 9, label: "Clubs d'intérêts",icon: 'tree'  },
    { id: 10, label: 'Environnement',icon: 'hist' },
    { id: 11, label: 'Histoire et culture',icon: 'hist' },
    { id: 12, label: 'Apprentissage',icon: 'devoir'  },
    { id: 13, label: 'Bien-être',icon: 'yoga' },
    { id: 14, label: 'Aventure',icon: 'chess'  },
    { id: 15, label: 'Cuisine',icon: 'chess'  },
    // ... Ajoutez le reste des activités ici
];

  const loadingFallback = useMemo(() => {
    return (
      <View>
        <Text>Loading</Text>
      </View>
    );
  }, []);

  const fileInputRef = useRef(null); // Création de la référence
  const handleDragOver = useCallback((event) => {
    event.preventDefault(); // Empêcher le comportement par défaut
  }, []);

const handleDropLogo = useCallback((event) => {
  event.preventDefault(); // Empêcher le comportement par défaut du navigateur
  event.stopPropagation(); // Empêcher la propagation de l'événement
  const file = event.dataTransfer.files[0];
  processFileLogo(file);
}, []);
const handleDropCoverPic = useCallback((event) => {
  event.preventDefault(); // Empêcher le comportement par défaut du navigateur
  event.stopPropagation(); // Empêcher la propagation de l'événement
  const file = event.dataTransfer.files[0];
  processFileCoverPic(file);
}, []);
const onDropLogo = useCallback(acceptedFiles => {
    // Do something with the files
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      setLogo(e.target.result);
    };
    reader.readAsDataURL(file);
  }, []);
  const onDropCoverPic = useCallback(acceptedFiles => {
      // Do something with the files
      const file = acceptedFiles[0];
  
      const reader = new FileReader();
      reader.onload = (e) => {
        setCoverPic(e.target.result);
      };
      reader.readAsDataURL(file);
    }, []);
  
    const { getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo } = useDropzone({ onDrop: onDropLogo });
    const { getRootProps: getRootPropsCoverPic, getInputProps: getInputPropsCoverPic } = useDropzone({ onDrop: onDropCoverPic });

  const processFileLogo = (file) => {
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const reader = new FileReader();
      reader.onload = (e) => setLogo(e.target.result);
      reader.readAsDataURL(file);
    }
  };
  const processFileCoverPic = (file) => {
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const reader = new FileReader();
      reader.onload = (e) => setCoverPic(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };
  const handleSelect = (value) => {
    setInput3(value);
    // Vous pouvez également récupérer plus d'informations sur l'adresse sélectionnée si nécessaire
  };
  const InscContinue = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (choice1 !== null && choice1 !== undefined) {
        setStep(3);
      } else {
        console.log('Step 2: choice1 is not selected or undefined');
        alert('Please select a choice.');
      }
    } else if (step === 3) {
      if (input2 !== '' && input2 !== undefined) {
        setStep(4);
      } else {
        console.log(`Step 3: input2 is ${input2}`);
        alert('Please fill in the required field.');
      }
    } else if (step === 4) {
      if (input3 !== '' && input3 !== undefined) {
        setStep(5);
      } else {
        console.log(`Step 4: input3 is ${input3}`);
        alert('Please fill in the required field.');
      }
    } else if (step === 5) {
      if (input4 !== '' && input4 !== undefined && input5 !== '' && input5 !== undefined) {
        setStep(6);
      } else {
        console.log(`Step 5: input4 is ${input4}, input5 is ${input5}`);
        alert('Please fill in all required fields.');
      }
    } else if (step === 6) {
      if (input6 !== '' && input6 !== undefined) {
        setStep(7);
      } else {
        console.log(`Step 6: input6 is ${input6}`);
        alert('Please fill in the required field.');
      }
    } else if (step === 7) {
      setStep(8);
    } else if (step === 8) {
      if (selectedActivities.length > 0) {
        setStep(9);
      } else {
        console.log('Step 8: No activities selected');
        alert('Please select at least one activity.');
      }
    } else if (step === 9) {
      setStep(10);
    } else if (step === 10) {
      if (input7 !== '' && input7 !== undefined) {
        setStep(11);
      } else {
        console.log(`Step 10: input7 is ${input7}`);
        alert('Please fill in the required field.');
      }
    } else if (step === 11) {
      if (logo !== null && logo !== undefined) {
        setStep(12);
      } else {
        console.log('Step 11: Logo not uploaded or undefined');
        alert('Please upload a logo.');
      }
    } else if (step === 12) {
      if (coverPic !== null && coverPic !== undefined) {
        setStep(13);
      } else {
        console.log('Step 12: Cover picture not uploaded or undefined');
        alert('Please upload a cover picture.');
      }
    } else if (step === 13) {
      setStep(14);
    } else if (step === 14) {
      setStep(15);
    } else if (step === 15) {
      setStep(16);
    }
  };
  
  
  

  const InscBack = () => {
    if (step == 1){
      setStep(0)
    }
    
    if (step == 2){
        setStep(1)
    }
    if (step == 3){
        setStep(2)
    }
    if (step == 4){
        setStep(3) 
    }
    if (step == 5){
        setStep(4) 
    }
    if (step == 6){
        setStep(5) 
    }
    if (step == 7){
      setStep(6)
    }
    if (step == 8){
          setStep(7) 
    }
    if (step == 9){
        setStep(8)
    }
    if (step == 10){
          setStep(9) 
    }
    if (step == 11){
        setStep(10)
    }
    if (step == 12){
        setStep(11)
    }
    if (step == 13){
        setStep(12)
    }
    if (step == 14){
        setStep(13)
    }
    if (step == 15){
        setStep(14)
    }
  }

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("Utilisateur connecté:", user);
          // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
        } else {
          console.log("Utilisateur déconnecté");
          navigation.navigate('Login')
          // Mettez à jour votre état en conséquence
        }
      });
    
      return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
    }, [auth]);
    return (
      <View style={{ alignItems: 'center', justifyContent: 'space-between',backgroundColor:'white',height:'100vh'}}>
        
        <NavBarWS navigation={navigation} selectedButton={selectedButton} setSelectedButton={setSelectedButton} searchActivity={searchActivity} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        
        <View style={{width:'100vw',height:'80vh',overflow:'hidden'}}>
        {
          step === 0 ?
          <View style={{flex: 1,flexDirection:'row',width:'100%',alignContent:'center'}}>
            <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
              <Text style={{...styles.textH2,width:'60%'}}>Inscrire son organisation sur Lumiscool, c’est facile</Text>
            </View>
            <View style={{flex:1,justifyContent:'center',alignItems:'center',padding:50}}>
                <View style={{flexDirection:'row',borderBottomWidth:1,borderColor:'#E1E1E1',marginTop:20}}>
                  <View style={{padding:10}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>1 </Text>
                  </View>
                  <View style={{padding:10,width:'20vw'}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>Parlez-nous de votre organisation </Text>
                    <Text style={{...styles.textH2normal,fontSize:15,marginTop:10}}>Introduisez-nous à votre structure, sa mission et ses coordonnées essentielles pour une meilleure visibilité auprès des parents. </Text>
                  </View>
                  <View style={{}}>
                    <CustomImage source={require('../assets/inscImg1.png')} style={{ width : 201,height: 199}}/>
                  </View>
                </View>
                <View style={{flexDirection:'row',borderBottomWidth:1,borderColor:'#E1E1E1'}}>
                  <View style={{padding:10}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>2 </Text>
                  </View>
                  <View style={{padding:10,width:'20vw'}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>Expliquez-nous vos activités </Text>
                    <Text style={{...styles.textH2normal,fontSize:15,color:'#A1A1A1',marginTop:10}}>Détaillez les activités que vous proposez, leurs spécificités et pour quelles tranches d'âge elles sont adaptées, afin que les parents puissent faire un choix éclairé. </Text>
                  </View>
                  <View style={{}}>
                    <CustomImage source={require('../assets/inscImg2.png')} style={{ width : 201,height: 199}}/>
                  </View>
                </View>
                <View style={{flexDirection:'row',borderBottomWidth:1,borderColor:'#E1E1E1'}}>
                  <View style={{padding:10}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>3 </Text>
                  </View>
                  <View style={{padding:10,width:'20vw'}}>
                    <Text style={{...styles.textH2,fontSize:20,marginTop:20}}>Finalisation </Text>
                    <Text style={{...styles.textH2normal,fontSize:15,color:'#A1A1A1',marginTop:10}}>Votre page est maintenant prête à être crée. Visualisez votre page et cliquez sur finaliser votre inscription afin de tirer le meilleur parti de nos services</Text>
                  </View>
                  <View style={{}}>
                    <CustomImage source={require('../assets/inscImg3.png')} style={{ width : 201,height: 199}}/>
                  </View>
                </View>
            </View>
          </View>

          :
          <View></View>
        }
        {
          step === 1 ?
          
          <View style={{flexDirection:'row',width:'100%',height:'100%'}}>
            <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
              <View style={{width:'30vw'}}>
                <Text style={{...styles.textH4,paddingBottom:10}}>Etape 1 </Text>
                <Text style={{...styles.textH2,wordSpacing:3}}>Parlez-nous de votre organisation </Text>
                <Text style={{...styles.textH2normal,fontSize:18,color:'#B1B1B1',marginTop:20}}>Au cours de cette étape, nous souhaitons en savoir plus sur votre organisation. Il s'agira de nous préciser la nature de votre structure (centre aéré, association, entreprise, etc.) et sa mission principale. Nous vous demanderons ensuite de fournir des informations clés comme votre localisation ou encore l'âge des enfants que vous pouvez accueillir. </Text>
              </View>
            </View>
            <View style={{flex:1,height:'100%'}}>
              <CustomImage source={require('../assets/inscImg4.png')} style={{minWidth:'40vw',height:'100%'}}/>
            </View>
          </View>
          
          :
          <View></View>
        }
        {
          step === 2 ?
          
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text style={{...styles.textH2,padding:40,paddingBottom:25,textAlign:'center',width:'50vw'}}>Parmi les propositions suivantes, laquelle décrit le mieux votre organisme ? </Text>
            <ScrollView style={{width:'100%'}}>
              <View style={{width:'100%',alignItems:'center',justifyContent:'center'}}>
            {
              choice1 === 0 
              ?
              <TouchableOpacity onPress={()=> setChoice1(null)} style={styles.buttonChoice1selected}>
                
                <Buildings size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Organisations publiques </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous êtes financé et géré par une entité gouvernementale (centre aéré municipal, école municipale de danse). </Text>
              </TouchableOpacity>
              :
              <TouchableOpacity onPress={()=>setChoice1(0)} style={styles.buttonChoice1}>
                <Buildings size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Organisations publiques </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous êtes financé et géré par une entité gouvernementale (centre aéré municipal, école municipale de danse). </Text>
              </TouchableOpacity>
            }
            
            {
              choice1 === 1 
              ?
              <TouchableOpacity onPress={()=> setChoice1(null)} style={styles.buttonChoice1selected}>
                <Building3 size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Associations à but non lucratif </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous fonctionnez principalement grâce à des subventions, des dons et des cotisations (club sportif associatif, atelier artistique communautaire).</Text>
              </TouchableOpacity>
              :
              <TouchableOpacity onPress={()=>setChoice1(1)} style={styles.buttonChoice1}>
                <Building3 size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Associations à but non lucratif </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous fonctionnez principalement grâce à des subventions, des dons et des cotisations (club sportif associatif, atelier artistique communautaire).</Text>
              </TouchableOpacity>
            }
          {
              choice1 === 2 
              ?
              <TouchableOpacity onPress={()=> setChoice1(null)} style={styles.buttonChoice1selected}>
                <Building size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Entreprises privées </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous opérez avec un objectif de profit à partir des frais des participants (école privée de musique, club de robotique commercial).</Text>
              </TouchableOpacity>
              :
              <TouchableOpacity onPress={()=>setChoice1(2)} style={styles.buttonChoice1}>
                <Building size={40} color={`#000000`}/> 
                <Text style={{...styles.textH3,padding:10,paddingLeft:0}}>Entreprises privées </Text>
                <Text style={{...styles.textH2normal,fontSize:15}}>Si vous opérez avec un objectif de profit à partir des frais des participants (école privée de musique, club de robotique commercial).</Text>
              </TouchableOpacity>
            }
            </View>
          </ScrollView>
          
          
          </View>
          
          :
          <View></View>
        }
        {
          step === 3 ?
          
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text style={{...styles.textH2,padding:50,width:'50vw',textAlign:'center'}}> Maintenant, entrez le nom officiel de votre organisation</Text>
            <TextInput 
                style={styles.input} 
                placeholder="Votre nom"
                value={input2}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={setInput2}
                />
          
          </View>
          
          :
          <View></View>
        }
        {
          step === 4 ?
          
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ ...styles.textH2, width: '50vw', paddingVertical: 10, paddingTop: 40, textAlign: 'center' }}>
        Continuons, entrez maintenant votre adresse
      </Text>
      <Text style={{ ...styles.textH2normal, width: '50vw', paddingVertical: 10, paddingBottom: 40, textAlign: 'center' }}>
        Indiquez l'adresse complète de votre organisation pour aider les parents à vous localiser.
      </Text>

      <GooglePlacesAutocomplete
        placeholder='Adresse'
        minLength={2}
        autoFocus={false}
        returnKeyType={'search'}
        listViewDisplayed={true}
        fetchDetails={true}
        renderDescription={row => row.description}
        onPress={handleSelectAddress}
        getDefaultValue={() => ''}
        requestUrl={{
          useOnPlatform: 'web',
          url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api',
        }}
        query={{
          key: 'AIzaSyBXP9mFixsN76mGW6_qNzo8kaeHj8iJXls',
          language: 'fr',
        }}
        styles={{
          listView: {
            color: 'black',
            zIndex: 16,
            position: 'absolute',
            width: '100%',
            borderRadius: 20,
            marginTop: 65,
            shadowColor: '#7E7E7E',
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 7,
          },
          textInput: {
            outlineStyle: 'none',
            marginLeft: 10,
            fontSize: 17,
            borderColor: '#757575',
            padding: 20,
            margin: 5,
            fontFamily: 'Avenir Next Regular',
            borderRadius: 10,
            borderWidth: 1,
            width: '30vw',
            height: 57,
          },
          predefinedPlacesDescription: {
            color: '#1faadb',
          },
        }}
        nearbyPlacesAPI='GooglePlacesSearch'
        filterReverseGeocodingByTypes={['locality', 'administrative_area_level_3']}
        debounce={200}
      />
      <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20, width: '100%', zIndex: -1 }}>
        <div style={{ pointerEvents: 'none', width: '60%', height: '450px' }}>
          <iframe
            width="100%"
            height="100%"
            style={{ borderRadius: 30, border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBXP9mFixsN76mGW6_qNzo8kaeHj8iJXls&q=${location.lat},${location.lng}`}
            allowFullScreen
          ></iframe>
        </div>
      </View>
    </View>
          
          :
          <View></View>
        }
        {
          step === 5 ?
          
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text style={{...styles.textH2,width:'35vw',textAlign:'center',padding:10}}>À présent, ajoutez vos contacts</Text>
            <Text style={{...styles.textH2normal,width:'35vw',marginBottom:30,textAlign:'center'}}>Entrez un numéro une adresse email de contact pour les demandes urgentes ou les clarifications.</Text>
            <TextInput 
                style={styles.input} 
                placeholder="Email"
                autoComplete="email"
                value={input4}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={handleEmailChange}
                />
              <TextInput 
                style={styles.input} 
                placeholder="Téléphone"
                value={input5}
                keyboardType={'phone-pad'}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChange={handlePhoneNumberChange}
                maxLength={10} 
                />
            
          </View>
          
          :
          <View></View>
        }
        {
          step === 6 ?
          
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text style={{...styles.textH2,width:'35vw',textAlign:'center',padding:20}}>Enfin, décrivez en quelques mots votre organisation</Text>
            <Text style={{...styles.textH2normal,width:'35vw',marginBottom:30,textAlign:'center'}}>En quelques phrases, présentez votre organisation aux parents.</Text>
            <TextInput 
                style={{...styles.input,height:'10vh'}} 
                placeholder="Entrez une courte description..."
                multiline = {true}
                value={input6}
                maxLength={300}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={setInput6}
                />
            
          </View>
          
          :
          <View></View>
        }
        
        {
          step === 7 ?
          
          <View style={{flexDirection:'row',width:'100%',height:'100%'}}>
            <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
              <View style={{width:'30vw'}}>
                <Text style={{...styles.textH4,paddingBottom:10}}>Etape 2 </Text>
                <Text style={{...styles.textH2,wordSpacing:3}}>Expliquez-nous vos activités </Text>
                <Text style={{...styles.textH2normal,fontSize:18,color:'#B1B1B1',marginTop:20}}>Durant cette étape, décrivez vos activités, précisez pour quelles tranches d'âge elles sont destinées et catégorisez-les (artistique, sportive, éducative, etc.). Une gestion plus détaillée sera possible via votre tableau de bord ultérieurement.</Text>
              </View>
            </View>
            <View style={{flex:1,height:'100%'}}>
              <CustomImage source={require('../assets/inscImg4.png')} style={{minWidth:'40vw',height:'100%'}}/>
            </View>
          </View>
          
          :
          <View></View>
        }
        
        {
          step === 8 ?
        <View style={{flex:1,flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%'}}>
          
        <View style={{height:'100%',width:'70%',overflow:'hidden',justifyContent:'center',alignContent:'center'}}>
          <Text style={{...styles.textH2,width:'70%',alignSelf:'center',textAlign: 'center',paddingVertical:20,right:28}}>Parmi les propositions suivantes, choisissez une ou plusieurs catégories</Text>
          <View style={{flex:1,width:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:40}}>
            <FlatList
              data={activities}
              numColumns={3}
              style={{width:'70%'}}
              showsHorizontalScrollIndicator={false}
              keyExtractor={item => item.id.toString()}
              renderItem={({ item: activity }) => (
      
              <TouchableOpacity 
                style={selectedActivities.includes(activity.label) ? styles.buttonCatSelected : styles.buttonCat}
                onPress={() => handleSelectActivity(activity.label)}
              >
                {
                selectedActivities.includes(activity.label) ? 
                <CustomImage 
                  source={require(`../assets/iconWhite/${activity.icon}.png`)} 
                  style={styles.iconCat} 
                  resizeMode="contain"
                />
                : 
                <CustomImage 
                  source={require(`../assets/icon/${activity.icon}.png`)} 
                  style={styles.iconCat} 
                  resizeMode="contain"
                />
                }
                <Text style={selectedActivities.includes(activity.label) ? styles.TextFilterSelected : styles.TextFilter}>{activity.label}</Text>
              </TouchableOpacity>
            )}/>
          </View>
        </View>
        <View style={{width:'30%',height:'100%',alignContent:'center',padding:20}}>
               <Text style={{...styles.textH2,textAlign: 'center',padding:20,paddingTop:0}}> Liste de priorité:</Text>
               <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
               <PriorityList selectedActivities={selectedActivities} />
  </View>
        </View>
            
        </View>
          
          :
          <View></View>
        }
        {
          step === 9 ?
          
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              
        
              <View style={{width:'60%',height:'100%',overflow:'hidden',justifyContent:'center',alignContent:'center'}}>
          <Text style={{...styles.textH2,width:'80%',alignSelf:'center',textAlign: 'center',paddingVertical:20}}>Selectionnez les avantages de votre organisation</Text>
          <Text style={{...styles.textH2normal,textAlign:'center',marginBottom:15}}>Choisissez les installations que vous possédez pour les enfants.</Text>
          <ScrollView style={{ /* Votre style ici */ }}>
  {filteredAvantagesWithLabels.map((group, index) => (
    <View key={index}>
      <Text style={styles.textH2normal}>{group.optionLabel}</Text>
      <View style={{flexDirection:'row'}}>
      {group.avantages.map(avantage => (
        <TouchableOpacity 
          key={avantage.id}
          style={selectedAvantages.includes(avantage.label) ? styles.buttonCatSelectedAV : styles.buttonCatAV}
          onPress={() => handleSelectAvantages(avantage.label)}
        >
          <Text style={selectedAvantages.includes(avantage.label) ? styles.TextFilterSelectedAV : styles.TextFilterAV}>{avantage.label}</Text>
        </TouchableOpacity>
      ))}
      </View>
    </View>
  ))}
</ScrollView>
        </View>
            </View>
          
          :
          <View></View>
        }
        {
          step === 10 ?
          
          <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text style={{...styles.textH2,width:'35vw',textAlign:'center',padding:20}}>Ensuite, résumez la mission de votre organisation</Text>
            <Text style={{...styles.textH2normal,width:'35vw',marginBottom:30,textAlign:'center'}}>En quelques lignes, expliquez aux parents la mission de vos activités.</Text>
            <TextInput 
                style={{...styles.input,height:'10vh'}} 
                placeholder="Décrivez votre mission..."
                multiline = {true}
                value={input7}
                maxLength={500}
                //selectionColor="rgba(255, 255, 255, 0)" IOS
                //underlineColorAndroid="transparent" ANDROID
                onChangeText={setInput7}
                />
            
          </View>
          
          :
          <View></View>
        }
        {
          step === 11 ?
          
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ ...styles.textH2, width: '55vw', minWidth: 200,textAlign:'center' }}>Pour illustrer, choisissez votre Logo</Text>
      <TouchableOpacity 
        style={{ overflow: 'hidden', borderWidth: 1, borderStyle: 'dashed',  width: 200, height: 200 , borderRadius: 20, justifyContent: 'center', alignItems: 'center', marginTop: 20 }}
       
  onDragOver={handleDragOver}
  onDrop={handleDropLogo}
        onPress={() => fileInputRef.current.click()} // Déclenchement du clic sur l'input
      >
        
      <div {...getRootPropsLogo()} style={{justifyContent:'space-between',alignItems:'center'}}>
        <input {...getInputPropsLogo()} />
        {logo ? (
          <CustomImage source={{ uri: logo }} style={{ width: 200, height: 200 ,resizeMode: 'cover'}} />
          
        ) : ( 
          <View style={{alignItems:'center',justifyContent:'center',alignContent:'center'}}>
          
            <CustomImage source={require('../assets/iconUploadImg.png')} style={{width:70, height:70}}/>
            <Text style={{ ...styles.textH2normal,fontSize:15 ,padding:10,textAlign:'center'}}>Faites glisser votre Logo ici ou cliquer pour télécharger depuis votre appareil</Text>
          </View>
        )}
        
      </div >
      </TouchableOpacity>
    </View>
          
          :
          <View></View>
        }{
          step === 12 ?
          
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ ...styles.textH2, width: '55vw', minWidth: 200,textAlign:'center' }}>Maintenant, sélectionnez votre photo de couverture</Text>
      <TouchableOpacity 
         style={{ overflow: 'hidden', borderWidth: 1, borderStyle: 'dashed',  width: 500, height: 500 , borderRadius: 20, justifyContent: 'center', alignItems: 'center', marginTop: 20 }}
       
  onDragOver={handleDragOver}
  onDrop={handleDropCoverPic}
        onPress={() => fileInputRef.current.click()} // Déclenchement du clic sur l'input
      >
        
      <div {...getRootPropsCoverPic()} style={{justifyContent:'space-between',alignItems:'center'}}>
        <input {...getInputPropsCoverPic()} />
        {coverPic ? (
          <CustomImage source={{ uri: coverPic }} style={{ width: 500, height: 500 ,resizeMode: 'cover'}} />
          
        ) : ( 
          <View style={{alignItems:'center',justifyContent:'center'}}>
          
            <CustomImage source={require('../assets/iconUploadImg.png')} style={{width:70, height:70}}/>
            <Text style={{ ...styles.textH2normal,fontSize:15 ,padding:10,textAlign:'center'}}>Faites glisser votre photo de couverture ici ou cliquer pour télécharger depuis votre appareil</Text>
          </View>
        )}
        
      </div >
      </TouchableOpacity>
    </View>
          
          :
          <View></View>
        }
        {
          step === 13 ?
          
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ ...styles.textH2, width: '35vw', minWidth: 200,textAlign:'center' }}>Choisissez pour quelles tranches d'âge vous avez des activités </Text>
              <View style={{width:'50vw'}}>
                <AgeRangePicker ageMin={doubleSliderValue[0]} ageMax={doubleSliderValue[1]} onChange={handleAgeRangeChange} />
              </View>
            </View>
          
          :
          <View></View>
        }
        {
          step === 14 ?
          
          <View style={{flexDirection:'row',width:'100%',height:'100%'}}>
            <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
              <View style={{width:'30vw'}}>
                <Text style={{...styles.textH4,paddingBottom:10}}>Etape 3 </Text>
                <Text style={{...styles.textH2,wordSpacing:3}}>Récapitulatif & Finalisation </Text>
                <Text style={{...styles.textH2normal,fontSize:18,color:'#B1B1B1',marginTop:20}}>Vérifiez que tout vous correspond bien.</Text>
              </View>
            </View>
            <View style={{flex:1,height:'100%'}}>
              <CustomImage source={require('../assets/inscImg4.png')} style={{minWidth:'40vw',height:'100%'}}/>
            </View>
          </View>
          
          :
          <View></View>
        }
        
        {
          step === 15 ? 
          <View style={{height:'100%',justifyContent:'center'}}>
          
          <View style={{ flexDirection: 'row',justifyContent:'center' }}>
              <View>
                  {coverPic ? 
                      <CustomImage source={{ uri: coverPic }} style={{ width: '40vw', aspectRatio: 1 / 1, borderRadius: 15 }}/>
                      :
                     null
                  }
              </View>
              <View style={{ padding: 20 }}>
              <View style={{ flexDirection: 'row', alignContent: 'center' }}>
              <View style={{ marginRight: 10 }}>
                  {logo ? 
                  <CustomImage source={{ uri: logo }} style={{ width: 150, aspectRatio: 1 / 1, borderRadius: 15 }}/>:
                  null
                }
              </View>
              <View style={{ justifyContent: 'center' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {
                      choice1 === 0 ?
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}>{'Organisations publiques'}</Text>
                      :
                      null
                    }
                    {
                      choice1 === 1 ?
                      <Text style={{ ...styles.textH2normal,fontSize:15}}>{'Associations à but non lucratif'}</Text>
                      :
                      null
                    }
                    {
                      choice1 === 2 ?
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}>{'Entreprises privées'}</Text>
                      :
                      null
                    }
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}> • </Text>
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}>{String(selectedActivities[0]) || 'Activité par défaut'}</Text>
                  </View>
                  <Text style={{ ...styles.textH1, fontSize: 30 }}>{String(input2) || 'Nom par défaut'}</Text>

                  <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                      <Star color={'#000000'} variant='Bold' size={15} style={{ marginRight: 5 }}/>
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}>{input5 ? String(input5) : '0612345678'} </Text>
                      <Text style={{ ...styles.textH2normal,fontSize:15}}> • </Text>
                      <Location color={'#000000'} variant='Bold' size={15} style={{ marginRight: 5 }}/>
                      <Text style={{ ...styles.textH2normal,fontSize:15 }}>{input3 ? truncateText(String(input3.formatted_address),40) : 'Adresse par défaut'}</Text>
                  </View>
              </View>
          </View>
                  <Text style={{ ...styles.textH3, marginTop: 20, fontWeight: 'bold', marginBottom: 10 }}>Courte Description</Text>
                  <View style={{width:'45vw',overflow:'hidden'}}>
                    <Text style={{...styles.textH2normal,fontSize:15  }}>{truncateText(String(input6)|| 'Description par défaut',250)}</Text>
                  </View>
                  <Text style={{ ...styles.textH3, marginTop: 20, fontWeight: 'bold', marginBottom: 10 }}>Votre Mission</Text>
                  <View style={{width:'45vw',overflow:'hidden'}}>
                    <Text style={{ ...styles.textH2normal,fontSize:15  }}>{truncateText(String(input7)|| 'Description par défaut',250)}</Text>
                  </View>
                  {/* Ajouter le bouton 'En savoir plus' et d'autres informations si nécessaire */}
              </View>
          </View>
          </View>

          : 
          null
        }
        
        </View>
        <View style={{width:'100%',alignContent:'center',justifyContent:'center',alignItems:'center'}}>
        <View style={{width:'90%',height:5,backgroundColor:'#DDDDDD',margin:10,overflow:'hidden',alignContent:'center'}}>
        {
          step === 0 ? <View/> : <View/>
        }
        {
          step === 1 ? <View style={{backgroundColor:'#6F2CF7',width:'5%',height:'100%'}}/> : <View/>
        }
        {
          step === 2 ? <View style={{backgroundColor:'#6F2CF7',width:'10%',height:'100%'}}/> : <View/>
        }
        {
          step === 3 ? <View style={{backgroundColor:'#6F2CF7',width:'15%',height:'100%'}}/> : <View/>
        }
        {
          step === 4 ? <View style={{backgroundColor:'#6F2CF7',width:'20%',height:'100%'}}/> : <View/>
        }
        {
          step === 5 ? <View style={{backgroundColor:'#6F2CF7',width:'30%',height:'100%'}}/> : <View/>
        }
        {
          step === 6 ? <View style={{backgroundColor:'#6F2CF7',width:'35%',height:'100%'}}/> : <View/>
        }
        {
          step === 7 ? <View style={{backgroundColor:'#6F2CF7',width:'40%',height:'100%'}}/> : <View/>
        }
        {
          step === 8 ? <View style={{backgroundColor:'#6F2CF7',width:'50%',height:'100%'}}/> : <View/>
        }
        {
          step === 9 ? <View style={{backgroundColor:'#6F2CF7',width:'55%',height:'100%'}}/> : <View/>
        }
        {
          step === 10 ? <View style={{backgroundColor:'#6F2CF7',width:'57%',height:'100%'}}/> : <View/>
        }
        {
          step === 11 ? <View style={{backgroundColor:'#6F2CF7',width:'60%',height:'100%'}}/> : <View/>
        }
        {
          step === 12 ? <View style={{backgroundColor:'#6F2CF7',width:'66%',height:'100%'}}/> : <View/>
        }
        {
          step === 13 ? <View style={{backgroundColor:'#6F2CF7',width:'72%',height:'100%'}}/> : <View/>
        }
        {
          step === 14 ? <View style={{backgroundColor:'#6F2CF7',width:'88%',height:'100%'}}/> : <View/>
        }
        {
          step === 15 ? <View style={{backgroundColor:'#6F2CF7',width:'100%',height:'100%'}}/> : <View/>
        }
        </View>
        {
         step === 15 ?
         <View style={{width:'100%', flexDirection:'row',justifyContent:'space-between',padding:10}}>
         <TouchableOpacity onPress={()=> InscBack ()} style={{borderRadius:50,backgroundColor:'#6F2CF7',padding:10,justifyContent:'center',alignItems:'center',width:100}}>
             <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}> Retour </Text>
         </TouchableOpacity>
           <TouchableOpacity onPress={()=> handleSubmit ()} style={{borderRadius:50,backgroundColor:'#6F2CF7',padding:10,justifyContent:'center',alignItems:'center',width:200}}>
               <Text style={{fontFamily:'Avenir Next Regular',color:'white',textAlign:'center'}}> Finir l'inscription </Text>
           </TouchableOpacity>
         </View>:
         
        <View style={{width:'100%', flexDirection:'row',justifyContent:'space-between',padding:10}}>
        <TouchableOpacity onPress={()=> InscBack ()} style={{borderRadius:50,backgroundColor:'#6F2CF7',padding:10,justifyContent:'center',alignItems:'center',width:100}}>
            <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}> Retour </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=> InscContinue()} style={{borderRadius:50,backgroundColor:'#6F2CF7',padding:10,justifyContent:'center',alignItems:'center',width:100}}>
            <Text style={{fontFamily:'Avenir Next Regular',color:'white'}}> Continuer </Text>
        </TouchableOpacity>
      </View>

        }
        </View>
        
      </View>
    );
  }

  const styles = StyleSheet.create({
    autocompleteContainer:{
      width:'100%',
      zIndex: 1,
  },
    textH1: {
        fontFamily:'Avenir Next Bold',
        fontSize:70
    },
    textH2: {
        fontFamily:'Avenir Next Bold',
        fontSize:30,
        fontWeight:500

    },
    textH2normal: {
        fontFamily:'Avenir Next Regular',
        fontSize:18,
        fontWeight:500,
        color:'#A1A1A1'

    },
    textH3: {
      fontFamily:'Avenir Next Bold',
      fontSize:20

    },
    input: {
        outlineStyle: 'none',
        marginLeft:10,
        borderColor: '#757575',
        padding: 20,
        margin: 5,
        width: 150,
        fontFamily: 'Avenir Next Regular',
        borderRadius: 10,
        borderWidth: 1 ,
        fontSize:17,
        width:'40vw'
    },
    textH4: { 
      fontFamily:'Avenir Next Bold',
      fontSize:22

    },
    textH4g: {
      fontFamily:'Avenir Next Regular',
      fontSize:15,
      color:'#7B7B7B'

    },
    textH5: {
        fontFamily:'Avenir Next Regular',
        fontSize:20,
        fontWeight:500,
        color:'#B1B1B1'
    },
    card: {
        borderWidth:1,
        borderColor:'#E6E6E6',
        borderRadius:40,
        alignItems:'center',
        width:425,
        height: 650,
        margin:20

    },
    cardPop: {
        borderWidth:1,
        borderColor:'#6F2CF7',
        borderRadius:40,
        alignItems:'center',
        width:425,
        height: 650,
        margin:20,
        backgroundColor:'rgba(111, 44, 247, 0.05)'

    },
    buttonPlan: {
        padding:12,
        margin:5,
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#6F2CF7',
        borderRadius:50,
        width:'80%'
    },
    tickCircleIcon: {
        width:24,
        height:24
    },
    buttonChoice1 : {
      marginTop:20,
      width:'50vw',
      borderRadius:20,
      borderWidth:1,
      borderColor:'#BEBEBE',
      padding:20,
    },
    buttonChoice1selected : {
      marginTop:20,
      width:'50vw',
      borderRadius:20,
      borderWidth:1,
      borderColor:'#6F2CF7',
      padding:20,
      backgroundColor:'rgba(111, 44, 247, 0.05)',
      

    },
    map : {
      marginTop:20,
      width:'40vw',
      height:400,
      borderRadius:20,
      borderWidth:1,
      borderColor:'#6F2CF7',
      padding:20,

    },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap', 
        paddingLeft:50,
        paddingRight:50,
    },
    buttonCat: {
        padding: 20,
        margin: 5,
        borderRadius:10,
        borderColor: '#ccc',
        justifyContent:'center',
        textAlign:'center',
        borderWidth:1,
        width:'30%',
        height:100,
    },
    buttonCatSelected: {
        padding: 20,
        margin: 5,
        borderRadius:10,
        borderColor: '#ccc',
        backgroundColor: '#6F2CF7',
        justifyContent:'center',
        width:'30%',
        height:100,
    },
    buttonCatAV: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        margin: 5,
        borderRadius:30,
        borderColor: '#ccc',
        justifyContent:'center',
        textAlign:'center',
        borderWidth:1,
    },
    buttonCatSelectedAV: {
      paddingVertical: 15,
      paddingHorizontal: 20,
        margin: 5,
        borderRadius:30,
        borderColor: '#ccc',
        backgroundColor: '#6F2CF7',
        justifyContent:'center',
    },
    iconCat: {
        width: 30,
        height:30
    },
    TextFilter: {
        color: 'black',
        paddingTop:10,
        fontWeight: 'Bold',
        fontFamily: 'Avenir Next Regular',
    },
    TextFilterSelected : {
        color: 'white',
        paddingTop:10,
        fontWeight: 'Bold',
        fontFamily: 'Avenir Next Regular',
    },
    TextFilterAV: {
        color: 'black',
        fontWeight: 'Bold',
        fontFamily: 'Avenir Next Regular',
    },
    TextFilterSelectedAV : {
        color: 'white',
        fontWeight: 'Bold',
        fontFamily: 'Avenir Next Regular',
    },
    priorityItem : {
      padding:10,
      width:'100%'

    },
    priorityListContainer: {
      width:'100%'
    }

    , containerRecap: {
      flex: 1, justifyContent: 'center', alignItems: 'center' 
  },
  titleRecap: {
      fontSize: 22,
      fontWeight: 'bold',
      marginBottom: 15,
  },
  textRecap: {
      fontSize: 16,
      marginBottom: 5,
  },
  imageRecap: {
      width: '100%',
      height: 200,
      resizeMode: 'cover',
      marginBottom: 15,
  }
})
