import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet,ScrollView, TouchableOpacity, Image } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; // Si vous utilisez Expo. Sinon, utilisez `react-native-vector-icons` directement.
import Activities from './Activities';


// Exemple d'écrans - Remplacez ceci par vos propres composants
export default function  HomeActivity({ navigation,searchActivity,setSearchActivity,onActivitySelect,address,setAddress}) {


      return (
        <View style={{width:'100%',zIndex:1,height:'100%'}}>
            <View style={{ flex: 1,height:'100%'}}>
                <Activities navigation={navigation} onActivitySelect={onActivitySelect} searchActivity={searchActivity} address={address} />
            </View>
        </View>
      );
    }