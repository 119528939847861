import React, { useState,useEffect,useRef } from 'react';

import { View, StyleSheet, Text, Button,Dimensions,ScrollView,Animated } from 'react-native';
import NavBar from '../components/NavBar.js'
import HomeLieux from '../components/HomeLieux.js'
import HomeActivity from '../components/HomeActivity.js'
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence

import { onAuthStateChanged,getAuth } from "firebase/auth";
// Exemple d'écrans - Remplacez ceci par vos propres composants
export default function  HomeScreen({ navigation,user }) {
  const [selectedButton, setSelectedButton] = useState('Lieux');
  const [searchActivity, setSearchActivity] = useState('');
  const [address, setAddress] = useState('');
  const [paddingTop, setPaddingTop] = useState('10vh');
  const scrollY = useRef(new Animated.Value(0)).current;

  const [onActivitySelect, setOnActivitySelect] = useState(null);
  const DayContext = React.createContext();
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

  let isLargeScreen = screenWidth >= 1000;  
  /*useEffect(() => {
    const onChange = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', onChange);
    return () => Dimensions.removeEventListener('change', onChange);
    const isLargeScreen = screenWidth >= 1000;  
  }, []);*/ 

  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log("Utilisateur connecté in MENU:", user);
      // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
    } else {
      const user = getAuth()
      console.log("Utilisateur déconnecté in MENU");
      // Mettez à jour votre état en conséquence
    }
  });
  useEffect(() => {
    
      return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
    }, [auth]);
    
    return (
      <View style={{backgroundColor:'#FFFFFF',height:'100vh'}}>
      
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center',overflow:'hidden',minHeight:'100%' }}>
        <NavBar onActivitySelect={onActivitySelect} setOnActivitySelect={setOnActivitySelect} scrollY={scrollY}  page={'Home'} navigation={navigation} selectedButton={selectedButton} setSelectedButton={setSelectedButton} searchActivity={searchActivity} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} style={{position:'sticky',top:0}}/>
        
      <ScrollView style={{backgroundColor:'#FFFFFF',width:'100%',height:'100vh'}}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: false }
        )}
        scrollEventThrottle={16}>
        {selectedButton === 'Lieux' ? 
        <HomeLieux searchActivity={searchActivity} onActivitySelect={onActivitySelect} setOnActivitySelect={setOnActivitySelect} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} navigation={navigation}/> 
        : <HomeActivity searchActivity={searchActivity} onActivitySelect={onActivitySelect} setOnActivitySelect={setOnActivitySelect} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} navigation={navigation}/>}
      
        </ScrollView>
        
      </View>
       
      </View>
      
    );
  }