import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Picker } from 'react-native';

const months = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];

const daysOfWeek = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

const getYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 3 }, (_, i) => currentYear + i);
};

const CustomDatePicker = ({ date, onDateChange }) => {
  const [currentDateChanged, setCurrentDateChanged] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const maxDate = new Date(new Date().getFullYear() + 2, 11, 31); // date maximale fixée

  const handlePrevMonth = () => {
    const newDate = new Date(displayedDate);
    newDate.setMonth(displayedDate.getMonth() - 1);
    if (newDate >= new Date().setHours(0, 0, 0, 0)) {
      setDisplayedDate(newDate);
    }
  };

  const handleNextMonth = () => {
    const newDate = new Date(displayedDate);
    newDate.setMonth(displayedDate.getMonth() + 1);
    if (newDate <= maxDate) {
      setDisplayedDate(newDate);
    }
  };

  const handleSelectDate = (day) => {
    const selectedDate = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), day);
    if (selectedDate >= new Date().setHours(0, 0, 0, 0)) {
      setCurrentDate(selectedDate);
      onDateChange(selectedDate);
      console.log("CurrentSelDate : " + String(selectedDate));
    }
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const renderDays = () => {
    const startOfMonth = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), 1);
    const endOfMonth = new Date(displayedDate.getFullYear(), displayedDate.getMonth() + 1, 0);
    const daysInMonth = endOfMonth.getDate();
    const startDay = startOfMonth.getDay() === 0 ? 6 : startOfMonth.getDay() - 1;
    const days = [];
    let rows = [];

    // Ajoute les jours du mois précédent grisés
    for (let i = 0; i < startDay; i++) {
      days.push(<View key={`empty-${i}`} style={styles.emptyDay} />);
    }

    // Ajoute les jours du mois en cours
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), i);
      const selected = isSameDay(date, currentDate);
      const isPastDate = date < new Date().setHours(0, 0, 0, 0);
      days.push(
        <TouchableOpacity
          key={i}
          style={[styles.day, selected && styles.selectedDay, isPastDate && styles.disabledDay]}
          onPress={() => !isPastDate && handleSelectDate(i)}
          disabled={isPastDate}
        >
          <Text style={selected ? styles.selectedDayText : styles.dayText}>{i}</Text>
        </TouchableOpacity>
      );
    }

    // Ajoute les jours pour compléter la dernière semaine du mois
    const remainingDays = (7 - (days.length % 7)) % 7;
    for (let i = 0; i < remainingDays; i++) {
      days.push(<View key={`empty-end-${i}`} style={styles.emptyDay} />);
    }

    // Organise les jours en lignes de 7 cases
    for (let i = 0; i < days.length; i += 7) {
      const week = days.slice(i, i + 7);
      rows.push(
        <View key={`week-${i / 7}`} style={styles.weekRow}>
          {week}
        </View>
      );
    }

    return rows;
  };

  const handleMonthChange = (month) => {
    const newDate = new Date(displayedDate);
    newDate.setMonth(month);
    if (newDate >= new Date().setHours(0, 0, 0, 0) && newDate <= maxDate) {
      setDisplayedDate(newDate);
    }
  };

  const handleYearChange = (year) => {
    const newDate = new Date(displayedDate);
    newDate.setFullYear(year);
    if (newDate >= new Date().setHours(0, 0, 0, 0) && newDate <= maxDate) {
      setDisplayedDate(newDate);
    }
  };

  useEffect(() => {
    if (!date && !currentDateChanged) {
      setCurrentDate(new Date());
      setDisplayedDate(new Date());
      setCurrentDateChanged(true);
    }
  }, [date, currentDateChanged]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={handlePrevMonth} disabled={displayedDate <= new Date().setHours(0, 0, 0, 0)}>
          <Text style={[styles.navButton, displayedDate <= new Date().setHours(0, 0, 0, 0) && styles.disabledNavButton]}>{'<'}</Text>
        </TouchableOpacity>
        <View style={styles.monthYearContainer}>
          <Picker
            selectedValue={displayedDate.getMonth()}
            style={styles.picker}
            onValueChange={handleMonthChange}
          >
            {months.map((month, index) => (
              <Picker.Item key={index} label={month} value={index} />
            ))}
          </Picker>
          <Picker
            selectedValue={displayedDate.getFullYear()}
            style={styles.picker}
            onValueChange={handleYearChange}
          >
            {getYears().map((year, index) => (
              <Picker.Item key={index} label={String(year)} value={year} />
            ))}
          </Picker>
        </View>
        <TouchableOpacity onPress={handleNextMonth} disabled={displayedDate >= maxDate}>
          <Text style={[styles.navButton, displayedDate >= maxDate && styles.disabledNavButton]}>{'>'}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.daysOfWeek}>
        {daysOfWeek.map((day, index) => (
          <Text key={index} style={styles.dayOfWeek}>{day}</Text>
        ))}
      </View>
      <View style={styles.daysContainer}>
        {renderDays()}
      </View>
      <View style={styles.selectedDateContainer}>
        <Text style={styles.selectedDateText}>
          Date sélectionnée: {currentDate.toLocaleDateString()}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#fff',
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  navButton: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledNavButton: {
    color: '#ccc',
  },
  monthYearContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  picker: {
    height: 50,
    width: 100,
    borderWidth: 0,
  },
  daysOfWeek: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  dayOfWeek: {
    width: 30,
    textAlign: 'center',
  },
  daysContainer: {
    flexDirection: 'column',
  },
  weekRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  day: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 1,
  },
  dayText: {
    color: '#000',
  },
  selectedDay: {
    backgroundColor: '#6f2cf7',
    borderRadius: 15,
  },
  selectedDayText: {
    color: '#fff',
  },
  emptyDay: {
    width: 30,
    height: 30,
    margin: 1,
    backgroundColor: '#f0f0f0',
  },
  disabledDay: {
    backgroundColor: '#f0f0f0',
  },
  selectedDateContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  selectedDateText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default CustomDatePicker;
