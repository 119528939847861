import React from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import { useInfiniteHits } from 'react-instantsearch-core';

export function InfiniteHits({ hitComponent: Hit,searchQuery:inputValue, ...props }) {
  const { hits, isLastPage, showMore } = useInfiniteHits({
    ...props,
    escapeHTML: false,
  });
  console.log("inputValue: "+String(inputValue)+" type: "+String(typeof(inputValue)))
 if (inputValue>0){
    return (
        <View style={styles.hits}>
            
        <FlatList
          data={hits}
          keyExtractor={(item) => item.objectID}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          onEndReached={() => {
            if (!isLastPage) {
              showMore();
            }
          }}
          renderItem={({ item }) => (
            <View style={styles.item}>
              <Hit hit={item} />
            </View>
          )}
        />
        </View>
      );
 }
  
};

const styles = StyleSheet.create({
    hits:{
        position:'absolute',
        backgroundColor:'#FFFFFF',
        top:60
    },
  separator: {
    borderBottomWidth: 1,
    borderColor: '#ddd',
  },
  item: {
    padding: 18,
  },
});