import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    TextInput,
    Button,
    Image,
    Animated,
    Dimensions,
    ActivityIndicator,
    FlatList,
    Switch,
    TouchableWithoutFeedback
} from "react-native";
import { ScrollView } from "react-native-web";
import { Picker } from "@react-native-picker/picker";
import { useDropzone } from 'react-dropzone';
import CustomImage from './customImage';
import AgeRangePicker from './AgeRangePicker';
import HourRangePicker from './HourRangePicker';
import CustomDatePicker from './CustomDatePicker';
import MultiDatePicker from './MultiDatePicker';
import { getDatabase, ref, set,push,onValue } from "firebase/database";
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import  useUniqueId  from './useUniqueId';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase'
import { Ionicons } from '@expo/vector-icons';

const ITEM_WIDTH = 370; // Largeur de l'élément + marginRight
const daysMapping = {
    'Lun': 'Lundi',
    'Mar': 'Mardi',
    'Mer': 'Mercredi',
    'Jeu': 'Jeudi',
    'Ven': 'Vendredi',
    'Sam': 'Samedi',
    'Dim': 'Dimanche'
  };


  
const Activity = ({ day,time,room,teacher,address,price,title,key,colorKey }) => (
  <View key={key+'View'} style={{width:300,marginLeft:10}}>
    <TouchableOpacity key={'activity'}  style={styles.containerCard}>
                        <Image source={require('../assets/inscImg1.png')} style={styles.imageAct} />
                        <View style={{ margin: 5, width: '100%' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Text style={styles.nameAct}>{title}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                    <Image source={require('../assets/icon/clock-icon.png')} style={styles.iconStar} />
                                    <Text style={styles.nameAct}>
                                      {time}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Text style={styles.nameAct}></Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                    <Image source={require('../assets/icon/clock-icon.png')} style={styles.iconStar} />
                                    <Text style={styles.nameAct}>
                                      {day === 'Lun' ? 'Lundi' : day === 'Mar' ? 'Mardei' : day === 'Mer' ? 'Mercredi' : day === 'Jeu' ? 'Jeudi' : day === 'Ven' ? 'Vendredi' : day === 'Sam' ? 'Samedi' :  day === 'Dim' ? 'Dimanche' : day}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <Text style={styles.descAct}>{room}</Text>
                                <Text style={styles.descAct}>{teacher}</Text>
                                
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <Text style={styles.priceAct}><b>{price} crédits</b> par activités</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
  </View>
);

// ...
const ListingActivitiesHourDashboard = ({ profilInfo,placeId }) => {
  // États pour les filtres sélectionnés
  
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [currentActivityData, setCurrentActivityData] = useState(null);
  const [animateListItems, setAnimateListItems] = useState(false);

  const openEditPanel = (activity) => {
    setCurrentActivityData(activity);
    setIsPanelVisible(true);
    setAnimateListItems(false); // Empêcher l'animation lors de l'ouverture du panneau
  };
  
  const closePanel = () => {
    setIsPanelVisible(false);
    setAnimateListItems(true); // Permettre l'animation après la fermeture du panneau
  };
  
  const openAddPanel = () => {
    setCurrentActivityData(null);
    setIsPanelVisible(true);
    setAnimateListItems(false); // Empêcher l'animation lors de l'ajout
  };
  
  const daysOrdered = ['Tous', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  
  
  const ITEM_WIDTH = 238; // Largeur de l'élément + marginRight
  const daysMapping = {
    Lun: "Lundi",
    Mar: "Mardi",
    Mer: "Mercredi",
    Jeu: "Jeudi",
    Ven: "Vendredi",
    Sam: "Samedi",
    Dim: "Dimanche",
  };
 
  const convertHourRange = (hourRange) => {
    const [start, end] = hourRange.split(' - ').map(time => {
      const [hour, minute] = time.split(':').map(Number);
      return hour + (minute / 60);
    });
    return [start, end];
  };

  
  const ActivityPanel = ({ isVisible, onClose, activityData, activities,placeId }) => { 
    const slideAnim = useRef(new Animated.Value(-700)).current;

    const [id, setId] = useState("");
    const [day, setDay] = useState("");
    const [time, setTime] = useState("");
    const [price, setPrice] = useState("");
    const [capacity, setCapacity] = useState("");
    const [group, setGroup] = useState("");
    const [desc, setDesc] = useState("");
    const [teacher, setTeacher] = useState("");
    const [age, setAge] = useState([null, null]);
    const [hour, setHour] = useState([null, null]);
    const [category, setCategory] = useState('Activité unique');
    const [ageRange, setAgeRange] = useState({ min: 3, max: 18 });
    const [hourRanges, setHourRanges] = useState({}); // Changement ici
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [isNewTeacher, setIsNewTeacher] = useState(false);
    const [multipleDates, setMultipleDates] = useState([]);
    const [sameHours, setSameHours] = useState(true);
    const [nthDay, setNthDay] = useState('1'); // Ajout de l'état pour le nième jour

    const handleDayPress = (day) => {
      setSelectedDays([day]);
      setDay(day);
      console.log(JSON.stringify({
        day: day || 0,
        frequency: frequency || 'non def',
    }));
      console.log("day : "+String(day))
  };

  useEffect(() => {
    console.log("ActivityPanel placeId : " + placeId);

    if (activityData) {
        setSelectedTeacher(activityData.teacher?.id || 'Selectionner un professeur');
        setIsNewTeacher(false);
    } else {
        setSelectedTeacher('Selectionner un professeur');
        setIsNewTeacher(false);
    }
}, [isVisible, activityData, teachers]);



    
    const [addressList, setAddressList] = useState([]);
    const [address, setAddress] = useState('');
    const [newAddressText, setNewAddressText] = useState('');
    const [newRoomText, setNewRoomText] = useState('');
    const [addressG, setAddressG] = useState(undefined);
    const [newAct, setNewAct] = useState(true);
    const [newAddress, setNewAddress] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [room, setRoom] = useState('');
    const [newRoom, setNewRoom] = useState(true);
    const [pickerAddressText, setPickerAddressText] = useState('');
    const [frequency, setFrequency] = useState(''); // Ajout de l'état de fréquence
 

    const resetFields = () => {
      try {
          setNewAct(true);
  
          if (typeof useUniqueId === 'function') {
              setId(useUniqueId());
          } else {
              console.error("useUniqueId n'est pas une fonction valide", useUniqueId);
          }
  
          setDay(new Date());
  
          setTime(null);
          setRoom(null);
          setPrice(1);
          setCapacity('');
  
          setCategory('Activité unique');
          setGroup(null);
          setDesc(null);
          setNthDay(0);
  
          if (teachers && teachers[1] && typeof teachers[1] === 'object') {
              setTeacher(JSON.stringify(teachers[1]));
              if (teachers[1].id) {
                  setSelectedTeacher(JSON.stringify(teachers[1].id));
              } else {
                  console.error("ID de l'enseignant non défini pour le deuxième enseignant", teachers[1]);
              }
          } else {
              console.error("Liste des enseignants non définie ou invalide", teachers);
          }
  
          setFrequency('Unique');
          setAddress(null);
          setNewAddressText('');
          setNewRoomText('');
          setAddressG(null);
          setNewAddress(true);
          setAge([3, 18]);
          setHourRanges({ 'default': [8, 10.5] });
  
          setIsNewTeacher(false);
      } catch (error) {
          console.error("Erreur lors de la réinitialisation des champs", error);
      }
  };
  

  const handleAddressChange = (itemValue) => {
    if (!itemValue || itemValue === 'Nouvelle adresse') {
        // Nouvelle adresse sélectionnée
        setNewAddress(true);
        setAddress(null);
        setAddressG(null); // Réinitialiser addressG
        setRoomList([]); // Vider la liste des salles
        setRoom(null);
        setNewRoom(true);
    } else {
        // Adresse existante sélectionnée
        setNewAddress(false);
        setAddress(itemValue);
        
        let selectedAddressG = null;
        let allRooms = [];

        // Parcourir toutes les places pour récupérer toutes les salles associées à l'adresse sélectionnée
        for (const place of Object.values(profilInfo.places)) {
            console.log("place : "+JSON.stringify(place));
            if (place && place.calendar) {  // Vérifiez que place et place.calendar existent
                for (const calendarKey in place.calendar) {
                    console.log("calendarKey : " + JSON.stringify(calendarKey));
                    const calendarEntry = place.calendar[calendarKey];

                    if (calendarEntry && calendarEntry.address === itemValue) {  // Vérifiez que l'adresse correspond à itemValue
                        selectedAddressG = calendarEntry.addressG || null;
                        const roomNames = calendarEntry.room ? [calendarEntry.room] : [];

                        // Si calendarEntry.calendar existe, ajoutez également ses rooms
                        if (calendarEntry.calendar && Array.isArray(calendarEntry.calendar)) {
                            const additionalRooms = calendarEntry.calendar
                                .map(event => event.room)
                                .filter(roomName => roomName && roomName !== "UNKNOWN_ROOM" && roomName !== "");
                            roomNames.push(...additionalRooms);
                        }

                        allRooms.push(...roomNames);
                    }
                }
            }
        }

        setAddressG(selectedAddressG);

        // Suppression des doublons dans les rooms
        const uniqueRooms = [...new Set(allRooms)];
        setRoomList(uniqueRooms);
        console.log("uniqueRooms : " + JSON.stringify(uniqueRooms));

        if (uniqueRooms.length > 0) {
            setRoom(uniqueRooms[0]); // Sélectionner la première salle trouvée par défaut
        } else {
            setRoom(null); // Aucun nom de salle valide trouvé
        }

        setNewRoom(false);
    }
};









    const handleRoomChange = (itemValue) => {
      console.log("itemValue handleRoomChange : "+JSON.stringify(itemValue))
        if (itemValue === 'new' ) {
            setNewRoom(true);
            setRoom('');
        } else {
          
            setNewRoomText('');
            setNewRoom(false);
            setRoom(itemValue);
        }
    };

    useEffect(() => {
      try {
          // Vérification que 'activities' et 'activities.places' existent et sont bien définis
          if (activities && activities.places) {
              const addresses = new Set();
              Object.values(activities.places).forEach(activity => {
                  if (activity && activity.address) { // Vérification que l'activité et l'adresse existent
                      addresses.add(activity.address);
                  }
              });
              setAddressList([...addresses]);
          } else {
              console.warn("Activities ou activities.places est non défini");
              setAddressList([]); // Par sécurité, on initialise une liste vide
          }
  
          // Vérification que 'activityData' est défini avant de tenter d'accéder à ses propriétés
          if (activityData) {
              setNewAct(false);
              setAddress(activityData.address || '');
              setAddressG(activityData.addressG || null);
              handleAddressChange(activityData.address || '');
              setId(activityData.id || useUniqueId());
              setDay(activityData.day || new Date());
              handleDayPress(activityData.day);
              setTime(activityData.time || '');
              setRoom(activityData.room || '');
              setNewRoom(false);
              setPrice(activityData.price || '');
              setCategory(activityData.category || 'Activité unique');
              setCapacity(activityData.capacity || '');
              setGroup(activityData.group || '');
              setDesc(activityData.desc || '');
              setNthDay(activityData.nthDay || '');
              setTeacher(activityData.teacher || teachers[1] || { id: '', name: '' }); // Sécurité si teachers[1] n'existe pas
              setAge(activityData.age || [3, 18]);
              setFrequency(activityData.frequency || 'Unique');
              setMultipleDates(activityData.multipleDates ? activityData.multipleDates.map(date => new Date(date)) : []);
              if (activityData.multipleDates> 0) {
                const hasSameHours = activityData.hourRanges.every(range => 
                    JSON.stringify(range.hours) === JSON.stringify(activityData.hourRanges[0].hours)
                );
                setSameHours(hasSameHours);
                setHourRanges(activityData.hourRanges.reduce((acc, range) => {
                    acc[range.date] = range.hours;
                    return acc;
                }, {}));

              }else{
                setHourRanges({ default: activityData.time ? convertHourRange(activityData.time) : [8, 10.5] });

              }
              setSelectedTeacher(activityData.teacher ? activityData.teacher.id : '');
              setIsNewTeacher(false);
          } else {
              resetFields();
          }
  
          // Animation pour l'affichage du composant
          if (isVisible) {
              Animated.timing(slideAnim, {
                  toValue: 0,
                  duration: 300,
                  useNativeDriver: false,
              }).start();
          } else {
              Animated.timing(slideAnim, {
                  toValue: -700,
                  duration: 300,
                  useNativeDriver: false,
              }).start();
          }
      } catch (error) {
          console.error("Erreur dans l'effet useEffect :", error);
      }
  }, [isVisible, activityData, activities]);
  

  const handleCategoryChange = (value) => {
    setCategory(value);
    if (value === 'Activité récurrente') {
        setFrequency('Hebdomadaire'); // ou une autre valeur par défaut que vous souhaitez
        setDay("Mon")
    } else if (value === 'Activité unique') {
        setFrequency('Unique'); // Pas de fréquence pour une activité unique
    } else if (value === 'Stage') {
        setFrequency('Hebdomadaire'); // Ou une autre valeur par défaut
    }
};

    const handleSelectAddress = (data, details = null) => {
      // 'details' est fourni lorsque fetchDetails = true
      console.log(data, details);
      console.log(data.description);
      if (details && details.geometry) {
        setNewAddressText(data.description)
        setAddressG({
              address: data.description,
              lat: details.geometry.location.lat,
              lng: details.geometry.location.lng
          });
          console.log("addressG : "+addressG)
      }
  };


  const handleTeacherChange = (value) => {
    const selectedTeacherObj = teachers.find(teacher => teacher.id === value);

    if (selectedTeacherObj) {
        setSelectedTeacher(value);
    } else {
        console.warn("L'enseignant sélectionné n'existe pas dans la liste.");
    }
};
const validTeachers = teachers.filter(teacher => teacher.id !== 'Tous' && teacher.id !== 'UNKNOWN_TEACHER_ID');
const sortedTeachers = [
    { id: '000', name: 'Selectionner un professeur' },
    ...validTeachers.filter(teacher => teacher.id !== 'Selectionner un professeur')
];




    const formatDate = (date) => {
      return date.toLocaleDateString(); // Formatage des dates
    };
    const [errors, setErrors] = useState({});


    const validateFields = () => {
      const newErrors = {};
      let hasError = false;
  
      if (!group) {
          newErrors.group = true;
          logError("Titre de l'activité", "Le champ 'Titre de l'activité' est vide");
          hasError = true;
      }
      if (!desc) {
          newErrors.desc = true;
          logError("Description", "Le champ 'Description' est vide");
          hasError = true;
      }
      if (!teacher) {
          newErrors.teacher = true;
          logError("Enseignant", "Le champ 'Enseignant' est vide");
          hasError = true;
      }
      if (!category) {
          newErrors.category = true;
          logError("Catégorie de l'activité", "Le champ 'Catégorie de l'activité' est vide");
          hasError = true;
      }
      if (!address && !newAddressText) {
          newErrors.address = true;
          logError("Adresse", "Le champ 'Adresse' est vide");
          hasError = true;
      }
      if (!room && !newRoomText) {
          newErrors.room = true;
          logError("Nom de la salle", "Le champ 'Nom de la salle' est vide");
          hasError = true;
      }
      if (!capacity) {
          newErrors.capacity = true;
          logError("Nombre de place restante", "Le champ 'Nombre de place restante' est vide");
          hasError = true;
      }
      if (!frequency || (frequency !== 'Hebdomadaire' && frequency !== 'Mensuel' && frequency !== 'Unique')) {
        newErrors.frequency = true;
        console.log('Erreur de validation : Fréquence non définie ou invalide');
        hasError = true;
    }
    if (day === 'Invalid Date') {
        newErrors.day = true;
        console.log('Erreur de validation : Date invalide');
        hasError = true;
    }
      setErrors(newErrors);
      return !hasError;
  };
  const logError = (fieldName, message) => {
    console.log(`Erreur de validation : le champ '${fieldName}' est vide`);
};



const handleSubmit = async (placeId) => {
  console.log('Préparation de la soumission avec les valeurs suivantes :');
  console.log('Frequency :', frequency);
  console.log('Day :', day);
  console.log('Teacher :', selectedTeacher);
  if (!validateFields()) {
      console.log('Validation échouée');
      return;
  }

  if (!placeId) {
      console.error("placeId est indéfini ou null");
      return;
  }

  let finalAddress = addressG.address || 'UNKNOWN_ADDRESS';
  let finalRoom = newRoomText !== '' ? newRoomText : room || 'UNKNOWN_ROOM';
  let finalTeacher = teachers.find(t => t.id === selectedTeacher) || { id: 'UNKNOWN_TEACHER_ID', name: 'Unknown Teacher' };

  // Gestion des plages horaires pour les dates multiples
  const formattedHourRanges = multipleDates.map(date => {
    // Vérifie si date est déjà un objet Date, sinon le convertir
    const dateObj = (date instanceof Date) ? date : new Date(date);

    return {
        date: dateObj.toISOString().split('T')[0], // Assurez-vous que toISOString est appelé sur un objet Date
        hours: hourRanges[dateObj.toLocaleDateString('fr-FR')] || [8, 10.5]
    };
});

  // Gestion des plages horaires pour les jours récurrents
  selectedDays.forEach(day => {
      formattedHourRanges.push({
          day: day, // Le jour récurrent (ex: "Lun", "Mar")
          hours: hourRanges[day] || [8, 10.5]
      });
  });

  const activityInfo = {
      id: id || 'UNKNOWN_ID',
      day: day || 0,
      time: time || '00:00 - 00:00',
      price: price || 0,
      capacity: capacity || 'UNKNOWN_CAPACITY',
      group: group || 'UNKNOWN_GROUP',
      desc: desc || 'No description',
      teacher: finalTeacher,
      age: age || [0, 99],
      hourRanges: formattedHourRanges,
      category: category || 'UNKNOWN_CATEGORY',
      address: finalAddress,
      addressG: addressG || { lat: 0, lng: 0 },
      room: finalRoom,
      nthDay: nthDay || 0,
      multipleDates: multipleDates || [],
      frequency: frequency || 0,
      selectedDays: selectedDays || [],
      isNewTeacher: isNewTeacher || false,
      image: image || null,
  };

  console.log(activityInfo);
  

  try {
      const addActivity = httpsCallable(functions, 'addActivity');
      const response = await addActivity({ placeId, activityInfo, newAddress, newAddressText: finalAddress });
      console.log("response.data.message : " + response.data.message);
      onClose();  // Ferme le panneau après soumission
  } catch (error) {
      console.error("Erreur lors de l'ajout de l'activité à Firebase:", error);
  }
};






const handleSubmit1 = async (placeId) => {
  if (!validateFields()) {
      console.log('Validation failed');
      return;
  }

  // Vérifiez que placeId est défini
  if (!placeId) {
      console.error("placeId est indéfini ou null");
      return;
  }
// Vérification des variables critiques avant de les utiliser
if (!addressG || !addressG.address) {
  console.error("addressG ou addressG.address est indéfini");
  return;
}


if (!Array.isArray(multipleDates) || multipleDates.length === 0) {
  console.warn("Aucune date multiple n'a été définie");
}

if (!hourRanges || typeof hourRanges !== 'object') {
  console.warn("hourRanges est indéfini ou mal formaté");
}

// Assurez-vous que les champs importants ne sont pas vides ou undefined
const finalAddress = addressG && addressG.address ? addressG.address : 'UNKNOWN_ADDRESS';
const finalRoom = newRoomText !== '' ? newRoomText : room || 'UNKNOWN_ROOM';
const finalTeacher = teachers.find(t => t.id === selectedTeacher) || { id: 'UNKNOWN_TEACHER_ID', name: 'Unknown Teacher' };

const formattedHourRanges = multipleDates.map(date => {
  const dateObj = date instanceof Date ? date : new Date(date);
  return {
      date: dateObj.toISOString().split('T')[0],
      hours: hourRanges[dateObj.toLocaleDateString('fr-FR')] || [8, 10.5],
  };
});

selectedDays.forEach(day => {
  formattedHourRanges.push({
      day,
      hours: hourRanges[day] || [8, 10.5],
  });
});
console.log('formattedHourRanges:', formattedHourRanges);

// Construire l'objet activityInfo
const activityInfo = {
  id: id || 'UNKNOWN_ID',
  day: day || 0,
  time: time || '00:00 - 00:00',
  price: price || 0,
  capacity: capacity || 'UNKNOWN_CAPACITY',
  group: group || 'UNKNOWN_GROUP',
  desc: desc || 'No description',
  teacher: finalTeacher,
  age: age || [0, 99],
  hourRanges: formattedHourRanges,
  category: category || 'UNKNOWN_CATEGORY',
  address: finalAddress,
  addressG: addressG || { lat: 0, lng: 0 },
  room: finalRoom,
  nthDay: nthDay || 0,
  multipleDates: multipleDates || [],
  frequency: frequency || 0,
  selectedDays: selectedDays || [],
  isNewTeacher: isNewTeacher || false,
  image: image || null,
};

console.log('activityInfo:', activityInfo);

try {
  const addActivity = httpsCallable(functions, 'addActivity');
  const response = await addActivity({ placeId, activityInfo, newAddress, newAddressText: finalAddress });
  console.log("response.data.message : " + response.data.message);
  onClose();
} catch (error) {
  console.error("Erreur lors de l'ajout de l'activité à Firebase:", error);
}

};





    const inputStyle = (field) => ({
      ...stylesSM.input,
      borderColor: errors[field] ? 'red' : 'gray',
      borderWidth: errors[field] ? 2 : 1,
  });
    
  
  const handleHourChange = (day, hours) => {
    console.log(`Changement d'horaire pour ${day}:`, hours);
    setHourRanges(prevState => ({
        ...prevState,
        [day]: hours  // Met à jour les heures pour le jour spécifié
    }));
};


    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);
    const handleDragOver = useCallback((event) => {
        event.preventDefault();
    }, []);

    const handleStudentLimitChange = (value) => {
        setCapacity(value);
    };

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        processFile(file);
    }, []);

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setImage(e.target.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleCapacityNumberChange = (event) => {
      if (event && event.target && event.target.value !== undefined) {
        const value = event.target.value;
        // Filtrer les caractères non numériques
        const numericValue = value.replace(/[^0-9]/g, '');
        setCapacity(numericValue);
      }
    };
    
    const processFile = (file) => { 
    
        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            const reader = new FileReader();
            reader.onload = (e) => setImage(e.target.result);
            reader.readAsDataURL(file);
        }
    };

    return (
        <Animated.View style={[styles.slidingPanel, { right: slideAnim, zIndex: 100, top: 86 }]}>
            <View style={{ justifyContent: 'center', alignContent: 'center', width: '100%', height: '100%' }}>
                <View style={{ flexDirection: 'row', width: '100%', justifyContent: "space-between", marginBottom: 20 }}>
                    {!newAct ? 
                        <Text style={{ ...stylesSM.header, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>Modification d'une activité</Text>
                        : 
                        <Text style={{ ...stylesSM.header, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>Ajout d'une activité</Text>
                    }
                    <TouchableOpacity onPress={onClose} style={{ height: '100%', alignContent: 'center', justifyContent: 'center' }}>
                        <Ionicons name="close-outline" size={20} color="black" />
                    </TouchableOpacity>
                </View>
                <ScrollView style={{ height: '100%' }}>
                    <View style={stylesSM.addActInputView}>
                        <TouchableOpacity 
                            style={{ ...styles.imageUploader, width: '60%', aspectRatio: '2/3', overflow: 'hidden', borderWidth: 2, borderRadius: 20, borderStyle: 'dashed', borderColor: 'lightgray', justifyContent: 'center' }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onPress={() => fileInputRef.current.click()}
                        >
                            <div {...getRootProps()} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '100%' }}>
                                <input {...getInputProps()} />
                                {image ? (
                                    <CustomImage source={require('../assets/inscImg1.png')} style={{ width: '100%', aspectRatio: '2/3', minHeight: '10vh', resizeMode: 'cover' }} />
                                ) : (
                                    <View style={{ padding: 10, height: '100%', justifyContent: 'flex-end' }}>
                                        <View></View>
                                        <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', bottom: '20%' }}>
                                            <CustomImage source={require('../assets/iconUploadImg.png')} style={{ width: 40, height: 40 }} />
                                            <Text style={{ ...styles.textH4, textAlign: 'center' }}>Faites glisser la photo de couverture de votre activité ici</Text>
                                        </View>
                                        <View style={{ bottom: 0, justifyContent: 'center' }}>
                                            <Text style={{ ...styles.textH4, bottom: 0, textAlign: 'center' }}>Télécharger depuis votre appareil</Text>
                                        </View>
                                    </View>
                                )}
                            </div>
                        </TouchableOpacity>
                    </View>
                    <View style={stylesSM.addActInputView}>
                        <Text style={stylesSM.TextTitle}>Adresse</Text>
                        <Picker
                            selectedValue={address}
                            onValueChange={handleAddressChange}
                            style={inputStyle('address')}
                        >
                            {addressList.map((addr, index) => (
                                <Picker.Item key={index} label={addr} value={addr} />
                            ))}
                            <Picker.Item label="Nouvelle adresse" value={null} />
                        </Picker>
                        {newAddress && (
                           <GooglePlacesAutocomplete
                           placeholder='Adresse'
                           minLength={2} // minimum length of text to search
                           autoFocus={false}
                           returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                           listViewDisplayed={true}   // true/false/undefined
                           fetchDetails={true}
                           renderDescription={row => row.description} // custom description render
                           onPress={ handleSelectAddress}
                           
                           getDefaultValue={() => ''}
                           
                           requestUrl={{
                               useOnPlatform: 'web', // ou "all" pour toutes les plateformes
                               url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // ou tout autre proxy de votre choix
                               
                           }}
                           query={{
                             key: 'AIzaSyBXP9mFixsN76mGW6_qNzo8kaeHj8iJXls',
                             language: 'fr',
                           }}
                           styles={{
                               
                               listView: { 
                                   color: 'black', //To see where exactly the list is
                                     zIndex: 125, //To popover the component outwards
                                     position: 'relative',  
                                     maxWidth:360,
                                     borderRadius:20,
                                     marginBottom:25 ,
                                     shadowColor: '#7E7E7E',
                                     shadowOffset: { width: 2, height: 2 },
                                     shadowOpacity: 0.25,
                                     shadowRadius: 7,
                                     backgroundColor:'white',
                                     borderColor:'grey',
                               },
                               textInput: {
                                width:'100%',
                                borderWidth: 1,
                                borderColor: 'gray',
                                borderRadius: 8,
                                padding: 10,
                                marginBottom: 20,
                               },
                               predefinedPlacesDescription: {
                                 color: '#1faadb'
                               },
                             }}
                             
                             nearbyPlacesAPI='GooglePlacesSearch' // Which API to use: GoogleReverseGeocoding or GooglePlacesSearch
                             
                       
                             filterReverseGeocodingByTypes={['locality', 'administrative_area_level_3']} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
                             
                       
                             debounce={200} // debounce the requests in ms. Set to 0 to remove debounce. By default 0ms.
                             
                           />
                        )}
                        {(address && !newAddress) && (
                            <>
                             <Text style={stylesSM.TextTitle}>Nom de la salle</Text>
                                <Picker
                                    selectedValue={room}
                                    onValueChange={handleRoomChange}
                                    style={inputStyle('room')}
                                >
                                    {roomList.map((roomName, index) => (
                                        <Picker.Item key={index} label={roomName} value={roomName} />
                                    ))}
                                    <Picker.Item label="Nouvelle salle" value={'new'} />
                                </Picker>
                                {newRoom && (
                                    <TextInput
                                        style={inputStyle('room')}
                                        onChangeText={(value) => {setNewRoomText(value)} }
                                        value={newRoomText}
                                        placeholder="Entrez le nom de la nouvelle salle"
                                    />
                                )}
                            </>
                        )}
                        {(newAddress) && (
                            <>
                                <Text style={stylesSM.TextTitle}>Nom de la salle</Text>
                                <TextInput
                                    style={inputStyle('room')}
                                    onChangeText={(value) => {setNewRoomText(value)} }
                                    value={newRoomText}
                                    placeholder="Entrez le nom de la nouvelle salle"
                                />
                            </>
                        )}
                    </View>
                    <View style={stylesSM.addActInputView}>
                    <Text style={stylesSM.TextTitle}>Titre de l'activité</Text>
                    <TextInput
                        style={inputStyle('group')}
                        onChangeText={setGroup}
                        value={group}
                        placeholder="Titre de l'activité"
                    />
                </View>
                    <View style={stylesSM.addActInputView}>
                        <Text style={stylesSM.TextTitle}> Description </Text>
                        <TextInput
                            style={inputStyle('desc')}
                            onChangeText={setDesc}
                            value={desc}
                            placeholder="Description"
                            multiline={true}
                        />
                    </View>
                    <View style={stylesSM.addActInputView}>
                    <Text style={stylesSM.TextTitle}>Enseignant</Text>
                    
            <Picker
    style={{ borderRadius: 20, padding: 10 }}
                selectedValue={selectedTeacher}
                onValueChange={(itemValue) => handleTeacherChange(itemValue)}
            >
                {sortedTeachers.map((teacher, index) => (
                    <Picker.Item
                        key={`${teacher.id}-${index}`}
                        label={teacher.lastname ? `${teacher.name} ${teacher.lastname}` : teacher.name}
                        value={teacher.id}
                    />
                ))}
            </Picker>


                </View>
                    <View style={stylesSM.addActInputView}>
                        <Text style={stylesSM.TextTitle}>Catégorie de l'activité</Text>
                        <Picker
    style={{ borderRadius: 20, padding: 10 }}
    selectedValue={category}
    onValueChange={handleCategoryChange}
>
    {['Activité unique', 'Activité récurrente', 'Stage'].map((category) => (
        category !== 'Tous' && (
            <Picker.Item key={category} label={category} value={category} />
        )
    ))}
</Picker>

                    </View>
                    {category === 'Activité unique' && (
            <View style={stylesSM.addActInputView}>
              <Text style={{ ...stylesSM.TextTitle, marginBottom: 0 }}>Date de l'activité:</Text>
              <CustomDatePicker
                style={stylesSM.datePicker}
                date={day}
                onDateChange={(date) => setDay(date)}
              />
              <Text style={{ ...stylesSM.TextTitle, marginBottom: 0 }}>Heure de l'activité:</Text>
              <HourRangePicker hourMin={8} hourMax={23} hourStart={8} hourEnd={10.5} onChange={(values) => handleHourChange('default', values)} />
            </View>
          )}
          {category === 'Activité récurrente' && (
            <View style={stylesSM.addActInputView}>
              <Text style={{ ...stylesSM.TextTitle, marginBottom: 0 }}>Jour de l'activité:</Text>
              <View style={stylesSM.dayPicker}>
                {["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam","Dim"].map((day) => (
                  <TouchableOpacity
                    key={day}
                    style={[
                      stylesSM.dayButton,
                      selectedDays.includes(day) && stylesSM.dayButtonSelected,
                    ]}
                    onPress={() => {
                      handleDayPress(day);
                    }}
                  >
                    <Text style={[stylesSM.dayButtonText, selectedDays.includes(day) && stylesSM.dayButtonTextSelected]}>{day}</Text>
                  </TouchableOpacity>
                ))}
              </View>
              <View style={{ marginTop: 20 }}>
                <Text style={{ ...styles.TextTitle, marginBottom: 10 }}>Fréquence :</Text>
                <Picker
                  selectedValue={frequency}
                  style={{ borderRadius: 20, padding: 10 }}
                  onValueChange={(itemValue) => setFrequency(itemValue)}
                >
                  <Picker.Item label="Hebdomadaire" value="Hebdomadaire" />
                  <Picker.Item label="Mensuel" value="Mensuel" />
                </Picker>
              </View>
              {frequency === 'Mensuel' && (
                <View style={{ marginTop: 20 }}>
                  <Text style={{ ...styles.TextTitle, marginBottom: 10 }}>Choisir le nième jour :</Text>
                  <Picker
                    selectedValue={nthDay}
                    style={{ borderRadius: 20, padding: 10 }}
                    onValueChange={(itemValue) => setNthDay(itemValue)}
                  >
                    <Picker.Item label="1er" value="1" />
                    <Picker.Item label="2ème" value="2" />
                    <Picker.Item label="3ème" value="3" />
                    <Picker.Item label="dernier" value="last" />
                  </Picker>
                </View>
              )}
              {selectedDays.map(day => (
    <HourRangePicker 
        key={day} 
        hourMin={8} 
        hourMax={23} 
        hourStart={hourRanges[day] ? hourRanges[day][0] : 8} 
        hourEnd={hourRanges[day] ? hourRanges[day][1] : 10.5} 
        onChange={(values) => handleHourChange(day, values)} 
    />
))}

            </View>
          )}
         {category === 'Stage' && (
    <View style={styles.addActInputView}>
        <Text style={{ ...styles.TextTitle, marginBottom: 0 }}>Dates du stage:</Text>
        <MultiDatePicker
            dates={multipleDates}
            onDatesChange={(dates) => setMultipleDates(dates)}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ ...styles.TextTitle, marginBottom: 0 }}>Les horaires sont les mêmes tous les jours:</Text>
            <Switch
                value={sameHours}
                onValueChange={(value) => setSameHours(value)}
            />
        </View>
        {sameHours ? (
            <HourRangePicker hourMin={8} hourMax={23} hourStart={8} hourEnd={10.5} onChange={(values) => handleHourChange('stage', values)} />
        ) : (
            multipleDates.map((date, index) => (
                <View key={index} style={styles.addActInputView}>
                    <Text style={{ ...styles.TextTitle, marginBottom: 0 }}>Heure pour le {formatDate(date)}:</Text>
                    <HourRangePicker hourMin={8} hourMax={23} hourStart={8} hourEnd={10.5} onChange={(values) => handleHourChange(formatDate(date), values)} />
                </View>
            ))
        )}
    </View>
)}
          <View style={stylesSM.addActInputView}>
            <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
              <Text style={{ ...stylesSM.TextTitle, textAlign: 'center', marginBottom: 0 }}>Nombre de places : </Text>
              <TextInput
                style={inputStyle('capacity')}
                
                onChange={handleCapacityNumberChange}
                value={capacity}
                type="number"
                placeholder="Exemple: 30"
              />
            </View>
          </View>
          <View style={stylesSM.addActInputView}>
            <AgeRangePicker style={stylesSM.slidingPanel} ageMin={age[0]} ageMax={age[1]} />
          </View>
        </ScrollView>
        <Button title="Soumettre" onPress={() => {
    console.log('SOUMIS - placeId:', placeId);
    handleSubmit(placeId);
}} />

            </View>
        </Animated.View>
    );
};

  
  const Activity = ({
    day,
    time,
    room,
    teacher,
    address,
    price,
    title,
    key,
    colorKey,
  }) => (
    <View key={key + "View"} style={{ width: 245, marginLeft: 10 }}>
      <TouchableOpacity key={"activity"} style={styles.containerCard}>
        <Image
          source={require("../assets/inscImg1.png")}
          style={styles.imageAct}
        />
        <View style={{ margin: 5, width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text style={styles.nameAct}>{title}</Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginRight: 10,
              }}
            >
              <Image
                source={require("../assets/icon/clock-icon.png")}
                style={styles.iconStar}
              />
              <Text style={styles.nameAct}>{time}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text style={styles.nameAct}></Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginRight: 10,
              }}
            >
              <Image
                source={require("../assets/icon/clock-icon.png")}
                style={styles.iconStar}
              />
              <Text style={styles.nameAct}>
                {day === "Lun"
                  ? "Lundi"
                  : day === "Mar"
                    ? "Mardi"
                    : day === "Mer"
                      ? "Mercredi"
                      : day === "Jeu"
                        ? "Jeudi"
                        : day === "Ven"
                          ? "Vendredi"
                          : day === "Sam"
                            ? "Samedi"
                            : day === "Dim"
                              ? "Dimanche"
                              : day}
              </Text>
            </View>
          </View>
          <View style={{ marginTop: 5 }}>
            <Text style={styles.descAct}>{room}</Text>
            <Text style={styles.descAct}>{teacher}</Text>
          </View>
          <View style={{ marginTop: 5 }}>
            <Text style={styles.priceAct}>
              <b>{price} crédiits</b> par activités
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
  
  // ...
  
  
  const styles = StyleSheet.create({
    containerCard: {
      width: 238,
      minWidth: 250,
      maxWidth: 290,
      alignItems: "flex-start",
      gap: 5,
      flexShrink: 0,
      marginRight: 20,
    },
    addButton: {
      width: "100%",
      height: 407,
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0", // Couleur de fond arbitraire
      borderRadius: 20,
      borderColor: "rgba(111, 44, 247, 0.17)",
      borderWidth: 2,
      borderStyle: "dashed",
      padding: 10,
      backgroundColor: "rgba(111, 44, 247, 0.06)",
    },
    slidingPanel: {
      position: "fixed",
      zIndex:111,
      right: 0,
      top: 0,
      bottom: 0,
      maxWidth: "50%", // Ajustez selon vos besoins
      minWidth: 310, // Ajustez selon vos besoins
      backgroundColor: "white",
      padding: 20,
      // ... autres styles pour la mise en page et l'animation
    },
  
    addButtonText: {
      fontSize: 24,
      color: "black",
      textAlign: "center",
      fontFamily: "Avenir Next Bold",
      color: "rgba(111, 44, 247, 1)",
    },
    filtersContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: 10,
    },
    pickerStyle: {
      height: 50,
      width: 150,
    },
    imageAct: {
      width: "100%",
      aspectRatio:'2/3',
      borderRadius: 20,
    },
    nameAct: {
      color: "#000",
      fontFamily: "Avenir Next Regular",
      fontSize: 15,
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    iconStar: {
      width: 15,
      height: 15,
      marginRight: 5,
    },
    descAct: {
      color: "#808080",
      fontFamily: "Avenir Next Regular",
      fontSize: 15,
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    priceAct: {
      fontSize: 15,
      fontWeight: "bold",
    },
    grid: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderColor: "rgba(210 ,210 ,247, 0.7)",
      borderStyle: "dashed",
      borderBottomWidth: 1,
    },
    cell: {
      width: 100 / 4,
      height: 50,
      borderColor: "rgba(210 ,210 ,247, 0.7)",
      borderStyle: "dashed",
      borderRightWidth: 1,
    },
    hourLabel: {
      width: 100,
      height: 30,
      textAlign: "flex-start",
      color: "white",
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "rgba(210 ,210 ,247, 0.7)",
      paddingLeft: 5,
      backgroundColor: "rgb(162 125 237)",
    },
    headerText: {
      fontSize: 18,
      fontWeight: "bold",
      padding: 10,
      fontFamily: "Avenir Next Bold",
    },
    dayLabel: {
      height: 50,
      width: 100,
      textAlign: "center",
      borderRightWidth: 1,
      borderColor: "rgba(210 ,210 ,247, 0.7)",
      backgroundColor: "rgb(162 125 237)",
    },
    activity: {
      borderColor: "blue",
      backgroundColor: "rgba(111, 44, 247,0.3 ) ",
      justifyContent: "center",
      alignContent: "center",
    },
  });
  const stylesSM = StyleSheet.create({
    container: {
      backgroundColor: "white",
      padding: 20,
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign:'center',
      fontFamily:'Avenir Next Bold'
    },
    TextTitle: {
      marginBottom:10,
      fontFamily:'Avenir Next Regular',
      fontWeight:'800'
    },
    imageUploader: {
      borderWidth: 2,
      borderStyle: "dashed",
      borderColor: "lightgray",
      borderRadius: 12,
      justifyContent: "center",
      padding: 20,
      marginBottom: 20,
      maxWidth:'50%',
      minHeight:150,
      maxHeight:'20vh'
    },
    imageIcon: {
      width: 50,
      height: 50, // Adjust the size accordingly
      marginBottom: 8,
    },
    imageUploaderText: {
      color: "gray",
      marginBottom: 4,
    },
    imageUploaderSubtext: {
      color: "blue",
    },
    addActInputView:{
      paddingVertical:10,
    },
    input: {
      borderWidth: 1,
      borderColor: "#E6E6E6",
      borderRadius: 10,
      padding: 10,
      marginBottom: 20,
    },
    inputDesc: {
      borderWidth: 1,
      borderColor: "#E6E6E6",
      borderRadius: 10,
      padding: 10,
      marginBottom: 20,
      height:200
    },
    inputStudentLimit:{
      borderWidth: 1,
      borderColor: "#E6E6E6",
      borderRadius: 10,
      padding: 10,
      marginBottom: 20,
      width:100

    },
    dayPicker: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
      marginTop: 20,
    },
    dayButton: {
      borderWidth: 1,
      borderColor: "#E6E6E6",
      borderRadius: 38,
      padding: 10,
    },
    dayButtonSelected: {
      backgroundColor: "#6F2CF7",
      borderColor: "#6F2CF7",
    },
    dayButtonText: {
      color: "black",
    },
    dayButtonTextSelected: {
      color: "white",
    },
    // Add more styles for other components as needed
  });
  
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [selectedDay, setSelectedDay] = useState('Tous');
  const [selectedRoom, setSelectedRoom] = useState('Tous');
  const [selectedTeacher, setSelectedTeacher] = useState('Tous');
  const daysMappingInverse = {
    'Lundi': 'Lun',
    'Mardi': 'Mar',
    'Mercredi': 'Mer',
    'Jeudi': 'Jeu',
    'Vendredi': 'Ven',
    'Samedi': 'Sam',
    'Dimanche': 'Dim'
  };
  // États pour les données uniques de chaque filtre
  const [categories, setCategories] = useState([]);
  const [days, setDays] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [filteredActivities, setFilteredActivities] = useState([]);
  const [totalActivitiesCount, setTotalActivitiesCount] = useState(0);
  const AddActivityButton = ({ onPress }) => {
    const scaleValue = useRef(new Animated.Value(0)).current;
    
    const prevItemsPerRowRef = useRef();
    useEffect(() => {
      console.log("ListingActivitiesHourDashboard placeId : "+placeId)
      if (prevItemsPerRowRef.current !== itemsPerRow) {
        scaleValue.setValue(0); // Réinitialisez la valeur d'échelle
        Animated.timing(scaleValue, {
          toValue: 1,
          duration: 300,
          delay: 500,
          useNativeDriver: true,
        }).start();
      }
    
      prevItemsPerRowRef.current = itemsPerRow;
    }, [itemsPerRow]);
    return (
      <Animated.View style={{ height:'100%',transform: [{ scale: scaleValue }] }}>
      <View style={{ height:'100%',width: ITEM_WIDTH,paddingLeft:10,paddingRight:10}}>
      <TouchableOpacity onPress={openAddPanel} style={styles.addButton}>
        <Text style={{...styles.addButtonText,fontSize:45}}>+</Text>
        <Text  style={{...styles.addButtonText,fontSize:18}}>Ajouter une activité</Text>

      </TouchableOpacity>
        </View>
        </Animated.View >
    );
  };
  useEffect(() => {
    try {
        // Calculer le nombre total d'activités au chargement
        let totalCount = 0;

        if (profilInfo && profilInfo.places) {
            Object.values(profilInfo.places).forEach(place => {
                if (place && place.calendar) {
                    totalCount += place.calendar.length;
                } else {
                    console.error("Lieu mal formé ou calendrier manquant", place);
                }
            });
            setTotalActivitiesCount(totalCount);
        } else {
            console.error("profilInfo ou places est indéfini ou vide", profilInfo);
        }

    } catch (error) {
        console.error("Erreur lors du calcul du nombre total d'activités", error);
    }
}, [profilInfo]);

useEffect(() => {
  try {
    const dayOrder = { 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6, 'SUN': 7 };
    let tempFilteredActivities = [];

    if (profilInfo && profilInfo.places) {
      console.log("profilInfo.places : ", JSON.stringify(profilInfo.places));

      // Utilisez Object.values pour parcourir les places sans se soucier de la clé dynamique
      Object.values(profilInfo.places).forEach((place, placeIndex) => {
        if (place && place.calendar) {
            place.calendar.forEach((activity, activityIndex) => {
                console.log(`Processing activity ${activityIndex} in place ${placeIndex}`, activity);
                
                // Ajout de valeurs par défaut (placeholders) pour les champs manquants
                const day = activity.day || 'UNKNOWN_DAY';
                const time = activity.time || '00:00 - 00:00';
                const room = activity.room || 'UNKNOWN_ROOM';
                const category = activity.category || 'UNKNOWN_CATEGORY';
                const teacherId = (activity.teacher && activity.teacher.id) ? activity.teacher.id : 'UNKNOWN_TEACHER';
    
                // Mettez à jour l'activité avec les valeurs par défaut si nécessaire
                activity.day = day;
                activity.time = time;
                activity.room = room;
                activity.category = category;
                if (!activity.teacher) {
                    activity.teacher = { id: teacherId, name: 'UNKNOWN_TEACHER' };
                }
    
                const dayInEnglish = selectedDay === 'Tous' ? 'all' : daysMappingInverse[selectedDay];
                const isDayMatch = selectedCategory === "Activité récurrente" ? (dayInEnglish === 'all' || activity.day === dayInEnglish) : true;
                const isRoomMatch = selectedRoom === 'Tous' || activity.room === selectedRoom;
                const isTeacherMatch = selectedTeacher === 'Tous' || activity.teacher.id === selectedTeacher;
                const isCategoryMatch = selectedCategory === 'Tous' || activity.category === selectedCategory;
    
                if (isDayMatch && isRoomMatch && isTeacherMatch && isCategoryMatch) {
                    tempFilteredActivities.push(activity);
                } else {
                    console.error("Activité mal formée malgré les placeholders", activity);
                }
            });
        } else {
            console.error("Lieu mal formé ou calendrier manquant", place);
        }
    });
    
    } else {
      console.error("profilInfo ou places est indéfini ou vide", profilInfo);
    }

    // Trier les activités par jour, puis par heure
    tempFilteredActivities.sort((a, b) => {
      const dayA = dayOrder[a.day];
      const dayB = dayOrder[b.day];
      if (dayA !== dayB) return dayA - dayB;

      const timeA = parseInt(a.time.split(" - ")[0].replace(":", ""), 10);
      const timeB = parseInt(b.time.split(" - ")[0].replace(":", ""), 10);
      return timeA - timeB;
    });

    tempFilteredActivities.push({ isAddButton: true });
    setFilteredActivities(tempFilteredActivities);
    setIsLoading(false);
  } catch (error) {
    console.error("Erreur dans useEffect lors du filtrage des activités", error);
  }
}, [profilInfo, selectedDay, selectedRoom, selectedTeacher, selectedCategory]);





useEffect(() => {
  try {
      const daysSet = new Set(['Tous']);
      const roomsSet = new Set(['Tous']);
      const categoriesSet = new Set(['Tous']);
      const teachersSet = new Set(); // Utiliser un Set pour garantir l'unicité

      if (profilInfo && profilInfo.places) {
          Object.values(profilInfo.places).forEach(place => {
              if (place && place.calendar) {
                  place.calendar.forEach(activity => {
                      // Vérifiez que les champs nécessaires sont présents
                      if (activity && activity.day && daysMapping[activity.day] && activity.room && activity.category) {
                          daysSet.add(daysMapping[activity.day]);
                          roomsSet.add(activity.room);
                          categoriesSet.add(activity.category);
                      } else {
                          console.error("Activité mal formée ou données manquantes dans le calendrier", activity);
                      }
                  });
              } else {
                  console.error("Lieu mal formé ou calendrier manquant", place);
              }
          });
          console.log("roomsSet : "+JSON.stringify(roomsSet))
      } else {
          console.error("profilInfo ou places est indéfini ou vide", profilInfo);
      }

      if (profilInfo && profilInfo.team) {
          profilInfo.team
              .filter(member => member && member.role && Array.isArray(member.role) && member.role.includes(2))
              .forEach(teacher => {
                  if (teacher && teacher.id && teacher.name) {
                      teachersSet.add(JSON.stringify({ id: teacher.id, name: teacher.name })); // Convertir en chaîne pour maintenir l'unicité par id et name
                  } else {
                      console.error("Enseignant mal formé ou données manquantes", teacher);
                  }
              });
      } else {
          console.error("profilInfo ou team est indéfini ou vide", profilInfo);
      }

      // Mettez à jour les états avec les valeurs uniques
      setDays(Array.from(daysSet));
      setRooms(Array.from(roomsSet));
      setCategories(Array.from(categoriesSet));
      // Convertir le Set en tableau d'enseignants uniques
      setTeachers([{ id: 'Tous', name: 'Tous' }, ...Array.from(teachersSet).map(teacher => JSON.parse(teacher))]);
      setAnimateListItems(true);

  } catch (error) {
      console.error("Erreur dans useEffect lors du traitement des données de profilInfo", error);
  }
}, [profilInfo]);



const groupByCity = {};
try {
  if (profilInfo && profilInfo.places) {
      Object.keys(profilInfo.places).forEach(index => {
          const place = profilInfo.places[index];
          const city = place && place.city;
          if (city) {
              if (!groupByCity[city]) {
                  groupByCity[city] = [];
              }
              groupByCity[city].push(place);
          } else {
              console.error("Ville manquante ou place mal formée", place);
          }
      });
  } else {
      console.error("profilInfo ou places est indéfini ou vide", profilInfo);
  }
} catch (error) {
  console.error("Erreur lors du groupement par ville dans groupByCity", error);
}


  // Calculer le nombre d'éléments par ligne en fonction de la largeur de l'écran
  const calculateItemsPerRow = (width) => {
    const ITEM_WIDTH = 238; // Largeur de l'élément + marginRight
    return Math.floor((width-360) / ITEM_WIDTH);
  };

  const [itemsPerRow, setItemsPerRow] = useState(calculateItemsPerRow(screenWidth));
  const keyExtractor = (item, index) => {
    return item.id ? item.id.toString() : `special-${index}`;
  };
  useEffect(() => {
    const updateLayout = () => {
      const newWidth = Dimensions.get('window').width;
      // Seuil pour déterminer si le changement de largeur est significatif
      const currentTotalWidth = itemsPerRow * ITEM_WIDTH; // largeur totale actuelle des éléments
    const widthDifference = Math.abs(screenWidth-360 - currentTotalWidth); // différence de largeur
; // Vous pouvez ajuster cette valeur en fonction de vos besoins
      console.log('widthDifference : '+widthDifference)
      if (Math.abs(newWidth - screenWidth) > widthDifference) {
        setScreenWidth(newWidth);
        let newCalc = calculateItemsPerRow(newWidth);
        if(itemsPerRow !== newCalc){
          setItemsPerRow(newCalc);
        }
      }
    };
  
    Dimensions.addEventListener('change', updateLayout);
    return () => Dimensions.removeEventListener('change', updateLayout);
  }, [screenWidth, itemsPerRow]);
   

  const renderItem = ({ item, index }) => {
    if (item.isAddButton) {
        return <AddActivityButton style={{ height: '100%' }} onPress={() => { /* Logique pour ajouter une activité */ }} />;
    }

    return <AnimatedListItem item={item} index={index} />;
};
const AnimatedListItem = ({ item: activity, index }) => {
  const scaleValue = useRef(new Animated.Value(0)).current;
  const prevItemsPerRowRef = useRef(itemsPerRow);

  useEffect(() => {
    if (animateListItems) {
        scaleValue.setValue(0); // Réinitialisez la valeur d'échelle
        Animated.timing(scaleValue, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
        }).start();
    } else {
        scaleValue.setValue(1); // Assurez-vous que les éléments sont entièrement visibles
    }
}, [animateListItems]);
const formatTime = (decimalTime) => {
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);
  return ` ${hours}h${minutes.toString().padStart(2, '0')}`;
};
const formatDateUnique = (isoDate) => {
  const date = new Date(isoDate);
  const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  
  const dayName = daysOfWeek[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];

  return `${dayName} ${day} ${month}`;
};
useEffect(() => {
  console.log("activity : "+JSON.stringify(activity))
}, [activity]);

  // Utiliser les props de l'activité
  return (
      <Animated.View style={{ transform: [{ scale: scaleValue }] }}>
          <View style={{ width: 265, marginBottom: 40 }}>
              <TouchableOpacity onPress={() => openEditPanel(activity)} style={styles.containerCard}>
                  <Image source={require('../assets/judo.jpeg')} style={styles.imageAct} />
                  <View style={{ margin: 5, width: '100%' }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                          <Text style={styles.nameAct}>{activity.group}</Text>
                      </View>
                      <View style={{ justifyContent: 'space-between', width: '100%' }}>
                          {activity.age[1] == null ?
                              <Text style={styles.nameAct}>Dès {activity.age[0]} ans</Text>
                              :
                              <Text style={styles.nameAct}>De {activity.age[0]} à {activity.age[1]} ans</Text>
                          }
                          {activity.category == 'Activité récurrente' ?
                              <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                  <Text style={styles.nameAct}>
                                      {daysMapping[activity.day] || activity.day}
                                  </Text>
                                  <Text style={styles.nameAct}>
                                    {activity && activity.hourRanges && activity.hourRanges[0] && activity.hourRanges[0].hours
                                      ? `${formatTime(activity.hourRanges[0].hours[0])}-${formatTime(activity.hourRanges[0].hours[1])}`
                                      : '0h00-0h00'}
                                  </Text>
                                </View>
                              :
                              activity.category == 'Activité unique' ?
                              <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                  <Text style={styles.nameAct}>
                                  {formatDateUnique(activity.day)}
                                  </Text>
                                  <Text style={styles.nameAct}>
                                    {activity && activity.hourRanges && activity.hourRanges[0] && activity.hourRanges[0].hours
                                      ? `${formatTime(activity.hourRanges[0].hours[0])}-${formatTime(activity.hourRanges[0].hours[1])}`
                                      : '0h00-0h00'}
                                  </Text>
                                </View>
                                  :
                                  activity.category == 'Stage' ?
                                      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                          <Text style={styles.nameAct}>  Luc : a faire </Text>
                                      </View>
                                      : null
                          }
                      </View>
                      <View style={{ marginTop: 5 }}>
                          <Text style={styles.descAct}>{activity.room}</Text>
                          <Text style={styles.descAct}>{activity.teacher.name}</Text>
                      </View>
                      <View style={{ marginTop: 5 }}>
                          <Text style={styles.priceAct}><b>{activity.price} crédits</b> par activités</Text>
                      </View>
                  </View>
              </TouchableOpacity>
          </View>
      </Animated.View>
  );
};
  
  useEffect(() => {
      
  
    console.log('Dimensions.get(window).width : '+ Dimensions.get('window').width)
  }, [Dimensions.get('window').width]);


  if (isLoading) {
    // Afficher l'indicateur de chargement
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }
  return (
      <View style={{  borderRadius: 50}}>
        <View style={{padding:30 }}>
        <Text style={styles.headerText}>
            {profilInfo?.name} compte aujourd'hui, {totalActivitiesCount} activités
        </Text>
        <View style={styles.filtersContainer}>
        {screenWidth < 760 ? (
                        <View>
                        <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
                            <Picker
                                style={{ borderWidth: 0, padding: 5 }}
                                selectedValue={selectedCategory}
                                onValueChange={(itemValue) => setSelectedCategory(itemValue)}
                            >
                                {categories.map((category, index) => (
                                    <Picker.Item
                                        key={`${category}-${index}`}
                                        label={category}
                                        value={category}
                                    />
                                ))}
                            </Picker>
                        </View>
                        {selectedCategory === "Activité récurrente" && (
                            <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
                                <Picker
                                    style={{ borderWidth: 0, padding: 5 }}
                                    itemStyle={{ backgroundColor: "grey", color: "blue", fontFamily: "Avenir Next Bold", fontSize: 17 }}
                                    selectedValue={selectedDay}
                                    onValueChange={(itemValue) => setSelectedDay(itemValue)}
                                >
                                    {daysOrdered.map((day, index) => <Picker.Item key={`${day}-${index}`} label={day} value={day} />)}
                                </Picker>
                            </View>
                        )}
                        <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
                            <Picker
                                style={{ borderWidth: 0, padding: 5 }}
                                selectedValue={selectedRoom}
                                onValueChange={(itemValue) => setSelectedRoom(itemValue)}
                            >
                                {rooms.map((room, index) => <Picker.Item key={`${room}-${index}`} label={room} value={room} />)}
                            </Picker>
                        </View>
                        <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
                            <Picker
                                style={{ borderWidth: 0, padding: 5 }}
                                selectedValue={selectedTeacher}
                                onValueChange={(itemValue) => setSelectedTeacher(itemValue)}
                            >
                                {teachers.map((teacher, index) => (
                                    <Picker.Item
                                        key={`${teacher.id}-${index}`}
                                        label={teacher.name+teacher.lastname}
                                        value={teacher.id}
                                    />
                                ))}
                            </Picker>
                        </View>
                    </View>
                    ) : (
                        <View style={{ flexDirection: 'row' }}>
    <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
        <Picker
            style={{ borderWidth: 0, padding: 5 }}
            selectedValue={selectedCategory}
            onValueChange={(itemValue) => setSelectedCategory(itemValue)}
        >
            {categories.map((category, index) => (
                <Picker.Item
                    key={`${category}-${index}`}
                    label={category}
                    value={category}
                />
            ))}
        </Picker>
    </View>
    {selectedCategory === "Activité récurrente" && (
        <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
            <Picker
                style={{ borderWidth: 0, padding: 5 }}
                itemStyle={{ backgroundColor: "grey", color: "blue", fontFamily: "Avenir Next Bold", fontSize: 17 }}
                selectedValue={selectedDay}
                onValueChange={(itemValue) => setSelectedDay(itemValue)}
            >
                {daysOrdered.map((day, index) => <Picker.Item key={`${day}-${index}`} label={day} value={day} />)}
            </Picker>
        </View>
    )}
    <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
        <Picker
            style={{ borderWidth: 0, padding: 5 }}
            selectedValue={selectedRoom}
            onValueChange={(itemValue) => setSelectedRoom(itemValue)}
        >
            {rooms.map((room, index) => <Picker.Item key={`${room}-${index}`} label={room} value={room} />)}
        </Picker>
    </View>
    <View style={{ borderWidth: 1, marginRight: 10, borderRadius: 20, overflow: 'hidden' }}>
        <Picker
            style={{ borderWidth: 0, padding: 5 }}
            selectedValue={selectedTeacher}
            onValueChange={(itemValue) => setSelectedTeacher(itemValue)}
        >
            {teachers.map((teacher, index) => (
                <Picker.Item
                    key={`${teacher.id}-${index}`}
                    label={teacher.lastname ? teacher.name+' '+teacher.lastname : teacher.name }
                    value={teacher.id}
                />
            ))}
        </Picker>
    </View>
</View>
                    )}
        </View>
          <View style={{justifyContent:'center',alignItems:'center'}}>
            
           <View style={{ flexDirection: 'row',justifyContent:'center',alignItems:'center' }}>
           <FlatList
  data={filteredActivities}
  renderItem={renderItem}
  keyExtractor={keyExtractor}
  numColumns={itemsPerRow}
  key={`flatlist-${itemsPerRow}`}
/>
           </View>
           </View>
                  </View>
                  {
            isPanelVisible ? 
            <TouchableWithoutFeedback onPress={() => setIsPanelVisible(false)} style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',zIndex:49,position:'absolute'}}>
              <View style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',zIndex:49,position:'absolute'}}></View>
            </TouchableWithoutFeedback>
            :
            null
           }
                  <ActivityPanel
        isVisible={isPanelVisible}
        onClose={closePanel}
        activityData={currentActivityData}
        activities={profilInfo}
        placeId={placeId}
      />
      </View>
  );
}


const styles = StyleSheet.create({
  
    containerCard: {
      width: 290,
      height: 407,
      minWidth: 250,
      maxWidth: 290,
      alignItems: 'flex-start',
      gap: 12,
      flexShrink: 0,
      marginRight: 20
  },
  addButton: {
    width: '100%',
    height: 407,
    marginBottom:10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0', // Couleur de fond arbitraire
    borderRadius: 20,
    borderColor:'rgba(111, 44, 247, 0.17)',
    borderWidth:2,
    borderStyle: 'dashed',
    padding:10,
    backgroundColor:'rgba(111, 44, 247, 0.06)'
  },
  slidingPanel: {
    position: 'fixed',
    right: -50,
    top: 86,
    maxWidth: 315, // Ajustez selon vos besoins
    minWidth: 300, // Ajustez selon vos besoins
    backgroundColor: 'white',
    padding: 20,
    zIndex:100
    // ... autres styles pour la mise en page et l'animation
  },

  addButtonText: {
    fontSize: 24,
    color: 'black',
    textAlign:'center',
    fontFamily:'Avenir Next Bold',
    color:'rgba(111, 44, 247, 1)'
  },
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 10,
  },
  pickerStyle: {
    height: 50,
    width: 150,
  },
  imageAct: {
      width: '100%',
      height: '60%',
      borderRadius: 20,
  },
  nameAct: {
      color: '#000',
      fontFamily: 'Avenir Next Regular',
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
  },
  iconStar: {
      width: 15,
      height: 15,
      marginRight: 5
  },
  descAct: {
      color: '#808080',
      fontFamily: 'Avenir Next Regular',
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
  },
  priceAct: {
      fontSize: 15,
      fontWeight: 'bold',
  },
    grid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderColor: 'rgba(210 ,210 ,247, 0.7)',
      borderStyle: 'dashed',
      borderBottomWidth: 1,
    },
    cell: {
      width: 100/4, 
      height: 50, 
      borderColor: 'rgba(210 ,210 ,247, 0.7)',
      borderStyle: 'dashed',
      borderRightWidth: 1,
    },
    hourLabel: {
      width: 100,
      height:30,
      textAlign: 'flex-start',
      color:'white',
      borderLeftWidth:1,
      borderBottomWidth:1,
      borderColor: 'rgba(210 ,210 ,247, 0.7)',
      paddingLeft:5,
      backgroundColor:'rgb(162 125 237)'
  
    },
    headerText: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: 10,
        fontFamily:'Avenir Next Bold'
      },
    dayLabel: {
      height: 50,
      width:100,
      textAlign: 'center',
      borderRightWidth:1,
      borderColor: 'rgba(210 ,210 ,247, 0.7)',
      backgroundColor:'rgb(162 125 237)'
  
    }, 
    activity: {
      borderColor: 'blue',
      backgroundColor:'rgba(111, 44, 247,0.3 ) ',
      justifyContent:'center',
      alignContent:'center'
    }
  });
  const stylesSM = StyleSheet.create({
    container: {
      backgroundColor: 'white',
      padding: 20,
    },
    header: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 20,
    },
    imageUploader: {
      borderWidth: 2,
      borderStyle: 'dashed',
      borderColor: 'lightgray',
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
      marginBottom: 20,
      overflow:'hidden',
      maxWidth:'50%',
      minHeight:150,
      aspectRatio:'2/3'
    },
    imageIcon: {
      width: 50,
      height: 50, // Adjust the size accordingly
      marginBottom: 8,
    },
    imageUploaderText: {
      color: 'gray',
      marginBottom: 4,
    },
    imageUploaderSubtext: {
      color: 'blue',
    },
    addActInputView:{
      paddingVertical:40,
      marginVertical:20
    },
    input: {
      borderWidth: 1,
      borderColor: 'gray',
      borderRadius: 8,
      padding: 10,
      marginBottom: 20,
    },
    dayPicker: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    dayButton: {
      borderWidth: 1,
      borderColor: 'gray',
      borderRadius: 8,
      padding: 10,
    },
    
    textH4: { 
      fontFamily:'Avenir Next Bold',
      fontSize:15

    },
    textH4g: {
      fontFamily:'Avenir Next Regular',
      fontSize:15,
      color:'#7B7B7B'

    },
    dayButtonSelected: {
      backgroundColor: 'purple',
      borderColor: 'purple',
    },
    dayButtonText: {
      color: 'white',
    },
    // Add more styles for other components as needed
  });
  
export default ListingActivitiesHourDashboard;
