import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, FlatList, StyleSheet, Text, Dimensions, TextInput, TouchableWithoutFeedback, Picker } from 'react-native';
import TeamPanel from './TeamPanel';
import renderMemberItem from './renderMemberItem';
import { storage } from '../firebase'; // Importez votre configuration Firebase
import { ref, getDownloadURL } from 'firebase/storage';
const TeamListing = ({ profilInfo, privilege }) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [currentMemberData, setCurrentMemberData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [membersWithPhotos, setMembersWithPhotos] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('Tous');

  const openEditPanel = (member) => {
    setCurrentMemberData(member);
    setIsPanelVisible(true);
  };

  const openAddPanel = () => {
    setCurrentMemberData(null);
    setIsPanelVisible(true);
  };

  const closePanel = () => {
    setIsPanelVisible(false);
  };

  const calculateItemsPerRow = (width) => {
    const ITEM_WIDTH = 250;
    return Math.floor((width - 360) / ITEM_WIDTH);
  };

  const [itemsPerRow, setItemsPerRow] = useState(calculateItemsPerRow(screenWidth));

  const updateLayout = () => {
    const newWidth = Dimensions.get('window').width;
    const newCalc = calculateItemsPerRow(newWidth);
    setScreenWidth(newWidth);
    setItemsPerRow(newCalc);
  };

  useEffect(() => {
    Dimensions.addEventListener('change', updateLayout);
    return () => Dimensions.removeEventListener('change', updateLayout);
  }, [screenWidth, itemsPerRow]);

  const fetchProfilePhotos = async () => {
    const membersWithPhotos = await Promise.all(profilInfo.map(async (member) => {
      try {
        if (!member || !member.id) {
          return member;
        }
        const storageRef = ref(storage, `images/${member.id}`);
        const url = await getDownloadURL(storageRef);
        return { ...member, profilePicture: url };
      } catch (error) {
        console.error(`Error fetching image for member ${member?.id}:`, error);
        return member;
      }
    }));
    setMembersWithPhotos(membersWithPhotos);
  };

  useEffect(() => {
    if (profilInfo && Array.isArray(profilInfo)) {
      fetchProfilePhotos();
    }
  }, [profilInfo]);

  const filteredMembers = membersWithPhotos.filter(member => 
    (selectedRole === 'Tous' || member?.role?.includes(parseInt(selectedRole))) &&
    (searchQuery === '' || member?.name?.toLowerCase().includes(searchQuery.toLowerCase()) || member?.lastname?.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const roleMembersMap = {};
  filteredMembers.forEach(member => {
    if (member?.role && Array.isArray(member.role)) {
      member.role.forEach(role => {
        if (!roleMembersMap[role]) {
          roleMembersMap[role] = [];
        }
        roleMembersMap[role].push(member);
      });
    }
  });

  const keyExtractor = (item, index) => {
    return item.id ? item.id.toString() : `special-${index}`;
  };

  const renderRoleSection = (role) => (
    <View key={role} style={styles.roleSection}>
      <Text style={styles.roleText}>{privilege[role]}</Text>
      <FlatList
        data={roleMembersMap[role]}
        renderItem={({ item }) => renderMemberItem({ item, openEditPanel, privilege })}
        keyExtractor={keyExtractor}
        numColumns={itemsPerRow}
        key={`flatlist-${itemsPerRow}-${role}`}
      />
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
        <View style={{width:'100%',height:'100%',padding:10}}>
        <Text style={styles.roleText}>Équipe</Text>
        <View style={{flexDirection:'row',justifyContent:'space-between',padding:10}}>
          <View style={styles.searchContainer}>
            <TextInput
              style={styles.searchInput}
              placeholder="Rechercher un membre"
              value={searchQuery}
              onChangeText={setSearchQuery}
            />
            <Picker
              selectedValue={selectedRole}
              style={styles.picker}
              onValueChange={(itemValue) => setSelectedRole(itemValue)}
            >
              <Picker.Item label="Postes" value="Tous" />
              {Object.entries(privilege).map(([key, role]) => (
                <Picker.Item key={key} label={role} value={key} />
              ))}
            </Picker>
          </View>
          <TouchableOpacity onPress={openAddPanel} style={styles.addButton}>
            <Text style={styles.addButtonText}>Ajouter un membre</Text>
          </TouchableOpacity>
        </View>
        {Object.keys(roleMembersMap).sort().map(renderRoleSection)}
      </View>
      <TeamPanel
        isVisible={isPanelVisible}
        onClose={closePanel}
        memberData={currentMemberData}
        privilege={privilege}
      />
      {
        isPanelVisible && (
          <TouchableWithoutFeedback onPress={() => setIsPanelVisible(false)}>
            <View style={styles.overlay} />
          </TouchableWithoutFeedback>
        )
      }
    </View>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  searchInput: {
    width:360,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 25,
    padding: 10,
    marginRight: 10,
    backgroundColor:'white',
  },
  picker: {
    width: 150,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 25,
    padding:10,
  },
  addButton: {
    padding: 10,
    backgroundColor: '#6F2CF7',
    borderRadius: 25,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    margin: 10,
  },
  addButtonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  roleText: {
    fontWeight: 'bold',
    fontSize: 18,
    margin: 10,
  },
  roleSection: {
    marginVertical: 10,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 50,
  },
});

export default TeamListing;
