 import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { StyleSheet, View, Text, TouchableOpacity,ScrollView,Dimensions,Platform  } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import ListingActivitiesHour from '../components/ListingActivitiesHour.js';
import CustomImage from '../components/customImage.js';
import ListingActivitiesHourDashboard from '../components/ListingActivitiesHourDashboard.js';
// Composant pour le profil utilisateur et la navigation
import { getFunctions, httpsCallable } from 'firebase/functions';
import Svg, {Path, G} from 'react-native-svg';
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence
import { onAuthStateChanged,signOut,getAuth  } from "firebase/auth";
import {Notification,Message,Category,LogoutCurve,Element4, Magicpen, People, Notepad2, FavoriteChart, Home3, ArchiveBook, Document,CalendarTick, HierarchySquare2} from 'iconsax-react-native';
import activities from '../components/Places.js';
import firebase from '../firebase.js'; // Remplacer par votre chemin d'importation Firebase
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import TeamListing from '../components/TeamListing.js';




const Dash = () => {
    return(
        <View>
            
        </View>
    )
}

const Message1 = () => {
    return(
        <View>
            
        </View>
    )
}
const HoverableTouchableOpacity = ({ onPress, hoverStyle, style, icon, text, page, setPage, selectedPage,colorIcon }) => {
    const [isHovered, setIsHovered] = useState(false);
    const combinedStyle = isHovered ? [styles.navItem, hoverStyle] : styles.navItem;
    const IconComponent = icon; // Suppose que c'est un composant

    return (
      <TouchableOpacity
        style={page === selectedPage ? styles.navItemSelected : combinedStyle}
        onPress={() => setPage(page)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconComponent variant='Bold' size={25} color={`${colorIcon}`}/>
        <Text style={page === selectedPage ? styles.navTextSelected : styles.navText}>{text}</Text>
      </TouchableOpacity>
    );
};
const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log("Utilisateur déconnecté");
      navigation.navigate('Home')
      // Redirigez l'utilisateur vers la page de connexion ou autre
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };
const HoverableTouchableOpacityDeco = ({ hoverStyle, style, }) => {
    const [isHovered, setIsHovered] = useState(false);
    const combinedStyle = isHovered ? [styles.navDeco, hoverStyle] : styles.navDeco;

    return (
      <TouchableOpacity
        style={combinedStyle}
        onPress={() => handleSignOut()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LogoutCurve size={25} color={`red`}/>
        <Text style={{...styles.navText,color:'black'}}>Déconnexion</Text>
      </TouchableOpacity>
    );
};

const Calendar = ({profilFB,activitiesInfoArray}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [displayMode, setDisplayMode] = useState('Semaine');
    const [allEvents, setAllEvents] = useState([]);
    
    const daysInMonth = (year, month) => {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    };

    const generateRecurringEvents = (event, startDate, endDate) => {
        const recurringEvents = [];
        const eventDayIndex = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"].indexOf(event.day);

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (event.frequency === "Hebdomadaire" && currentDate.getDay() === eventDayIndex) {
                recurringEvents.push({
                    ...event,
                    day: currentDate.toLocaleDateString('fr-FR'),
                });
            } else if (event.frequency === "Mensuel" && currentDate.getDay() === eventDayIndex) {
                const nthOccurrence = Math.ceil(currentDate.getDate() / 7);
                if (nthOccurrence.toString() === event.nthDay) {
                    recurringEvents.push({
                        ...event,
                        day: currentDate.toLocaleDateString('fr-FR'),
                    });
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return recurringEvents;
    };
    
    
    const CalendarHeader = ({currentDate, setCurrentDate, onModeChange, currentMode })  => {
        const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

        const modes = ["Mois", "Semaine", "Jour"];
        const changeMonth = (offset) => {
            const newDate = new Date(currentDate);
            newDate.setMonth(currentDate.getMonth() + offset);
            setCurrentDate(newDate);
        };
        
        const changeWeek = (offset) => {
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + offset * 7);
            setCurrentDate(newDate);
        };
        
        const changeDay = (offset) => {
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + offset);
            setCurrentDate(newDate);
        };
        const handleNavigation = (offset) => {
            switch (displayMode) {
                case 'Jour':
                    changeDay(offset);
                    break;
                case 'Semaine':
                    changeWeek(offset);
                    break;
                case 'Mois':
                default:
                    changeMonth(offset);
                    break;
            }
        };
        const getHeaderTitle = () => {
            switch (displayMode) {
                case 'Jour':
                    return `${dayNames[currentDate.getDay()]}, ${currentDate.getDate()} ${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
                case 'Semaine':
                    // Calculez ici la plage de la semaine en fonction de currentDate
                    // Par exemple: "12 - 18 Mars 2024"
                    return `Semaine du ${calculateWeekRange(currentDate)}`;
                case 'Mois':
                default:
                    return `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
            }
        };
        const calculateWeekRange = (date) => {
            // Trouvez le lundi de la semaine
            const monday = new Date(date);
            monday.setDate(monday.getDate() - monday.getDay() + 1);
        
            // Trouvez le dimanche de la semaine
            const sunday = new Date(monday);
            sunday.setDate(sunday.getDate() + 6);
        
            const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        
            // Formattez la chaîne pour la plage de la semaine
            const formatDay = (d) => `${d.getDate()} ${monthNames[d.getMonth()]}`;
            return `${formatDay(monday)} - ${formatDay(sunday)}`;
        };
        return (
            <View style={styles.calendarHeader}>
                
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            {/* Boutons de navigation */}
            <TouchableOpacity style={styles.navigationButton} onPress={() => handleNavigation(-1)}>
                <Ionicons name="chevron-back-circle-outline" size={25} />
            </TouchableOpacity>
            <Text style={styles.calendarTitle}>
                {getHeaderTitle()}
            </Text>
            <TouchableOpacity style={styles.navigationButton} onPress={() => handleNavigation(1)}>
                <Ionicons name="chevron-forward-circle-outline" size={25} />
            </TouchableOpacity>
        </View>
                <View style={{flexDirection:'row'}}>
                {modes.map(mode => (
                    <TouchableOpacity
                        key={mode}
                        style={[styles.modeButton, currentMode === mode && styles.activeModeButton]}
                        onPress={() => onModeChange(mode)}
                    >
                        <Text>{mode}</Text>
                    </TouchableOpacity>
                ))}
            </View>
            </View>
        );
    }
    const getDayName = (date) => {
        const dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        return dayNames[date.getDay()];
    };
    const getEventsForWeekTimeSlot = (dayIndex, hour, minutes, weekStartDate) => {
        const eventsForWeek = allEvents.flatMap(event => {
            if (event.frequency === "Hebdomadaire" || event.frequency === "Mensuel") {
                const weekEndDate = new Date(weekStartDate);
                weekEndDate.setDate(weekEndDate.getDate() + 6);
                return generateRecurringEvents(event, weekStartDate, weekEndDate);
            }
            return [event];
        });
    
        return eventsForWeek.filter(event => {
            const eventDate = new Date(event.day.split('/').reverse().join('-'));
            const eventDay = eventDate.getDay();
    
            if (eventDay !== dayIndex) return false;
    
            const [eventStart, eventEnd] = event.time.split(' - ').map(time => hourToNumber(time));
            const eventStartTime = (Math.floor(eventStart) === hour) && (minutes >= (eventStart % 1) * 60);
            const eventEndTime = (Math.floor(eventEnd) > hour) || (minutes < (eventEnd % 1) * 60 && Math.floor(eventEnd) === hour);
    
            return eventStartTime || eventEndTime;
        });
    };
    
    const getEventsForTimeSlot = (dayIndex, hour, minutes, weekStartDate) => {
        console.log(`\n--- Début de getEventsForTimeSlot ---`);
        
        // Supprimer les doublons avant le traitement
        const uniqueEvents = removeDuplicateEvents(allEvents);
        
        console.log(`uniqueEvents : ${JSON.stringify(uniqueEvents)}`);
        
        const eventsForWeek = uniqueEvents.flatMap(event => {
            if (event.frequency === "Hebdomadaire") {
                // Gérer les événements hebdomadaires
                const weekEndDate = new Date(weekStartDate);
                weekEndDate.setDate(weekEndDate.getDate() + 6);
                const recurringEvents = generateRecurringEvents(event, weekStartDate, weekEndDate);
                console.log(`Événement récurrent généré pour : ${event.name}, Période : ${weekStartDate.toLocaleDateString('fr-FR')} - ${weekEndDate.toLocaleDateString('fr-FR')}`);
                return recurringEvents;
    
            } else if (event.frequency === "Mensuel") {
                // Gérer les événements mensuels (ex: 2ème jeudi de chaque mois)
                const nthOccurrence = Math.ceil(new Date(weekStartDate).getDate() / 7); // Trouver la N-ième occurrence
                const isCorrectNthDay = nthOccurrence.toString() === event.nthDay;
    
                if (isCorrectNthDay && event.hourRanges.some(range => range.day === daysOfWeek[dayIndex - 1])) {
                    return [event];
                }
    
            } else if (event.frequency === "Unique") {
                // Gérer les événements uniques
                const eventDate = new Date(event.day);
                if (eventDate.getDate() === new Date(weekStartDate).getDate() && eventDate.getMonth() === new Date(weekStartDate).getMonth()) {
                    return [event];
                }
            }
            return [];
        });
        
        console.log(`Événements pour la semaine générés :`, eventsForWeek);
        
        const filteredEvents = eventsForWeek.filter(event => {
            // La logique de filtrage pour l'heure de début et de fin reste la même
            const matchingHourRange = event.hourRanges.find(range => {
                console.log(`Vérification de la plage horaire pour l'événement : ${event.name}`);
                console.log(`Jour de la plage horaire : ${range.day}, Plage horaire : ${range.hours}`);
                return range.day === daysOfWeek[dayIndex - 1];
            });
    
            if (matchingHourRange) {
                const [eventStart, eventEnd] = matchingHourRange.hours;
                const eventStartTime = (Math.floor(eventStart) === hour) && (minutes >= (eventStart % 1) * 60);
                const eventEndTime = (Math.floor(eventEnd) > hour) || (minutes < (eventEnd % 1) * 60 && Math.floor(eventEnd) === hour);
    
                console.log(`Heure de début : ${eventStart}, Heure de fin : ${eventEnd}`);
                console.log(`EventStartTime: ${eventStartTime}, EventEndTime: ${eventEndTime}`);
    
                return eventStartTime || eventEndTime;
            } else {
                console.log(`Aucune plage horaire correspondante trouvée pour l'événement : ${event.name}`);
                return false;
            }
        });
        
        console.log(`Événements filtrés pour le créneau :`, filteredEvents);
        return filteredEvents;
    };
    
    
    useEffect(() => {
        console.log('État allEvents :', allEvents);
    }, [allEvents]);
    
const formatTime = (decimalTime) => {
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);
    return `${hours}h${minutes.toString().padStart(2, '0')}`;
  };
    const DaySchedule = ({ day, isCurrentMonth, events,date  }) => {
        const dayName = getDayName(date); // Obtenez le nom du jour

        useEffect(()=> {
            console.log("eventsDaySchedule : "+JSON.stringify(events))
        },[events])

        return (
            <TouchableOpacity
            style={[
                styles.dayMonthContainer,
                isCurrentMonth ? styles.currentDayContainer : styles.otherMonthDayContainer
            ]}
            onPress={() => {
                // Logique de gestion des clics sur les jours ici
                // Par exemple, ouvrir un modal pour afficher les événements de ce jour ou ajouter un nouvel événement
            }}>
            <Text style={[
                styles.dayTitle,
                isCurrentMonth ? styles.currentDayTitle : styles.otherMonthDayTitle
            ]}>
                 {dayName} {day}
            </Text>
            <ScrollView showsVerticalScrollIndicator={false}>
            {isCurrentMonth && events.map((event, index) => (
            <View key={index} style={styles.eventMonth}>
                <Text style={styles.eventText}>{event.room} </Text> 
                <Text style={styles.eventText}>
    {event.hourRanges && event.hourRanges.length > 0 
        ? formatTime(event.hourRanges[0].hours[0]) + ' - ' + formatTime(event.hourRanges[0].hours[1])
        : 'Heure non définie'}
</Text>

                {/* Ajoutez plus de détails si nécessaire */}
            </View>
        ))}
        </ScrollView>
        </TouchableOpacity>
        );
    };

    
    
    const [currentMonthEvents, setCurrentMonthEvents] = useState([]);// Remplir ce tableau avec les événements du mois en cours
    const MonthView = () => {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {daysInMonth(currentDate.getFullYear(), currentDate.getMonth()).map((day, index) => {
                    const isCurrentMonthDay = day.getMonth() === currentDate.getMonth();
                    const eventsForDay = allEvents.filter(event => {
                        const eventDate = new Date(event.day.split('/').reverse().join('-'));
                        return isCurrentMonthDay && eventDate.getDate() === day.getDate() && eventDate.getMonth() === day.getMonth();
                    });
    
                    return (
                        <DaySchedule 
                            key={index}
                            day={day.getDate()} 
                            isCurrentMonth={isCurrentMonthDay} 
                            events={eventsForDay}
                            date={day}
                        />
                    );
                })}
            </View>
        );
    };
    
    const WeekView = ({ currentDate }) => {
        const hours = Array.from({ length: 16 }, (_, i) => i + 8); // De 08:00 à 23:00
        const daysOfWeek = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
        
        const timeSlots = Array.from({ length: 16 * 4 }, (_, i) => {
            const hour = Math.floor(i / 4) + 8; // Démarre à 8
            const minutes = (i % 4) * 15;
            return { hour, minutes };
        });
    
        const hourToNumber = (hourString) => {
            const [hours, minutes] = hourString.split(':').map(Number);
            return hours + minutes / 60;
        };
    
        const getWeekStartDate = (date) => {
            const startDate = new Date(date);
            const day = startDate.getDay();
            const diff = startDate.getDate() - day + (day === 0 ? -6 : 1); // Ajuste si le jour est Dimanche
            return new Date(startDate.setDate(diff));
        };
    
        const [weekStartDate, setWeekStartDate] = useState(getWeekStartDate(currentDate));
    
        useEffect(() => {
            setWeekStartDate(getWeekStartDate(currentDate));
        }, [currentDate]);


        const removeDuplicateEvents = (events) => {
            const uniqueEventsMap = new Map();
        
            events.forEach(event => {
                const eventKey = `${event.frequency}-${event.day}-${JSON.stringify(event.hourRanges)}`;
        
                if (!uniqueEventsMap.has(eventKey)) {
                    uniqueEventsMap.set(eventKey, event);
                }
            });
        
            return Array.from(uniqueEventsMap.values());
        };
        

        const getEventsForTimeSlot = (dayIndex, hour, minutes, weekStartDate) => {
            console.log(`\n--- Début de getEventsForTimeSlot ---`);
            
            // Supprimer les doublons avant le traitement
            const uniqueEvents = removeDuplicateEvents(allEvents);
            
            console.log(`uniqueEvents : ${JSON.stringify(uniqueEvents)}`);
            
            const eventsForWeek = uniqueEvents.flatMap(event => {
                if (event.frequency === "Hebdomadaire") {
                    // Gérer les événements hebdomadaires
                    const weekEndDate = new Date(weekStartDate);
                    weekEndDate.setDate(weekEndDate.getDate() + 6);
                    const recurringEvents = generateRecurringEvents(event, weekStartDate, weekEndDate);
                    console.log(`Événement récurrent généré pour : ${event.name}, Période : ${weekStartDate.toLocaleDateString('fr-FR')} - ${weekEndDate.toLocaleDateString('fr-FR')}`);
                    return recurringEvents;
        
                } else if (event.frequency === "Mensuel") {
                    // Gérer les événements mensuels
                    const nthOccurrence = Math.ceil(new Date(weekStartDate).getDate() / 7); // Trouver la N-ième occurrence
                    const isCorrectNthDay = nthOccurrence.toString() === event.nthDay;
        
                    if (isCorrectNthDay && event.hourRanges.some(range => range.day === daysOfWeek[dayIndex - 1])) {
                        return [event];
                    }
        
                } else if (event.frequency === "Unique") {
                    // Gérer les événements uniques
                    const eventDate = new Date(event.day);
                    const isSameDay = eventDate.getDate() === new Date(weekStartDate).getDate() && 
                                      eventDate.getMonth() === new Date(weekStartDate).getMonth();
                    if (isSameDay) {
                        return [event];
                    }
                }
                return [];
            });
            
            console.log(`Événements pour la semaine générés :`, eventsForWeek);
            
            const filteredEvents = eventsForWeek.filter(event => {
                const matchingHourRange = event.hourRanges.find(range => {
                    console.log(`Vérification de la plage horaire pour l'événement : ${event.name}`);
                    console.log(`Jour de la plage horaire : ${range.day}, Plage horaire : ${range.hours}`);
                    return range.day === daysOfWeek[dayIndex - 1]; // Vérification du jour
                });
        
                if (matchingHourRange) {
                    const [eventStart, eventEnd] = matchingHourRange.hours;
                    const eventStartTime = (Math.floor(eventStart) === hour) && (minutes >= (eventStart % 1) * 60);
                    const eventEndTime = (Math.floor(eventEnd) > hour) || (minutes < (eventEnd % 1) * 60 && Math.floor(eventEnd) === hour);
        
                    console.log(`Heure de début : ${eventStart}, Heure de fin : ${eventEnd}`);
                    console.log(`EventStartTime: ${eventStartTime}, EventEndTime: ${eventEndTime}`);
        
                    return eventStartTime || eventEndTime;
                } else {
                    console.log(`Aucune plage horaire correspondante trouvée pour l'événement : ${event.name}`);
                    return false;
                }
            });
            
            console.log(`Événements filtrés pour le créneau :`, filteredEvents);
            return filteredEvents;
        };
        
        
        
        
        
        
        
        
        
        
        
        const renderEvent = (event) => {
            if (!event.day || event.day === "Invalid Date") {
                console.warn(`Event with invalid date: ${JSON.stringify(event)}`);
                return null; // Ne pas afficher l'événement
            }
        
            // Logique pour rendre l'événement ici
            return (
                <View key={event.id} style={{ top: topOffset, height: durationInPixels, ...styles.event, justifyContent: 'space-between' }}>
                    <View style={{ padding: 5, backgroundColor: 'black', width: 'auto', borderRadius: 30 }}>
                        <Text style={{ color: 'white', marginLeft: 5, marginRight: 7 }}>{event.time}</Text>
                    </View>
                    <View>
                        <Text>{event.name}</Text>
                    </View>
                    <View>
                        <Text>{event.day}</Text>
                    </View>
                </View>
            );
        };
        
        
    
        return (
            <View>  
                <View style={styles.weekViewContainer}>
                    <View style={styles.headerRow}>
                        <View style={{ width: 50 }} /> {/* Pour les heures */}
                        {daysOfWeek.map(day => (
                            <View key={day} style={styles.headerCell}>
                                <Text>{day}</Text>
                            </View>
                        ))}
                    </View>
                    
                    <View style={{ position: 'absolute', width: '100%', top: 45 }}>
                        {hours.map(hour => (
                            <View key={hour} style={styles.hourRow}>
                                <View style={styles.hourBorder} />
                            </View>
                        ))}
                    </View>
                    
                    <View style={{ width: '100%' }}>
                        {hours.map(hour => (
                            <View key={hour} style={styles.hourRow}>
                                <View style={styles.hourCell}>
                                    <Text>{`${hour}:00`}</Text>
                                </View>
                                {daysOfWeek.map((day, dayIndex) => (
                                    <View key={day + hour} style={styles.dayCell}>
                                        {timeSlots.filter(({ hour: slotHour }) => slotHour === hour).map(({ hour, minutes }, index) => (
                                            getEventsForTimeSlot(dayIndex + 1, hour, minutes, weekStartDate).map(event => {
                                                const eventDate = new Date(event.day.split('/').reverse().join('-'));
                                                let matchingHourRange;
    
                                                if (event.hourRanges && event.hourRanges.length > 0) {
                                                    matchingHourRange = event.hourRanges.find(range => {
                                                        const rangeDate = new Date(range.date);
                                                        return rangeDate.getDate() === eventDate.getDate() &&
                                                            rangeDate.getMonth() === eventDate.getMonth() &&
                                                            rangeDate.getFullYear() === eventDate.getFullYear();
                                                    });
                                                }
    
                                                if (!matchingHourRange) return null;
    
                                                const [eventStart, eventEnd] = matchingHourRange.hours;
                                                const eventDuration = eventEnd - eventStart;
                                                const startMinutes = (eventStart % 1) * 60;
                                                const topOffset = (startMinutes / 60) * 150;
                                                const durationInPixels = ((eventEnd - eventStart) * 60 / 60) * 150;
    
                                                return (
                                                    <View key={event.id} style={styles.event}>
                                                        <Text>{event.name}</Text>
                                                        <Text>{`${eventStartTime}-${eventEndTime}`}</Text>
                                                    </View>
                                                );
                                            })
                                        ))}
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>
                </View>
            </View>
        );
    };
    
    
    

    const DayView = () => {
        // Créez une vue détaillée pour une journée spécifique
        // ...
    
        return (
            <View style={styles.dayViewContainer}>
                {/* Affichez les heures et les événements pour la journée sélectionnée */}
            </View>
        );
    };
    
    useEffect(() => {
        const eventsForCurrentMonth = allEvents.filter(event => {
            const eventDate = new Date(event.day.split('/').reverse().join('-'));
            return eventDate.getMonth() === currentDate.getMonth() && 
                   eventDate.getFullYear() === currentDate.getFullYear();
        });
        setCurrentMonthEvents(eventsForCurrentMonth);
    }, [currentDate, allEvents]);

    const newEvents = useMemo(() => {
        const events = [];
        const seenEventIds = new Set(); // Set pour stocker les IDs uniques
    
        if (activitiesInfoArray) {
            Object.values(activitiesInfoArray).forEach((place) => {
                Object.values(place.places || {}).forEach((location) => {
                    if (Array.isArray(location.calendar)) {
                        location.calendar.forEach((event) => {
                            if (seenEventIds.has(event.id)) {
                                // Si l'ID de l'événement a déjà été ajouté, on l'ignore
                                return;
                            }
    
                            const baseEvent = {
                                id: event.id,
                                time: event.time,
                                room: event.room,
                                calendar: event.room,
                                price: event.price || 0,
                                hourRanges: event.hourRanges || [],
                                name: place.name || 'Nom non défini',
                                typeOfActivity: event.category || 'Activité',
                                activity: event.desc || 'Aucune description',
                                frequency: event.frequency,
                                day: event.day,
                                nthDay: event.nthDay,
                            };
    
                            if (!event.frequency || event.frequency === "Unique") {
                                events.push({
                                    ...baseEvent,
                                    day: new Date(event.day).toLocaleDateString('fr-FR'),
                                });
                            } else {
                                const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                                const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                                const recurringEvents = generateRecurringEvents(baseEvent, startDate, endDate);
                                events.push(...recurringEvents);
                            }
    
                            // Marquer cet ID d'événement comme traité
                            seenEventIds.add(event.id);
                        });
                    }
                });
            });
        }
    
        return events;
    }, [activitiesInfoArray, currentDate]);
    
    
    useEffect(() => {
        setAllEvents(newEvents);
    }, [newEvents]);
    
    
    return (
        <View style={{ borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#ECECEC', minHeight: '100vh', alignContent: 'center', alignItems: 'center' }}>
        <View style={{ paddingTop: 86, width: '100%' }}>
            <CalendarHeader currentDate={currentDate} setCurrentDate={setCurrentDate} displayMode={displayMode} onModeChange={setDisplayMode} />
            {displayMode === 'Mois' && <MonthView />}
            {displayMode === 'Semaine' && <WeekView currentDate={currentDate} />}
            {displayMode === 'Jour' && <DayView />}
        </View>
    </View>
    );
};

const ActivitesDash = ({user,profilFB,placeId,activitiesInfoArray }) => {
    const [ongletUp,setOngletUp] = useState('activites')
console.log('profilFB',profilFB)
    return (
        <View>
<View style={{height:86,right:0,paddingLeft:250,backgroundColor:'white',width:'100%',justifyContent:'center',alignContent:'center',flexDirection:'row',position:'fixed',top:0,zIndex:50,borderBottomColor:'#E7E7E7',borderBottomWidth:1}}>
            {
                ongletUp == 'activites' ? 

                <TouchableOpacity onPress={() => setOngletUp('activites')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Toutes les activités </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('activites')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Toutes les activités </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            {
                ongletUp == 'calendar' ? 

                <TouchableOpacity onPress={() => setOngletUp('calendar')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Calendrier </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('calendar')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Calendrier </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            {
                ongletUp == 'reservation' ? 

                <TouchableOpacity onPress={() => setOngletUp('reservation')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Réservations </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('reservation')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Réservations </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            <View style={{position:'absolute',top:0,right:20,padding:10,flexDirection:'row-reverse',alignItems:'center',justifyContent:'center'}}>
                
                <TouchableOpacity style={{width:60,height:60,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',marginLeft:10,overflow:'hidden',justifyContent:'center',alignItems:'center'}}>
                {
    user ?
    user.photoURL ?
            <CustomImage source={{uri: user.photoURL}} style={{width:60,height:60}} />
        :
            <CustomImage source={require('../assets/dalhae.png')} style={{width:60,height:60}} />
    :
        null
}
                </TouchableOpacity>
                <TouchableOpacity style={{ width:40,height:40,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',justifyContent:'center',alignContent:'center',alignItems:'center',marginLeft:7}}>
                    <Notification variant='Bold' size={20} color={`#000000`}/> 
                </TouchableOpacity>
                <TouchableOpacity style={{ width:40,height:40,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',justifyContent:'center',alignContent:'center',alignItems:'center',marginLeft:7}}>
                    <Message variant='Bold' size={20} color={`#000000`}/> 
                </TouchableOpacity>
            </View>
        </View>
        <View>
            {
                ongletUp == 'activites' ?
                <ManageActivity profilInfo={profilFB} placeId={placeId}/>
                :
                null
            }
            
            {
                ongletUp == 'calendar' ?
                <Calendar profilFB={profilFB} activitiesInfoArray={activitiesInfoArray}/> 
                :
                null
            }
        </View>
        </View>

    )
}

const Team = ({ profilInfo,placeId }) => {
    const [usersData, setUsersData] = useState([]);
    useEffect(() => {
        const fetchUserData = async (id) => {
            const firestore = getFirestore();
            const userRef = doc(firestore, 'images', id); // Modifier selon votre structure Firestore
            const docSnap = await getDoc(userRef);
            return docSnap.exists() ? docSnap.data() : null;
        };
        console.log("team profilInfo : "+JSON.stringify(profilInfo))
        const loadUserData = async () => {
            const data = await Promise.all(
                Object.values(profilInfo.team).flat().map(async (user) => {
                    if (typeof user === 'object' && user !== null && user.id) {
                        const userData = await fetchUserData(user.id);
                        return { ...user, ...userData };
                    }
                    return null;
                })
            );
            setUsersData(data.filter(u => u != null)); // Filtrer les données nulles
        };

        if (profilInfo && profilInfo.team) {
            loadUserData();
        }
        
    console.log('profilInfo',profilInfo)
    }, [profilInfo]);

    // Trier les utilisateurs par rôle
    const sortedUsers = usersData.sort((a, b) => {
        const rolePriority = { 'directeur': 1, 'gérant': 2, 'animateur': 3 };
        return (rolePriority[a.role] || 4) - (rolePriority[b.role] || 4);
    });

    return (
        <View style={{marginTop:86,backgroundColor:'rgba(200,200,200,0.1)',borderLeftWidth:1,borderColor:'#E7E7E7'}}>
            
            <TeamListing profilInfo={profilInfo.team} privilege={profilInfo.teamPrivilege}/>
        </View>
    );
};
const TeamManagement = ({user,profilFB}) => {
    const [ongletUp,setOngletUp] = useState('Allteam')
    return (
        <View>
<View style={{height:86,right:0,paddingLeft:250,backgroundColor:'white',width:'100%',justifyContent:'center',alignContent:'center',flexDirection:'row',position:'fixed',top:0,zIndex:50,borderBottomColor:'#E7E7E7',borderBottomWidth:1}}>
            {
                ongletUp == 'Allteam' ? 

                <TouchableOpacity onPress={() => setOngletUp('Allteam')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Toutes l'équipe </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('Allteam')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Toutes l'équipe </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            {
                ongletUp == 'Schedule' ? 

                <TouchableOpacity onPress={() => setOngletUp('Schedule')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Emplois du temps </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('Schedule')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Emplois du temps </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            {
                ongletUp == 'Task' ? 

                <TouchableOpacity onPress={() => setOngletUp('Task')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text  style={{fontFamily:'Avenir Next Regular', fontWeight:"bold",}}> Tâches </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgb(112, 47, 247)',marginTop:2}}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={() => setOngletUp('Task')} style={{ height:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',padding:10}}>
                    <Text style={{fontFamily:'Avenir Next Regular',color:"rgba(0,0,0,0.5)", fontWeight:"bold",}}> Tâches </Text>
                    <View style={{width:5,height:5,borderRadius:5,backgroundColor:'rgba(255, 255, 255,0)',marginTop:2}}/>
                </TouchableOpacity>
            }
            <View style={{position:'absolute',top:0,right:20,padding:10,flexDirection:'row-reverse',alignItems:'center',justifyContent:'center'}}>
                
                <TouchableOpacity style={{width:60,height:60,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',marginLeft:10,overflow:'hidden',justifyContent:'center',alignItems:'center'}}>
                {
    user ?
    user.photoURL ?
            <CustomImage source={{uri: user.photoURL}} style={{width:60,height:60}} />
        :
            <CustomImage source={require('../assets/dalhae.png')} style={{width:60,height:60}} />
    :
        null
}
                </TouchableOpacity>
                <TouchableOpacity style={{ width:40,height:40,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',justifyContent:'center',alignContent:'center',alignItems:'center',marginLeft:7}}>
                    <Notification variant='Bold' size={20} color={`#000000`}/> 
                </TouchableOpacity>
                <TouchableOpacity style={{ width:40,height:40,borderRadius:50,borderWidth:1,borderColor:'#E7E7E7',justifyContent:'center',alignContent:'center',alignItems:'center',marginLeft:7}}>
                    <Message variant='Bold' size={20} color={`#000000`}/> 
                </TouchableOpacity>
            </View>
        </View>
        <View>
            {
                ongletUp == 'Allteam' ?
                <Team profilInfo={profilFB} />
                :
                null
            }
            
            {
                ongletUp == 'Schedule' ?
                <Team profilInfo={profilFB} />
                :
                null
            }
            {
                ongletUp == 'Task' ?
                <Team profilInfo={profilFB} />
                :
                null
            }
        </View>
        </View>

    )
}
const AddMember = () => {
    return(
        <View>
            
        </View>
    )
}
const Task = () => {
    return(
        <View>
            
        </View>
    )
}
const Schedule = () => {
    return(
        <View>
            
        </View>
    )
}
const AddActivity = () => {
    return(
        <View>
            
        </View>
    )
}
const ManageActivity = ({setPage,profilInfo,placeId}) => {
    useEffect(() => {
        console.log("ManageActivity placeId : "+placeId)
    })
    return(
        <View style={{marginTop:86,backgroundColor:'rgba(200,200,200,0.1)',borderLeftWidth:1,borderColor:'#E7E7E7'}}>
            
            <ListingActivitiesHourDashboard setPage={setPage} profilInfo={profilInfo} placeId={placeId}/>
        </View>
    )
}
const Reservation = () => {
    return(
        <View>
            
        </View>
    )
}
const Payment = () => {
    return(
        <View>
            
        </View>
    )
}
const Statistic = () => {
    return(
        <View>
            
        </View>
    )
}
const OrgaProfil = () => {
    return(
        <View>
            
        </View>
    )
}
const AccountSettings = () => {
    return(
        <View>
            
        </View>
    )
}

// Composant pour la vue principale de l'application
//role : 0=admin , 1=sous admin, 2=Enseignant, 3=secretariat
const Dashboard = () => {
    const navigation = useNavigation();
    const functions = getFunctions(); // Initialize Firebase Functions
    const [myId, setMyId] = useState('ID1'); //
    const [placeId, setPlaceId] = useState(undefined); //
    const [activitiesInfoArray, setActivitiesInfoArray] = useState({    
        'ID1': {
            name: 'Dalhae Acaaademie', phoneNumber: '0682888745', email: 'dalhae.academie@gmail.com', desc: 'Notre mission est de fournir une formation de qualité supérieure en Taekwondo, tout en encourageant la croissance personnelle et l excellence. Nous visons à renforcer le corps et l esprit, tout en inspirant nos membres à atteindre leurs objectifs personnels et à donner le meilleur d eux-mêmes. ',
            typeAct: 'Association privée', category: ['Club sportif','Centre de loisirs'], price: [1, 3], imgUrl: require('../assets/dalhaeLogo.jpg'),note:4,imgClub:'',
            nbAvis:7,age: [3, 18],imgCoverUrl: require('../assets/musicImg.jpg'),note:4,
            team: {
                1:{id:"jBxKTj59TYW98sXhxtMZr3mlQ6k2", name:"Marie-Catherine",lastname:"Leseine",role:[0,2],email:"mcleseine@gmail.com",phone:"0682888745"},
                2:{id:"9ERAyuiAHwh1jqDV3Qu3dqhhfoJ3", name:"Clément",lastname:"Martinez",role:[1,2],email:"clem13dutae@gmail.com",phone:"0612345678"},
                3:{id:'ZiceOlMwNIXkwRRsefRzBq4oiso1', name:"Luc-Arnaud",lastname:"Bonnet",role:[0],email:"luc.bonnet.13@gmail.com",phone:"0650007947"},
            },
            teamPrivilege: {
                0:'Admin',
                1:'Sous-Admin',
                2:'Professeur',
                3:'Secrétariat',
            },
            places:{
                1:{
                    geoloc:'',
                    imgLieux: '',
                    address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                    city:'Herndon, Virginie, États-Unis',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 19,
                        imgAct: '',
                        frequency:'Stage',
                        desc:'Petite desc des familles',
                        day:"Sam",
                        category:'Activité récurrente',
                        time: "08:00 - 10:00",
                        room: "Gymnase Gilberti",
                        price: 1,
                        capacity: 10,
                        group: "TITRE",
                        teacher: {"id":1254321,"name":"Marie-Catherine Leseine"},
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 25,
                        imgAct: '',
                        frequency:'Mensuel',
                        nthDay: '4',
                        desc:'Petite desc des familles',
                        day:"Mer",
                        category:'Activité récurrente',
                        time: "10:15 - 15:45",
                        room: "Gymnase Gilllberti",
                        price: 2,
                        capacity: 20,
                        group: "Groupe 1",
                        teacher: {
                            id:12556721,
                            name:"Clément Martinez"
                        },
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 41,
                        imgAct: '',
                        frequency:'Hebdomadaire',
                        desc:'Petite desc des familles',
                        day:"Dim",
                        category:'Activité récurrente',
                        time: "17:00 - 18:00",
                        room: "Gymnase Gillberti",
                        price: 3,
                        nthDay: '0',
                        capacity: 30,
                        group: "Groupe 1",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13101 Worldgate Drive, Herndon, Virginie, États-Unis',
                        addressG: {address:"13101 Worldgate Drive, Herndon, Virginie, États-Unis",lat: 38.9578565, lng: -77.4021707},
                        age:[3,8],
                        recurrent:false
                      },
                    ]
                },
                0:{
                    geoloc:'',
                    imgLieux: '',
                    address:'15 Chemin des Aubagnens, Allauch, France',
                    city:'Allauch, France',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 12,
                        imgAct: '',
                        day:"Lun",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "09:00 - 12:00",
                        room: "Gymnase du Charrel",
                        price: 3,
                        frequency:'Mensuel',
                        nthDay: '4',
                        capacity: 15,
                        group: "Groupe 1",
                        teacher: {id:1254321,name:"Marie-Catherine Leseine"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 28,
                        imgAct: '',
                        day:"Ven",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "11:15 - 13:45",
                        room: "Gymnase du Charrel",
                        price: 2,
                        frequency:'Hebdomadaire',
                        nthDay: '0',
                        capacity: 25,
                        group: "Groupe 2&3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 49,
                        imgAct: '',
                        day:"Mer",
                        category:'Activité récurrente',
                        desc:'Petite desc des familles',
                        time: "11:00 - 18:00",
                        room: "Gymnase du Charrel",
                        price: 1,
                        frequency:'Hebdomadaire',
                        nthDay: '0',
                        capacity: 35,
                        group: "Groupe 3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'15 Chemin des Aubagnens, Allauch, France',
                        addressG: {address:'15 Chemin des Aubagnens, Allauch, France', lat: 43.2934045, lng: 5.567245199999999},
                        age:[12,null],
                        recurrent:false
                      },
                    ]
                },
                2:{
                    geoloc:'',
                    imgLieux: '',
                    address:'13 cour foch, Aubagne 13400',
                    city:'Aubagne 13400',
                    desc:'Petite desc du lieu gang',
                    calendar: [
                        {
                        id: 72,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Sam",
                        category:'Activité récurrente',
                        time: "11:00 - 12:00",
                        room: "Gymnase Foch",
                        price: 3,
                        frequency:'Mensuel',
                        nthDay: '2',
                        capacity: 17,
                        group: "Groupe 1",
                        teacher: {id:1254321,name:"Marie-Catherine Leseine"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[3,8],
                        recurrent:false
                      },
                      {
                        id: 68,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Dim",
                        category:'Activité récurrente',
                        time: "11:15 - 13:45",
                        room: "Gymnase Bras d'Or",
                        price: 2,
                        frequency:'Mensuel',
                        nthDay: '1',
                        capacity: 26,
                        group: "Groupe 2&3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[8,null],
                        recurrent:false
                      },
                      {
                        id: 59,
                        imgAct: '',
                        desc:'Petite desc des familles',
                        day:"Jeu",
                        category:'Activité récurrente',
                        time: "11:00 - 18:00",
                        room: "Gymnase Charrel",
                        price: 1,
                        frequency:'Mensuel',
                        nthDay: '3',
                        capacity: 32,
                        group: "Groupe 3",
                        teacher: {id:12556721,name:"Clément Martinez"},
                        address:'13 cour foch, Aubagne 13400',
                        addressG: {address:'13 cour foch, Aubagne 13400', lat: 43.2934045, lng: 5.567245199999999},
                        age:[12,null],
                        recurrent:false
                      },
                    ]
                },
            }
        },
        
        
    })
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const [page, setPage] = useState(1);
    const testSetPage = (page) => {
        console.log("testSetPage called with:", page);
    };
    const setPageCallback = useCallback((newPage) => {
        setPage(newPage);
    }, []); 
    const Sidebar = () => {
        const [colorIcon, setColorIcon] = useState(['555555','FFFFFF']);
      
      // Ici, vous pouvez gérer la navigation ou le state actif de la section
      return (
        <View style={styles.sidebar}>
            <View style={{width:'100%',height:75,justifyContent:'center',alignContent:'center',alignItems:'center'}}>
            <TouchableOpacity onPress={()=>navigation.navigate('Home')} style={{...styles.logols,margin:0,left:0,height:76,paddingHorizontal:10,paddingBottom:10}}>
                   
                   <Svg  id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/Svg" viewBox="0 0 2651.86 493.44">
 
 <G id="OBJECTS">
   <G>
     <Path
         fill="#6f2cf7"
         strokeWidth={0} class="cls-2" d="m374.96,261.65c0,5.45-3.31,10.36-8.4,12.33l-78.71,30.2c-27.7,10.63-49.4,32.79-59.59,60.75l-29.93,82.46c-.21.62-.68,1.05-.99,1.61-.26.46-.5.91-.82,1.33-.48.64-.96,1.21-1.56,1.72-.92.82-1.93,1.54-3.07,2.04-.71.27-1.43.54-2.14.63-.71.18-1.43.27-2.14.27l-2.32-.27c-.71-.09-1.43-.36-2.14-.63-1.18-.51-2.21-1.25-3.15-2.09-.58-.5-1.04-1.06-1.51-1.67-.27-.36-.49-.74-.71-1.12-.37-.62-.89-1.12-1.15-1.82l-29.93-82.46c-10.1-27.96-31.81-50.12-59.5-60.75l-78.71-30.2c-5.09-1.97-8.49-6.88-8.49-12.33,0-5.72,3.75-10.81,9.2-12.51l72.01-22.69c31.18-9.83,55.75-33.95,66.29-64.86l29.3-86.57c.36-.98.8-1.88,1.34-2.68.36-.54.72-1.07,1.25-1.52,1.07-1.16,2.41-2.05,4.02-2.68.45-.18.98-.36,1.52-.45.8-.27,1.7-.36,2.59-.36s1.79.09,2.59.36c.54.09,1.07.27,1.52.45,1.43.54,2.68,1.43,3.75,2.41.45.45.8.89,1.16,1.34.72.98,1.34,1.96,1.7,3.13l29.3,86.57c10.45,30.91,35.11,55.03,66.2,64.86l72.01,22.69c5.54,1.7,9.2,6.79,9.2,12.51Z"/>
     <Path 
         fill="#6f2cf7"
         strokeWidth={0}class="cls-2" d="m502.89,106.4c0,2.98-1.81,5.67-4.6,6.75l-43.1,16.53c-15.17,5.82-27.05,17.95-32.63,33.26l-16.39,45.15c-.12.34-.37.58-.54.88-.14.25-.27.5-.45.73-.26.35-.53.66-.85.94-.5.45-1.06.84-1.68,1.12-.39.15-.78.29-1.17.34-.39.1-.78.15-1.17.15l-1.27-.15c-.39-.05-.78-.2-1.17-.34-.64-.28-1.21-.68-1.72-1.15-.32-.27-.57-.58-.83-.91-.15-.2-.27-.4-.39-.62-.2-.34-.49-.61-.63-1l-16.39-45.15c-5.53-15.31-17.41-27.44-32.58-33.26l-43.1-16.53c-2.79-1.08-4.65-3.77-4.65-6.75,0-3.13,2.05-5.92,5.04-6.85l39.43-12.43c17.07-5.38,30.53-18.59,36.3-35.52l16.05-47.4c.2-.54.44-1.03.73-1.47.2-.29.39-.59.69-.83.59-.64,1.32-1.13,2.2-1.47.25-.1.54-.2.83-.24.44-.15.93-.2,1.42-.2s.98.05,1.42.2c.29.05.59.15.83.24.78.29,1.47.78,2.05,1.32.25.24.44.49.64.73.39.54.73,1.08.93,1.71l16.05,47.4c5.72,16.93,19.23,30.13,36.25,35.52l39.43,12.43c3.03.93,5.04,3.72,5.04,6.85Z"/>
     <Path 
         fill="#6f2cf7"
         strokeWidth={0}class="cls-2" d="m467.83,423.73c0,1.96-1.19,3.74-3.03,4.45l-28.38,10.89c-9.99,3.83-17.81,11.82-21.49,21.91l-10.79,29.73c-.08.22-.25.38-.36.58-.09.16-.18.33-.29.48-.17.23-.35.43-.56.62-.33.3-.7.56-1.1.74-.26.1-.52.19-.77.22-.26.06-.52.1-.77.1l-.84-.1c-.26-.03-.52-.13-.77-.22-.42-.19-.8-.45-1.13-.75-.21-.18-.37-.38-.54-.6-.1-.13-.18-.27-.26-.41-.13-.22-.32-.4-.42-.65l-10.79-29.73c-3.64-10.08-11.47-18.07-21.45-21.91l-28.38-10.89c-1.84-.71-3.06-2.48-3.06-4.45,0-2.06,1.35-3.9,3.32-4.51l25.96-8.18c11.24-3.54,20.1-12.24,23.9-23.39l10.57-31.22c.13-.35.29-.68.48-.97.13-.19.26-.39.45-.55.39-.42.87-.74,1.45-.97.16-.06.35-.13.55-.16.29-.1.61-.13.93-.13s.64.03.93.13c.19.03.39.1.55.16.51.19.97.52,1.35.87.16.16.29.32.42.48.26.35.48.71.61,1.13l10.57,31.22c3.77,11.15,12.66,19.84,23.87,23.39l25.96,8.18c2,.61,3.32,2.45,3.32,4.51Z"/>
   </G>
   <G>
     <Path strokeWidth={0} class="cls-1" d="m681.67,84.76h67.33v318.66h-67.33V84.76Z"/>
     <Path strokeWidth={0} class="cls-1" d="m800.15,296.6v-114.9h67.33v114.9c0,35.01,17.05,49.82,42.19,49.82s42.19-14.81,42.19-49.82v-114.9h67.32v114.9c0,74.5-43.98,112.65-109.51,112.65s-109.51-37.7-109.51-112.65Z"/>
     <Path strokeWidth={0} class="cls-1" d="m1063.14,278.65c0-71.37,41.29-102.78,95.15-102.78,31.42,0,57.9,12.12,74.5,35.46,16.61-23.34,42.64-35.46,74.5-35.46,53.86,0,95.15,31.42,95.15,102.78v124.77h-67.32v-124.77c0-29.63-14.36-39.95-34.56-39.95s-34.11,10.32-34.11,39.95v124.77h-67.32v-124.77c0-29.63-14.36-39.95-34.11-39.95s-34.55,10.32-34.55,39.95v124.77h-67.33v-124.77Z"/>
     <Path strokeWidth={0} class="cls-1" d="m1444.17,118.42c0-24.69,18.4-43.09,43.09-43.09s43.09,18.4,43.09,43.09-18.4,43.09-43.09,43.09-43.09-18.4-43.09-43.09Zm9.42,63.28h67.33v221.72h-67.33v-221.72Z"/>
     <Path strokeWidth={0} class="cls-1" d="m1563.1,336.1h66.42c.45,11.67,8.98,17.5,27.38,17.5,20.65,0,27.38-7.18,27.38-16.61,0-14.81-16.15-16.15-36.8-19.3-30.52-4.49-80.78-12.12-80.78-65.53,0-44.88,35.45-76.3,90.66-76.3s89.31,31.87,90.66,71.81h-65.52c-1.8-10.32-10.77-16.16-24.24-16.16-16.6,0-23.34,8.53-23.34,17.5,0,13.46,16.15,15.26,36.8,18.4,31.86,4.49,80.78,13.02,80.78,65.53,0,45.78-37.7,76.3-94.7,76.3s-93.8-30.52-94.7-73.16Z"/>
     <Path strokeWidth={0} class="cls-1" d="m1778.51,292.56c0-67.32,49.37-116.69,119.39-116.69,61.49,0,106.37,38.15,115.79,92.9h-68.22c-7.63-18.4-25.13-30.07-47.57-30.07-30.52,0-51.17,21.99-51.17,53.86s20.64,53.86,51.17,53.86c22.44,0,39.94-11.67,47.57-30.07h68.22c-9.42,54.76-54.3,92.91-115.79,92.91-70.02,0-119.39-49.37-119.39-116.69Z"/>
     <Path strokeWidth={0} class="cls-1" d="m2038.8,292.56c0-67.32,49.37-116.69,119.39-116.69s119.38,49.37,119.38,116.69-49.37,116.69-119.38,116.69-119.39-49.37-119.39-116.69Zm170.55,0c0-31.87-20.64-53.86-51.16-53.86s-51.17,21.99-51.17,53.86,20.64,53.86,51.17,53.86,51.16-21.99,51.16-53.86Z"/>
     <Path strokeWidth={0} class="cls-1" d="m2303.59,292.56c0-67.32,49.37-116.69,119.39-116.69s119.38,49.37,119.38,116.69-49.37,116.69-119.38,116.69-119.39-49.37-119.39-116.69Zm170.55,0c0-31.87-20.64-53.86-51.16-53.86s-51.17,21.99-51.17,53.86,20.64,53.86,51.17,53.86,51.16-21.99,51.16-53.86Z"/>
     <Path strokeWidth={0} class="cls-1" d="m2584.54,84.76h67.33v318.66h-67.33V84.76Z"/>
   </G>
 </G>
</Svg>
               </TouchableOpacity>
               </View>
          <View style={{paddingHorizontal:10,borderTopWidth:1,borderTopColor:'#E7E7E7'}}>
            <View style={{paddingVertical:20,borderBottomWidth:1,borderBottomColor:'#E7E7E7'}}>
            <View style={{flexDirection:'row', paddingHorizontal: 20,}}>
                <View style={{marginRight:10}}>
                    <CustomImage source={activitiesInfoArray[myId].logo} style={{width:60,height:60,borderRadius:15,borderWidth:1,borderColor:'#E7E7E7'}} />
                    <View style={{position:'absolute',bottom:-7,right:-12,borderWidth:2,borderColor:'white',borderRadius:20,justifyContent:'center',alignContent:'center',alignItems:'center',overflow:'hidden'}}>
                    {
                        profilFB ?
                            profilFB.photoURL ?
                                <CustomImage source={{uri: profilFB.photoURL}} style={{width: 30, height: 30, }} />
                            :
                                <CustomImage source={require('../assets/dalhae.png')} style={{width: 30, height: 30,}} />
                        :
                            null
                    }


                    </View>
                </View>
                <View style={{marginLeft:10,justifyContent:'center'}}>
                    <Text style={{fontFamily:'Avenir Next Regular',fontSize:10,color:'#808080'}}>Administrateur</Text>
                    <Text style={{fontFamily:'Avenir Next Bold',fontSize:11,marginTop:5}}>{activitiesInfoArray[myId].name}</Text>
                    <Text style={{fontFamily:'Avenir Next Regular',fontSize:10,color:'#808080',marginTop:10}}>{profilFB ? profilFB.displayName : 'undefined'}</Text>
                </View>
            </View>
            </View>
          </View>
          <ScrollView showsHorizontalScrollIndicator={false} > 
          <View style={{paddingHorizontal:10,}}>
            <View style={{ paddingHorizontal: 5,paddingTop:20}}>
                {
                    page == 0 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(0)}>
                        <Element4 variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                        <Text style={styles.navTextSelected}>Dashboard</Text>
                    </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(0)} 
    hoverStyle={styles.hoverStyle} 
    icon={Element4} 
    text="Dashboard" 
    page={0} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 1 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(1)}>
                    <Magicpen variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                        <Text style={styles.navTextSelected}>Activités</Text>
                    </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(1)} 
    hoverStyle={styles.hoverStyle} 
    icon={Magicpen} 
    text="Activités" 
    page={1} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 2 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(2)}>
                    <People variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Équipe et rôles</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(2)} 
    hoverStyle={styles.hoverStyle} 
    icon={People} 
    text="Équipe et rôles" 
    page={2} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
            </View>
            <View style={{ paddingHorizontal: 5,}}>
                {
                    page == 3 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(3)}>
                    <Notepad2 variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Gestion des inscrits</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(3)} 
    hoverStyle={styles.hoverStyle} 
    icon={Notepad2} 
    text="Gestion des inscrits" 
    page={3} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 5 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(5)}>
                    <CalendarTick variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Tâches</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(5)} 
    hoverStyle={styles.hoverStyle} 
    icon={CalendarTick} 
    text="Tâches" 
    page={5} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 6 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(6)}>
                    <HierarchySquare2 variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Locaux et matériels</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(6)} 
    hoverStyle={styles.hoverStyle} 
    icon={HierarchySquare2} 
    text="Locaux et matériels" 
    page={6} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
            </View>
            <View style={{paddingHorizontal:5,borderBottomColor:'#E7E7E7',borderBottomWidth:1,paddingBottom:20}}>
                {
                    page == 7 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(7)}>
                    <Message variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Messagerie</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(7)} 
    hoverStyle={styles.hoverStyle} 
    icon={Message} 
    text="Messagerie" 
    page={7} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 8 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(8)}>
                        <FavoriteChart variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                        <Text style={styles.navTextSelected}>Statistiques</Text>
                    </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
                        onPress={() => setPage(8)} 
                        hoverStyle={styles.hoverStyle} 
                        icon={FavoriteChart} 
                        text="Statistiques" 
                        page={8} 
                        setPage={setPage} 
                        selectedPage={page}
                        colorIcon={'#555555'}
                    />
                }
                    {
                        page == 9 ? 
                        <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(9)}>
                        <Home3 variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                        <Text style={styles.navTextSelected}>Paramètres du compte</Text>
                    </TouchableOpacity>
                        : 
                        <HoverableTouchableOpacity 
        onPress={() => setPage(9)} 
        hoverStyle={styles.hoverStyle} 
        icon={Home3} 
        text="Paramètres du compte" 
        page={9} 
        setPage={setPage} 
        selectedPage={page}
        colorIcon={'#555555'}
    />
                    }
                    
            </View>
            <View style={{paddingHorizontal:5,paddingVertical:20}}>
                {
                    page == 10 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(7)}>
                    <ArchiveBook variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                    <Text style={styles.navTextSelected}>Ressources</Text>
                </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
    onPress={() => setPage(10)} 
    hoverStyle={styles.hoverStyle} 
    icon={ArchiveBook} 
    text="Ressources" 
    page={10} 
    setPage={setPage} 
    selectedPage={page}
    colorIcon={'#555555'}
/>
                }
                {
                    page == 11 ? 
                    <TouchableOpacity style={styles.navItemSelected} onPress={() => setPage(8)}>
                        <Document variant='Bold' size={25} color={`#${colorIcon[1]}`}/> 
                        <Text style={styles.navTextSelected}>Documents</Text>
                    </TouchableOpacity>
                    : 
                    <HoverableTouchableOpacity 
                        onPress={() => setPage(11)} 
                        hoverStyle={styles.hoverStyle} 
                        icon={Document} 
                        text="Documents" 
                        page={11} 
                        setPage={setPage} 
                        selectedPage={page}
                        colorIcon={'#555555'}
                    />
                }
                   
                    
            </View>
          </View>
          </ScrollView>
          <View style={{width:'100%',justifyContent:'center',alignContent:'center',alignItems:'center',marginBottom:20}}>

          <HoverableTouchableOpacityDeco hoverStyle={styles.hoverStyle}/>
          </View>
          {/* Ajouter d'autres éléments de navigation ici */}
        </View>
      );
    };
    
    if (myId!='ID1') {
        return (
            <View style={styles.container}>
              <Sidebar />
            <View style={{flex:1,justifyContent:'center',alignItems:'center',height:'100%'}}>
                <Text style={{textAlign:'center'}}>VOUS N'AVEZ PAS ACCES A CES INFORMATIONS !</Text>
            </View>
            </View>
        )
    }
    
    const [profilFB, setProfilFB] = useState(undefined);
   /* useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log("Utilisateur connecté DASH:", user);
            setProfilFB(user)
            console.log("profilInfo DASH: ", profilFB);
            // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
          } else {
            console.log("Utilisateur déconnecté DASH");
            console.log("Utilisateur déconnecté in DASH");
            navigation.navigate('Home')
            // Mettez à jour votre état en conséquence
          }
        });
      
        return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
      }, [auth]);
      */
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("Utilisateur connecté DASH:", user);
                
                // Mettre à jour profilFB avec les informations utilisateur
                const updatedProfil = {
                    ...profilFB,
                    ...user
                };
                setProfilFB(updatedProfil);
                console.log("profilInfo DASH mis à jour: ", updatedProfil);
    
                try {
                    const getDashboardData = httpsCallable(functions, 'getDashboardData');
                    console.log("getDashboardData id: ", user.uid);
                    const result = await getDashboardData({ userId: user.uid });
                    setActivitiesInfoArray({'ID1':result.data.dashboardData});
                    setPlaceId(result.data.dashboardData.id);
                    console.log("Données du tableau de bord récupérées:", activitiesInfoArray);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données du tableau de bord", error);
                }
            } else {
                console.log("Utilisateur déconnecté DASH");
                navigation.navigate('Home');
            }
        });
    
        return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
    }, [auth, navigation]);
    
  return (
    <View style={styles.container}>
      <Sidebar />
      <View style={styles.mainContent}>
        {
            page == 0 ? <Dash/> : <View/>
        }
        {
            page == 1 ? <ActivitesDash user={profilFB} profilFB={activitiesInfoArray[myId]} placeId={placeId} activitiesInfoArray={activitiesInfoArray} /> : <View/>
        }
        {
            page == 3 ? <AddMember/> : <View/>
        }
        {
            page == 2 ? <TeamManagement user={profilFB} profilFB={activitiesInfoArray[myId]}/> : <View/>
        }
        {
            page == 4 ?  <Calendar profilFB={profilFB} activitiesInfoArray={activitiesInfoArray}/> : <View/>
        }
        {
            page == 5 ? <Task/> : <View/>
        }
        {
            page == 6 ? <Schedule/> : <View/>
        }
        {
            page == 7 ? <AddActivity/> : <View/>
        }
        {
            // Supposons que le composant qui contient le Picker s'appelle `ActivityPanel`
            page == 8 ? <Statistic/> : <View/>
        }
        {
            page == 9 ? <Reservation/> : <View/>
        }
        {
            page == 10 ? <Payment/> : <View/>
        }
        {
            page == 11 ? <Statistic/> : <View/>
        }
        {
            page == 12 ? <OrgaProfil/> : <View/>
        }
        {
            page == 13 ? <AccountSettings/> : <View/>
        }
        
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  sidebar: {
    width: 250,
    backgroundColor: '#fff',
    paddingVertical: 10,
    height:'100vh',
    position:'fixed',
    zIndex:10,
  },
  profileSection: {
    alignItems: 'center',
    marginBottom: 40,
  },
  profileName: {
    marginTop: 10,
    fontWeight: 'bold',
  },
  hoverStyle: {
    // styles pour l'effet de survol
    backgroundColor: 'rgba(200,200,200,0.1)', // Exemple : un gris clair pour l'effet de survol
    // autres propriétés de style à modifier lors du survol
  },
  navItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    borderRadius:30,
    padding:10,
    paddingLeft:30
  },
  logols: {
      minWidth:180,
      maxWidth:190,
      resizeMode:'cover',
      justifyContent:'center',
      alignContent:'center',
      alignItems:'center'
  },
  navText: {
    marginLeft: 10,
    fontWeight:"bold",
    color:"rgba(0,0,0,0.5)",
    fontSize:12,
    fontFamily:'Avenir Next Regular'
  },
  navItemSelected: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    borderRadius:30,
    backgroundColor:'rgba(112, 47, 247,1)',
    padding:10,
    paddingLeft:30,
  },
  navDeco: {
    flexDirection: 'row',
    width:'80%',
    alignItems: 'center',
    justifyContent:'center',
    alignContent:'center',
    marginBottom: 5,
    borderRadius:30,
    padding:10,
    borderWidth:1,
    borderColor:'#E7E7E7'
  },
  navTextSelected: {
    marginLeft: 10,
    fontWeight:"bold",
    color:'white',
    fontSize:12,
    fontFamily:'Avenir Next Regular'
  },
  mainContent: {
    flex: 1,
    paddingLeft:250
  },
  calendarHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: '#f0f0f0',
},
calendarHeader: {
    flexDirection: 'row',  // Aligner les éléments horizontalement
    alignItems: 'center',  // Centrer les éléments verticalement dans le conteneur
    justifyContent: 'space-between', // Répartir l'espace uniformément autour des éléments
    padding: 10,
    backgroundColor: '#f0f0f0', // Choisissez une couleur de fond adaptée
},
calendarTitle: {
    fontSize: 20,
    fontWeight: 'bold',
},
navigationButton: {
    padding: 10, // Ajustez pour une meilleure réactivité au toucher
    fontSize: 18, // Taille du texte pour les boutons
    fontWeight: 'bold', // Rendre le texte des boutons plus lisible
    color: '#000', // Couleur du texte des boutons
},
calendarTitle: {
    fontSize: 20,
    fontWeight: 'bold',
},
dayMonthContainer: {
    minWidth: 150, // Taille minimale de la case
    width: '14.28%', // Approximativement 1/7ème de la largeur totale pour 7 jours par semaine
    
    alignItems: 'center',
    padding: 5,
    borderWidth: 0.5,
    borderColor: '#dddddd',
    minHeight: 150, // Hauteur minimale pour chaque case
    maxHeight:160,
},
currentDayContainer: {
    backgroundColor: '#e3f2fd',
},
otherMonthDayContainer: {
    backgroundColor: '#f9f9f9',
},
dayTitle: {
    fontSize: 16,
    fontWeight: 'bold',
},
currentDayTitle: {
    color: 'black',
},
otherMonthDayTitle: {
    color: '#999999',
},
eventMonth: {
    padding:5,
    marginTop: 5,
    width:'90%',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#6F2CF7', // Remplacer par votre variable si nécessaire
    backgroundColor: '#FFF', // Remplacer par votre variable si nécessaire
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4, // Pour Android
},
eventText: {
    fontSize: 14,
},
modeButton:{
    padding:15,
    borderRadius:20,
    marginRight:10
},
activeModeButton:{
    padding:15,
    backgroundColor:'#6F2CF7',
    borderRadius:20,
    marginRight:10
},
weekViewContainer: {
    backgroundColor: '#fff',
    height:2400,
    overflow:'hidden'
},
headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: '#f9f9f9',
    padding: 5,
},
headerCell: {
    flex: 1,
    wdith:50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
},
hourRow: {
    height: 150,
    flexDirection: 'row',
    zIndex: 1, // Assurez-vous que cela est inférieur à celui de event
},
hourBorder: {
    zIndex:1,
    elevation:1,
    width:'100%',
    position:'absolute',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
},
hourCell: {
    width: 50,
    alignItems: 'center',
    paddingVertical: 10,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
},
dayCell: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
},
event: {
    zIndex:10,
    position: 'absolute',
    width: '100%',
    backgroundColor: '#ffccbc', // Couleur de fond pour les événements
    borderRadius: 5, // Bords arrondis pour un look plus doux
    padding: 5, // Padding à l'intérieur de l'événement
    marginVertical: 2, // Petite marge pour séparer les événements les uns des autres
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#6F2CF7', // Remplacer par votre variable si nécessaire
    backgroundColor: '#FFF', // Remplacer par votre variable si nécessaire
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4, // Pour Android
},
eventText: {
    fontSize: 12,
    color: 'black',
    // Autres styles pour le texte des événements
},
});

export default Dashboard;
