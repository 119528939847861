import React, { useState,useEffect } from 'react';

import { View, StyleSheet, Text, Button,Dimensions,TouchableOpacity } from 'react-native';
import NavBarWS from '../components/NavBarWS.js'
import { auth } from '../App.js'; // Ajustez le chemin d'accès en conséquence

import { Star , Location} from 'iconsax-react-native';
import { onAuthStateChanged } from "firebase/auth";
import CustomImage from '../components/customImage.js';
import CalendarPlaceAct from '../components/calendarPlaceAct.js';
import { ScrollView } from 'react-native-web';
import ListingActivitiesHour from '../components/ListingActivitiesHour.js';
import AvisActivity from '../components/AvisActivity.js';
// Exemple d'écrans - Remplacez ceci par vos propres composants
export default function  ActivityProfilScreen({ route,navigation,user }) {
  const [selectedButton, setSelectedButton] = useState('Lieux');
  const [searchActivity, setSearchActivity] = useState('');
  const [address, setAddress] = useState('');
  const [selectedDay, setSelectedDay] = useState({}); // Pour stocker les jours sélectionnés
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  
  const getOrganizationType = (choice) => {
    switch (choice) {
      case 0:
        return "Organisation Publique";
      case 1:
        return "Association non lucrative";
      case 2:
        return "Entreprise Privée";
      default:
        return "Type inconnu";
    }
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  useEffect(() => {
    const onChange = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', onChange);
    return () => Dimensions.removeEventListener('change', onChange);
  }, []);
  const isLargeScreen = screenWidth >= 1350; 
  const isLittleScreen = screenWidth <= 1040; 
  const { idProfil, profilInfo } = route.params;
  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("Utilisateur connecté:", user);
          console.log("profilInfo : ", Object(profilInfo));
          // Ici, mettez à jour votre état ou faites ce que vous voulez avec les infos de l'utilisateur
        } else {
          console.log("Utilisateur déconnecté");
          console.log("Utilisateur déconnecté in ACTPROFIL");
          // Mettez à jour votre état en conséquence
        }
      });
    
      return () => unsubscribe();  // Nettoyez l'observateur lorsque le composant est démonté
    }, [auth]);
    return (
      <View style={{backgroundColor:'#FFFFFF'}}>
      {!isLargeScreen ? (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <NavBarWS page={'Home'} user={user} navigation={navigation} selectedButton={selectedButton} setSelectedButton={setSelectedButton} searchActivity={searchActivity} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        <ScrollView style={{paddingHorizontal:50,paddingTop:20,flex:1,width:'100%'}}>
          <View style={{flexDirection:'row',alignContent:'center'}}>
              <View style={{marginRight:10}}>
                <CustomImage source={profilInfo.logo} style={{width:150,aspectRatio:1/1,borderRadius:15}}/>

              </View>
              <View style={{justifyContent:'center'}}>
                <View style={{flexDirection:'row',alignItems:'center'}}>

                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{getOrganizationType(profilInfo.typeAct) || 'nom par défaut'} </Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}> • </Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.category[0] || 'nom par défaut'} </Text>
                </View>
                <Text style={{...styles.textH1,fontSize:30}}>{profilInfo.name || 'nom par défaut'} </Text>

                <View style={{width:'100%',flexDirection:'row',alignItems:'center'}}>

                <Star color={'#000000'} variant='Bold' size={15} style={{marginRight: 5}}/>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.note || [0]} </Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}> • </Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.nbAvis || [0]} avis</Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}> • </Text>
                                <Location color={'#000000'} variant='Bold' size={15} style={{marginRight: 5}}/>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.address.formatted_address || 'Adresse par défaut'}</Text>
                </View>
              </View>
              
          </View>
          <View style={{flexDirection:'row'}}>
            <View>
              {
                profilInfo.coverPicture !== undefined ?
                <CustomImage source={profilInfo.coverPicture} style={{width:'40vw',
                aspectRatio: 1 / 1,borderRadius:15}}/>
                :
                
                <CustomImage source={profilInfo.coverPicture} style={{width:'40vw',
                aspectRatio: 1 / 1,borderRadius:15}}/>
              }
            </View>
            <View style={{padding:20,width:'60%'}}>
              
              <Text style={{...styles.textH3,marginTop:20,fontWeight:'bold',marginBottom:10}}>Description </Text>
              <Text style={{width:'100%',fontFamily:'Avenir Next Regular',fontSize:17,color:'rgba(0, 0, 0, 0.70)'}}>{truncateText(profilInfo.desc || 'desc par défaut',350)} </Text>
              <TouchableOpacity style={{flexDirection:'row',width:'auto',marginTop:5,alignItems:'center'}}>
                <Text style={{...styles.textH3,fontSize:15,fontWeight:'bold',textDecorationLine:'underline'}}>En savoir plus</Text>
              </TouchableOpacity>
              {
                isLittleScreen ? 
                null
              :
              
              <View style={{}}>
              <Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Informations</Text>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
              </View><Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Liens</Text>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
              </View>
            </View>
              }
          </View>
          </View>
          {
                isLittleScreen ? 
              
             
                <View style={{flexDirection:'row',justifyContent:'space-between',maxWidth:'40vw'}}>
                <View>
              <Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Informations</Text>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                </View>
              </View>
                <View>
              <Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Liens</Text>
                <View>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                </View>
                </View>
              </View>
            </View>
            :
            null
              }
          
          {
              profilInfo.places ?
              <View>
              <Text style={{...styles.textH1,fontSize:30,marginTop:30}}>Nos activités :</Text>
            <ListingActivitiesHour profilInfo={profilInfo}/>
          </View>
              :
              null
            }{
              profilInfo.places ?
              <View>
                <Text style={{...styles.textH1,fontSize:30,marginTop:30}}> Calendrier des activités régulières</Text>
              <CalendarPlaceAct profilInfo={profilInfo}/>
          </View>
              :
              null
            }
          <View >
            <AvisActivity profilInfo={profilInfo}/>
          </View>
        </ScrollView>
        
      </View>
      )
      :
      (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <NavBarWS page={'Home'} user={user} navigation={navigation} selectedButton={selectedButton} setSelectedButton={setSelectedButton} searchActivity={searchActivity} setSearchActivity={setSearchActivity} address={address} setAddress={setAddress} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        <ScrollView style={{paddingLeft:150,paddingRight:150,paddingTop:50,flex:1,width:'100%'}}>
          <View style={{flexDirection:'row',width:'100%'}}>
            <View style={{width:'50%',paddingRight:20}}>
            {
                profilInfo.imgCoverUrl == undefined ?
                <CustomImage source={profilInfo.coverPicture} style={{width:'100%',
                aspectRatio: 1 / 1,borderRadius:15,borderWidth:1,borderColor:'#E7E7E7',borderRadius:15}}/>
                :
                
              <CustomImage source={profilInfo.coverPicture} style={{width:'100%',
              aspectRatio: 1 / 1,borderRadius:15,borderWidth:1,borderColor:'#E7E7E7',borderRadius:15}}/>
              }
            </View>
            <View style={{padding:20,width:'60%',paddingLeft:20}}>
              <View style={{flexDirection:'row',alignItems:'center'}}>
              <View style={{marginRight:10,borderWidth:1,borderColor:'#E7E7E7',borderRadius:10}}>
                <CustomImage source={profilInfo.logo} style={{height:'100%',minWidth:100,maxWidth:150,borderRadius:15,aspectRatio:1/1}}/>

              </View>
              <View>
                <View style={{flexDirection:'row',alignItems:'center'}}>

                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{getOrganizationType(profilInfo.typeAct) || 'nom par défaut'}</Text>
                  <Text style={{...styles.textH4,fontSize:7,color:'#AFAFAF',marginHorizontal:7}}>•</Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.category[0] || 'nom par défaut'} </Text>
                </View>
                <Text style={{...styles.textH1,fontSize:30}}>{profilInfo.name || 'nom par défaut'} </Text>

                <View style={{width:'100%',flexDirection:'row',alignItems:'center'}}>

                  <Star color={'#000000'} variant='Bold' size={15} style={{marginRight: 5,paddingBottom:2}}/>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF'}}>{profilInfo.note || [0]} </Text>
                  <Text style={{...styles.textH4,fontSize:7,color:'#AFAFAF',marginHorizontal:7}}>•</Text>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF', textDecorationLine: 'underline',}}>{profilInfo.nbAvis || [0]} avis</Text>
                  <Text style={{...styles.textH4,fontSize:7,color:'#AFAFAF',marginHorizontal:7}}>•</Text>
                  <Location color={'#000000'} variant='Bold' size={15} style={{marginRight: 5,paddingBottom:2}}/>
                  <Text style={{...styles.textH4,fontSize:15,color:'#AFAFAF',textDecorationLine: 'underline',}}>{profilInfo.address.formatted_address || 'Adresse par défaut'}</Text>
                </View>
              </View>
              </View>
              <Text style={{...styles.textH3,marginTop:20,fontWeight:'bold',marginBottom:10}}>Description </Text>
              <Text style={{width:'80%',fontFamily:'Avenir Next Regular',fontSize:15,color:'rgba(0, 0, 0, 0.70)'}}>{truncateText(profilInfo.desc || 'desc par défaut',200)} </Text>
              <TouchableOpacity style={{flexDirection:'row',width:'auto',marginTop:5,alignItems:'center'}}>
                <Text style={{...styles.textH3,fontSize:15,fontWeight:'bold',textDecorationLine:'underline'}}>En savoir plus</Text>
              </TouchableOpacity>
              
              
              <View style={{}}>
              <Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Informations</Text>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
              </View><Text style={{...styles.textH3,marginTop:30,fontWeight:'bold'}}>Liens</Text>
              <View style={{flexDirection:'row',marginTop:10}}>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
                <View style={{borderRadius:10,borderWidth:1,borderColor:'#808080',padding:10,marginRight:5}}>
                  <CustomImage source={require('../assets/medal-star.png')} style={{height:25,width:25,borderRadius:15}}/>

                </View>
              </View>
            </View>

          </View>
          </View>
          
          {
              profilInfo.places ?
              <View>
              <Text style={{...styles.textH1,fontSize:30,marginTop:30}}>Nos activités :</Text>
            <ListingActivitiesHour profilInfo={profilInfo}/>
          </View>
              :
              null
            }{
              profilInfo.places ?
              <View>
                <Text style={{...styles.textH1,fontSize:30,marginTop:30}}> Calendrier des activités régulières</Text>
              <CalendarPlaceAct profilInfo={profilInfo}/>
          </View>
              :
              null
            }
          <View >
            <AvisActivity profilInfo={profilInfo}/>
          </View>
        </ScrollView>
        
      </View>
      )}
      </View>
    );
  }
  const styles = StyleSheet.create({
    textH1: {
        fontFamily:'Avenir Next Bold',
        fontSize:30
    },
    textH2: {
        fontFamily:'Avenir Next Bold',
        fontSize:30,
        fontWeight:500

    },
    iconStar: {
        width: 15,
        height: 15,
        marginRight: 5
    },
    textH4: {
      fontFamily:'Avenir Next Regular',

    },
    textH3: {
      fontFamily:'Avenir Next Regular',
      fontSize:20

    },
  })